import {IonIcon} from '@ionic/react';
import {checkmarkCircle, warning} from 'ionicons/icons';
import React from 'react';
import styles from './FormFeedback.module.scss';

interface FormFeedbackProps {
  type: 'success' | 'error';
  className?: string;
  noMargin?: boolean;
}

const FormFeedback: React.FC<FormFeedbackProps> = ({type, className, noMargin, children}) => {

  return (
    <div
      className={`${styles.feedbackBox} ${type === 'success' ? styles.success : styles.error} ${className ? className :
        ''} ${noMargin ? styles.noMargin : ''}`}>
      <IonIcon icon={type === 'success' ? checkmarkCircle : warning} />
      <div>{children}</div>
    </div>
  );
};

export default FormFeedback;