import {getPlatforms, IonButton, IonIcon, IonSpinner} from "@ionic/react";
import {eye, eyeOff} from "ionicons/icons";
import React, {FormEvent, useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import AuthContext from "../../../context/AuthContext";
import useLogin from "../../../hooks/useLogin";
import {UnconfirmedResponse} from "../../../types/otherTypes";
import {LoginData, LoginRequest} from "../../../types/requestTypes";
import {ExceptionDto} from "../../../types/schema";
import FormFeedback from "../../../components/UI/FormFeedback";
import {useTranslation} from "react-i18next";

import styles from "./Login.module.scss";
import TwoFALogin from "./TwoFALogin/TwoFALogin";
import useQuery from "../../../hooks/useQuery";

interface LoginCardProps {
    is2FA: boolean;

    setIs2FA(value: boolean): void;
}

const LoginCard: React.FC<LoginCardProps> = ({is2FA, setIs2FA}) => {
    const history = useHistory();
    const query = useQuery();
    const {t} = useTranslation();
    const {updateAuthCtx} = useContext(AuthContext);
    const login = useLogin();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fcmToken] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [login2FAState, setLogin2FAState] = useState<LoginData>({
        email: "",
        password: "",
        fcmToken: "",
        pin: null,
    });


    const submitHandler = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoading(true);

        setLogin2FAState((prevState) => {
            return {
                ...prevState,
                email: email.trim(),
                password: password,
                fcmToken: fcmToken,
            };
        });

        const data: LoginRequest = {
            email: email.trim(),
            password: password,
            fcmToken: fcmToken,
            pin: null,
            platforms: getPlatforms(),
        };

        await login(data)
            .then((result) => {
                if (!result?.has2FA) {
                    updateAuthCtx(result);
                    return;
                }
                setIs2FA(result?.has2FA);
            })
            .catch((err) => {
                setIsLoading(false);
                const exception: ExceptionDto = err.response?.data;
                if (
                    err.response?.status === 400 ||
                    err.response?.status === 401
                ) {
                    if (exception.message === "SOCIAL_LOGIN_ONLY_EXCEPTION") {
                        setError(t("err.SOCIAL_LOGIN_ONLY_EXCEPTION"));
                    } else if (exception.message === "USER_IS_DELETED") {
                        setError(t("err.USER_IS_DELETED"));
                    } else if (exception.message === "USER_IS_UNCONFIRMED") {
                        // setUnconfirmedType({
                        //     type: "account",
                        //     resendUrl: exception.resendUrl as string,
                        // });
                    }
                    // else if (exception.message === "USER_HAS_PASSWORD_UNCONFIRMED")
                    // {
                    //     setUnconfirmedType({
                    //         type: "password",
                    //         resendUrl: exception.resendUrl as string,
                    //     });
                    // }
                    else {
                        setError(t("err.INVALID_LOGIN"));
                    }
                } else {
                    setError(t("err.GENERIC_ERROR"));
                }
            });
    };

    return (
        <div className={styles.loginForm}>
            {!is2FA ? (
                    <>
                        <form onSubmit={submitHandler}>
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value as string)}
                                type="email"
                                autoComplete="email"
                                placeholder={t("login.email.placeholder")}
                                required
                            />
                            <div className={styles.password}>
                                <input
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value as string)
                                    }
                                    type={showPassword ? "text" : "password"}
                                    autoComplete="current-password"
                                    placeholder={t("login.password.placeholder")}
                                    required
                                />
                                <IonButton
                                    onClick={() =>
                                        setShowPassword((prevState) => !prevState)
                                    }
                                    className={showPassword ? styles.off : styles.on}
                                >
                                    <IonIcon icon={showPassword ? eyeOff : eye}/>
                                </IonButton>
                            </div>

                            <input type="submit" className="submit-enter"/>

                            {/*{!unconfirmedType ? (*/}
                                <IonButton
                                    type="submit"
                                    color="primary"
                                    strong={true}
                                    disabled={isLoading}
                                    onClick={() => {
                                    }}
                                >
                                    {isLoading ? (
                                        <>
                                            <IonSpinner name="circles"/>{" "}
                                            {t("login.loading")}
                                        </>
                                    ) : (
                                        <>{t("login.button")}</>
                                    )}
                                </IonButton>
                            {/*: (*/}
                            {/*    ""*/}
                            {/*)}*/}

                            {!!error ? (
                                <FormFeedback type={"error"}>{error}</FormFeedback>
                            ) : (
                                ""
                            )}
                            {query.get("expiredRefresh") === "true" ? (
                                <FormFeedback type={"error"}>{t("err.SESSION_EXPIRED")}</FormFeedback>
                            ) : (
                                ""
                            )}
                        </form>

                        <IonButton
                            fill="clear"
                            color="dark"
                            strong={true}
                            size="small"
                            onClick={() => history.push("/forgot-password")}
                        >
                            {t("login.forgotPass")}
                        </IonButton>
                    </>) :
                ((
                    <TwoFALogin credentials={login2FAState} source={'login'}/>
                ))
            }
        </div>
    );
};

export default LoginCard;
