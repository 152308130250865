import React, { useEffect, useState } from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import useQuery from "../../hooks/useQuery";
import TableNews from "./TableNews";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {URLBuilderService} from "../../services/URLBuilderService";

const NewsPage: React.FC = () => {
    const query = useQuery();
    const axiosul = useAxiosPrivate();
    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );
    const [newsData1, setNewsData1] = useState<any>();


    useEffect(() => {
        axiosul
            .get((URLBuilderService.build("/admin/news")
                    .withPage(query.get("page"))
                    .withSearchTerm(query.get("searchTerm"))
                    .withSortIndex(query.get("sortIndex"))
                    .withSortDirection(query.get("sortDirection"))
                    .generateFullUrl()
                ))
            .then((newsData1) => newsData1.data)
            .then((newsData) => setNewsData1(newsData));
    }, [
        query.get("searchTerm"),
        query.get("page"),
        query.get("sortIndex"),
        query.get("sortDirection"),
    ]);

    return (
        <IonPage>
            <SidebarLayout className="others-page" layoutType={"submenu"}>
                <SkeletonList
                    loading={!newsData1}
                    type={"simple"}
                    skeletonWidth={"888px"}
                />

                {newsData1 && (
                    <TableNews
                        key={query.get("page")}
                        newsData={newsData1}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        query={query}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default NewsPage;
