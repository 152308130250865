import { IonLabel, IonPage } from "@ionic/react";
import React from "react";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import { FaqContentRO } from "./FaqContentRO";

export const FaqPage: React.FC = () => {
    return (
        <IonPage>
            <SidebarLayout layoutType={"back"}>
                <IonLabel className="ag-h2 black">Întrebări Frecvente</IonLabel>
                <FaqContentRO />
            </SidebarLayout>
        </IonPage>
    );
};
