import React, { useState } from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import useFetchCaching from "../../hooks/useFetchCaching";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import { UsersRequest } from "../../types/requestTypes";
import TabelUsers from "./TableUsers/TabelUsers";
import useQuery from "../../hooks/useQuery";

const UsersPage: React.FC = () => {
    let query = useQuery();
    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );

    const urlWithSearch: string = `/admin/users?page=${query.get(
        "page"
    )}&searchTerm=${query.get(`searchTerm`)}&withContractOnly=${query.get(
        "withContractOnly"
    )}`;

    const urlWithoutSearch: string = `/admin/users?page=${query.get(
        "page"
    )}&withContractOnly=${query.get("withContractOnly")}`;

    const urlWithSearchSorted: string = `/admin/users?page=${query.get(
        "page"
    )}&searchTerm=${query.get(`searchTerm`)}&withContractOnly=${query.get(
        "withContractOnly"
    )}&sortIndex=${query.get("sortIndex")}&sortDirection=${query.get(
        "sortDirection"
    )}`;

    const urlWithoutSearchSorted: string = `/admin/users?page=${query.get(
        "page"
    )}&withContractOnly=${query.get("withContractOnly")}&sortIndex=${query.get(
        "sortIndex"
    )}&sortDirection=${query.get("sortDirection")}`;

    const { data } = useFetchCaching<UsersRequest>(
        query.get("sortIndex") === null
            ? query.get("searchTerm") === null
                ? urlWithoutSearch
                : urlWithSearch
            : query.get("searchTerm") === null
            ? urlWithoutSearchSorted
            : urlWithSearchSorted
    );

    return (
        <IonPage>
            <SidebarLayout className="others-page" layoutType={"submenu"}>
                <SkeletonList
                    loading={!data}
                    type={"simple"}
                    skeletonWidth={"880px"}
                />

                {data && (
                    <TabelUsers
                        key={query.get("page")}
                        users={data}
                        query={query}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default UsersPage;
