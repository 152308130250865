import React, { useMemo, useState } from "react";
import { IonIcon } from "@ionic/react";
import { informationCircle, document } from "ionicons/icons";
import { BounceEmail, BounceEmailRequest } from "../../../types/requestTypes";
import moment from "moment/moment";
import TablePagination from "../../../components/Pagination/TablePagination";
import { useTranslation } from "react-i18next";
import EmailReturnTableHeader from "./EmailReturnTableHeader";
import EmailReturnModal from "./EmailReturnModal";
import { Link } from "react-router-dom";

import styles from "../EmailReturn.module.scss";
import SortingIcons from "../../../components/UI/SortingIcons";

interface TabelProps {
    bounceEmail: BounceEmailRequest;
    query: URLSearchParams;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
}

const TabelEmailReturn: React.FC<TabelProps> = ({
    bounceEmail,
    query,
    pageNumber,
    setPageNumber,
}) => {
    const { t } = useTranslation();

    const data = useMemo<BounceEmail[]>(
        () => bounceEmail.content,
        [bounceEmail]
    );
    const totalPages = useMemo<number>(
        () => bounceEmail.totalPages,
        [bounceEmail]
    );
    const totalElements = useMemo<number>(
        () => bounceEmail.totalElements,
        [bounceEmail]
    );

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            <table className="tableReturn" style={{ width: "100%" }}>
                <EmailReturnTableHeader
                    query={query}
                    totalElements={totalElements}
                />

                <thead className={styles.headerWrapper}>
                    <tr>
                        <SortingIcons
                            columnLabel={t("emailReturn.table.headerEmail")}
                            query={query}
                            urlStringPart={"bounce"}
                            sortIndex={0}
                            deafultSort={false}
                            width={"270px"}
                        />
                        <SortingIcons
                            columnLabel={t("emailReturn.table.headerContract")}
                            urlStringPart={"bounce"}
                            sortIndex={null}
                            deafultSort={false}
                            width={"100px"}
                        />
                        <SortingIcons
                            columnLabel={t("emailReturn.table.headerDate")}
                            query={query}
                            urlStringPart={"bounce"}
                            sortIndex={2}
                            deafultSort={true}
                            width={"180px"}
                        />
                        <SortingIcons
                            columnLabel={t(
                                "emailReturn.table.headerCampaignName"
                            )}
                            query={query}
                            urlStringPart={"bounce"}
                            sortIndex={3}
                            deafultSort={false}
                            width={"300px"}
                        />
                        <SortingIcons
                            columnLabel={t("emailReturn.table.headerDetail")}
                            urlStringPart={"bounce"}
                            sortIndex={null}
                            deafultSort={false}
                            width={"80px"}
                        />
                    </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                    {data.map((bounceEmail) => {
                        return (
                            <tr
                                key={
                                    bounceEmail.email +
                                    bounceEmail.contracts +
                                    bounceEmail.sendingTime +
                                    bounceEmail.campaignName
                                }
                            >
                                <td className={"invoiceSerial"}>
                                    <span style={{ fontSize: "14px" }}>
                                        {bounceEmail.email}
                                    </span>{" "}
                                    <br />
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            color: "gray",
                                        }}
                                    >
                                        {bounceEmail.contracts[0] === undefined
                                            ? t("noData")
                                            : bounceEmail.contracts[0].name}
                                    </span>
                                </td>

                                <td
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {bounceEmail.contracts[0] === undefined ? (
                                        <span
                                            style={{
                                                fontSize: "14px",
                                                color: "gray",
                                            }}
                                        >
                                            {t("noData")}
                                        </span>
                                    ) : (
                                        <Link
                                            className={styles.button}
                                            to={`/contract/${bounceEmail.contracts[0].id}`}
                                        >
                                            <IonIcon
                                                style={{ fontSize: "1.5rem" }}
                                                color={
                                                    bounceEmail.rectificationStatus ===
                                                    "MANDATORY"
                                                        ? "danger"
                                                        : "tertiary"
                                                }
                                                icon={document}
                                            />
                                        </Link>
                                    )}
                                </td>
                                <td
                                    style={{
                                        width: "200px",
                                        fontSize: "14px",
                                        color: "#464E5F",
                                    }}
                                >
                                    {moment(bounceEmail.sendingTime).format(
                                        "L"
                                    )}
                                </td>
                                <td style={{ color: "#464E5F" }}>
                                    {bounceEmail.campaignName}
                                </td>
                                <td
                                    style={{
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "1rem",
                                    }}
                                >
                                    <div onClick={() => setShowModal(true)}>
                                        <IonIcon
                                            color="tertiary"
                                            icon={informationCircle}
                                            style={{
                                                fontSize: "2rem",
                                            }}
                                        />
                                    </div>

                                    <EmailReturnModal
                                        showModal={showModal}
                                        setShowModal={setShowModal}
                                        bounceEmail={bounceEmail}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"bounce"}
            />
        </>
    );
};

export default TabelEmailReturn;
