import {IonIcon, IonItem, IonLabel, IonList, IonModal} from "@ionic/react";
import React, {useRef} from "react";
import AppTypeahead from "./Typehead";
import {caretDownOutline} from "ionicons/icons";

interface NewsSelectorProps {
    labelInput: string;
    disabled?: boolean;
    allOptions?: string[];
    setDataCounties?: () => {};
    defaultData?: string[];
    onUpdate?: (selection: string[]) => void;
    selectedOptions?: string[];
    placeholder?: string;
}

const NewsSelector: React.FC<NewsSelectorProps> = ({
                                                       labelInput,
                                                       disabled,
                                                       allOptions,
                                                       onUpdate,
                                                       selectedOptions,
                                                       placeholder,
                                                   }) => {
    const modal = useRef<HTMLIonModalElement>(null);

    return (
        <IonList style={{
            padding: "0"
        }}>
            <IonLabel>{labelInput}</IonLabel>
            <IonItem
                className={"inputItemClassDots"}
                disabled={disabled}
                button={true}
                detail={false}
                id={`select-${labelInput}`}
                lines={"none"}
                style={{
                    border: '1px solid #DEDEDE',
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%"
                }}
            >
                <IonLabel
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <span style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "346.1px"
                    }
                    }>
                        {selectedOptions.length
                            ? selectedOptions.join(", ")
                            : placeholder}
                    </span>{" "}
                    <IonIcon icon={caretDownOutline}/>
                </IonLabel>
            </IonItem>
            <IonModal
                trigger={`select-${labelInput}`}
                ref={modal}
                backdropDismiss={false}
            >
                <AppTypeahead
                    title={labelInput}
                    items={allOptions}
                    selectedItems={selectedOptions}
                    onSelectionCancel={() => modal.current?.dismiss()}
                    onSelectionChange={(selection) => {
                        onUpdate(selection);
                        modal.current?.dismiss();
                    }}
                />
            </IonModal>
        </IonList>
    );
};

export default NewsSelector;
