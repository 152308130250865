import React from "react";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface EditStatusProps {
    item: any;
    handleChangeStatus: (e: any, id: string) => void;
    isLoading: boolean;
}

const EditStatus: React.FC<EditStatusProps> = ({
    item,
    handleChangeStatus,
    isLoading,
}) => {
    const { t } = useTranslation();

    return (
        <IonSelect
            key={item.id}
            interface="popover"
            placeholder={t("contractDemands.select.placeholder")}
            onIonChange={(e) => handleChangeStatus(e, item.id)}
            selectedText={item.status}
            style={{ padding: "0px", fontSize: "15px" }}
            disabled={isLoading}
        >
            <IonSelectOption value="Aprobată">
                {t("contractDemands.select.approved")}
            </IonSelectOption>
            <IonSelectOption value="Respinsă">
                {t("contractDemands.select.rejected")}
            </IonSelectOption>
            <IonSelectOption value="Trimisă">
                {t("contractDemands.select.sent")}
            </IonSelectOption>
            <IonSelectOption value="În analiză">
                {t("contractDemands.select.pending")}
            </IonSelectOption>
        </IonSelect>
    );
};

export default EditStatus;
