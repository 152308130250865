import React, { useState } from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import useFetchCaching from "../../hooks/useFetchCaching";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import { InvoicesData } from "../../types/requestTypes";
import TabelInvoices from "./TableInvoices/TableInvoices";
import useQuery from "../../hooks/useQuery";

const InvoicesPage: React.FC = () => {
    const query = useQuery();
    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );

    const urlWithoutSearchTerm: string = `/admin/invoices?page=${query.get(
        "page"
    )}`;

    const urlWithSearchTerm: string = `/admin/invoices?page=${query.get(
        "page"
    )}&searchTerm=${query.get("searchTerm")}`;

    const urlWithoutSearchTermSorted: string = `/admin/invoices?page=${query.get(
        "page"
    )}&sortIndex=${query.get("sortIndex")}&sortDirection=${query.get(
        "sortDirection"
    )}`;

    const urlWithSearchTermSorted: string = `/admin/invoices?page=${query.get(
        "page"
    )}&searchTerm=${query.get("searchTerm")}&sortIndex=${query.get(
        "sortIndex"
    )}&sortDirection=${query.get("sortDirection")}`;

    const { data: invoicesData } = useFetchCaching<InvoicesData>(
        query.get("sortIndex") === null
            ? query.get("searchTerm") === null
                ? urlWithoutSearchTerm
                : urlWithSearchTerm
            : query.get("searchTerm") === null
            ? urlWithoutSearchTermSorted
            : urlWithSearchTermSorted
    );

    return (
        <IonPage>
            <SidebarLayout className="others-page" layoutType={"submenu"}>
                <SkeletonList
                    loading={!invoicesData}
                    type={"simple"}
                    skeletonWidth={"1350px"}
                />

                {invoicesData && (
                    <TabelInvoices
                        key={query.get("page")}
                        invoicesData={invoicesData}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        query={query}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default InvoicesPage;
