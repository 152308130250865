import { IonItem, IonLabel, IonSpinner } from "@ionic/react";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { UserProvider } from "./context/UserContext";
import { ToastContainer } from "react-toastify";
import "./i18n";

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <>
        <ToastContainer autoClose={5000} position={"top-right"} />
        <Suspense
            fallback={
                <IonItem lines="none">
                    <IonSpinner slot="start" color="primary" />
                    <IonLabel color="medium">Se încarcă</IonLabel>
                </IonItem>
            }
        >
            <AuthProvider>
                <UserProvider>
                    <App />
                </UserProvider>
            </AuthProvider>
        </Suspense>
    </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
