import { IonButton, IonInput, IonLabel, IonSpinner } from "@ionic/react";
import React, { useContext, useState } from "react";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import { userActions } from "../../../api/UserActions";
import AuthContext from "../../../context/AuthContext";
import { EditProfile } from "../../../types/requestTypes";
import styles from "./UserPages.module.scss";
import {Profile} from "../../../types/responseTypes";

interface PropsProfilePage{
    profile: Profile
}

const ProfilePageForm: React.FC<PropsProfilePage> = ({profile}) => {
    const { nameCtx, mailCtx, updateNameCtx } = useContext(AuthContext);

    const [email, setEmail] = useState(mailCtx);
    const [name, setName] = useState(profile.name);
    const [isLoading, setLoading] = useState(false);


    const submitHandler = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setLoading(true);

        const data: EditProfile = {
            name: name as string,
        };
        try {
            await userActions.changeUserName(data);
            setLoading(false);
            // toastAdd(t("profile.saved"), "success");
            updateNameCtx(name as string);
        } catch (err: any) {
            setLoading(false);

            toastAdd("Eroare", "error");
        }
    };

    return (
        <form onSubmit={submitHandler} className={styles.form}>
            <IonLabel>{"Adresă email"}</IonLabel>
            <IonInput
                onIonChange={(e) => setEmail(e.target.value as string)}
                value={email}
                type="text"
                inputmode="email"
                autocomplete="off"
                disabled={true}
                placeholder={"Email"}
            />

            <IonLabel>{"Nume și prenume"}</IonLabel>
            <IonInput
                onIonChange={(e) => setName(e.target.value as string)}
                value={name}
                type="text"
                inputmode="text"
                autocomplete="off"
                placeholder={"Nume și prenume"}
            />

            <IonButton
                type="submit"
                expand="block"
                fill="solid"
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <IonSpinner name="circles" /> {"Se salvează"}
                    </>
                ) : (
                    "Salvează"
                )}
            </IonButton>

            <input type="submit" className="submit-enter" />
        </form>
    );
};
export default ProfilePageForm;
