import React, { useContext, useState } from "react";
import { IonContent, IonLabel, IonPage } from "@ionic/react";
import LoginCard from "./LoginCard";
import { useTranslation } from "react-i18next";

import styles from "./Login.module.scss";
import AuthContext from "../../../context/AuthContext";

const LoginPage: React.FC = () => {
    const { t } = useTranslation();
    const { versionCtx } = useContext(AuthContext);
    const [is2FA, setIs2FA] = useState(null);

    return (
        <IonPage>
            <IonContent fullscreen>
                <main className={`${styles.loginPage} ${styles.desktop}`}>
                    <section className={styles.loginFormWrapper}>
                        <div className={styles.loginFormTop}>
                            <div className={`${styles.businessHeader}`}>
                                <img
                                    src="/assets/img/aqmeter-small.png"
                                    alt="Aqmeter"
                                />
                            </div>
                            <LoginCard setIs2FA={setIs2FA} is2FA={is2FA} />
                        </div>
                        {!is2FA && (
                            <span style={{ marginBottom: "20px" }}>
                                {t("version", { version: versionCtx })}
                            </span>
                        )}
                    </section>

                    <section
                        className={styles.businessInfo}
                        style={{ background: "url('/assets/img/login.jpg')" }}
                    >
                        <div className={styles.businessText}>
                            <IonLabel>{t("login.title")}</IonLabel>
                            <IonLabel>{t("login.description")}</IonLabel>
                        </div>
                    </section>
                </main>
            </IonContent>
        </IonPage>
    );
};

export default LoginPage;
