import React, { useContext, useMemo } from "react";
import { IonButton, IonIcon, IonLabel } from "@ionic/react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import TablePagination from "../../components/Pagination/TablePagination";
import { NewsAdmin, NewsAdminList } from "../../types/responseTypes";
import moment from "moment";
import { t } from "i18next";

import styles from "./News.module.scss";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useWindowSize } from "../../hooks/useWindowSize";
import { SettingsContext } from "../../context/SettingsContext";
import SortingIcons from "../../components/UI/SortingIcons";

interface TabelProps {
    newsData: NewsAdminList;
    setPageNumber: (pageNumber: number) => void;
    pageNumber: number;
    query?: URLSearchParams;
}

const TabelNews: React.FC<TabelProps> = ({
    newsData,
    setPageNumber,
    pageNumber,
    query,
}) => {
    const history = useHistory<History>();
    const { layout } = useWindowSize();
    const data = useMemo<NewsAdmin[]>(() => newsData?.content, [newsData]);
    const totalPages = useMemo<number>(() => newsData?.totalPages, [newsData]);
    const totalElements = useMemo<number>(
        () => newsData?.totalElements,
        [newsData]
    );

    return (
        <>
            <table>
                {layout === "phone" ? (
                    <caption>
                        <div className={styles.wrapperInsideCaption}>
                            <div className={styles.wrapperTitlePlusSearchbar}>
                                <div
                                    className={styles.wrapperTitlePlusSubtitle}
                                >
                                    <IonLabel className={styles.title}>
                                        {t("news.title")}
                                    </IonLabel>
                                    <span
                                        style={{
                                            display: "flex",
                                            margin: "10px 0",
                                            color: "gray",
                                        }}
                                    >{`Total: ${totalElements} informări`}</span>
                                </div>
                                <SearchBar query={query} urlStringPart="news" />
                            </div>
                            <div className={styles.wrapperButtonNews}>
                                <IonButton
                                    className={styles.newsButton}
                                    fill={"outline"}
                                    color={"tertiary"}
                                    shape={"round"}
                                    strong={true}
                                    onClick={() => {
                                        history.push("/news/create");
                                    }}
                                >
                                    {t("news.buttonLabel")}
                                </IonButton>
                            </div>
                        </div>
                    </caption>
                ) : (
                    <caption>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                marginBottom: "40px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <IonLabel className={styles.title}>
                                        {t("news.title")}
                                    </IonLabel>
                                    <span
                                        style={{
                                            display: "flex",
                                            margin: "10px 0",
                                            color: "gray",
                                        }}
                                    >{`Total: ${totalElements} informări`}</span>
                                </div>
                                <SearchBar query={query} urlStringPart="news" />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <IonButton
                                    className={styles.newsButton}
                                    fill={"outline"}
                                    color={"tertiary"}
                                    shape={"round"}
                                    strong={true}
                                    onClick={() => {
                                        history.push("/news/create");
                                    }}
                                >
                                    {t("news.buttonLabel")}
                                </IonButton>
                            </div>
                        </div>
                    </caption>
                )}
                <thead className={styles.headerWrapper}>
                    <tr>
                        <SortingIcons
                            columnLabel={t("news.tableColumns.active")}
                            query={query}
                            urlStringPart={"news"}
                            sortIndex={0}
                            deafultSort={false}
                        />
                        <SortingIcons
                            columnLabel={t("news.tableColumns.title")}
                            query={query}
                            urlStringPart={"news"}
                            sortIndex={1}
                            deafultSort={false}
                            width={"450px"}
                        />
                        <SortingIcons
                            columnLabel={t("news.tableColumns.date")}
                            query={query}
                            urlStringPart={"news"}
                            sortIndex={2}
                            deafultSort={true}
                        />
                        {
                            (layout !== "phone" ? (
                                <>
                                    <th>{t("news.tableColumns.email")}</th>
                                    <th>{t("news.tableColumns.sms")}</th>
                                    <th>{t("news.tableColumns.push")}</th>
                                    <th>{t("news.tableColumns.web")}</th>
                                </>
                            ) : (
                                ""
                            ))}
                    </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                    {data?.map((news) => {
                        return (
                            <tr
                                key={news.id}
                                onClick={() => {
                                    history.push(`/news/${news.id}`);
                                }}
                            >
                                <td>
                                    {news.widgetActive ? (
                                        <IonIcon
                                            icon={checkmarkCircleOutline}
                                            color={"tertiary"}
                                            size={"large"}
                                        />
                                    ) : (
                                        <IonIcon
                                            icon={ellipseOutline}
                                            style={{ color: "gray" }}
                                            size={"large"}
                                        />
                                    )}
                                </td>
                                <td data-testid={"titleData"}>{news.title}</td>
                                <td>{moment(news.date).format("L")}</td>

                                {layout !== "phone" ? (
                                    <>
                                        <td>
                                            {news.emailSelected ? (
                                                <IonIcon
                                                    icon={
                                                        checkmarkCircleOutline
                                                    }
                                                    color={"tertiary"}
                                                    size={"large"}
                                                />
                                            ) : (
                                                <IonIcon
                                                    icon={ellipseOutline}
                                                    style={{ color: "gray" }}
                                                    size={"large"}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {news.smsSelected ? (
                                                <IonIcon
                                                    icon={
                                                        checkmarkCircleOutline
                                                    }
                                                    color={"tertiary"}
                                                    size={"large"}
                                                />
                                            ) : (
                                                <IonIcon
                                                    icon={ellipseOutline}
                                                    style={{ color: "gray" }}
                                                    size={"large"}
                                                />
                                            )}
                                        </td>

                                        <td>
                                            {news.pushSelected ? (
                                                <IonIcon
                                                    icon={
                                                        checkmarkCircleOutline
                                                    }
                                                    color={"tertiary"}
                                                    size={"large"}
                                                />
                                            ) : (
                                                <IonIcon
                                                    icon={ellipseOutline}
                                                    style={{ color: "gray" }}
                                                    size={"large"}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {news.widgetActive ? (
                                                <IonIcon
                                                    icon={
                                                        checkmarkCircleOutline
                                                    }
                                                    color={"tertiary"}
                                                    size={"large"}
                                                />
                                            ) : (
                                                <IonIcon
                                                    icon={ellipseOutline}
                                                    style={{ color: "gray" }}
                                                    size={"large"}
                                                />
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    ""
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"news"}
            />
        </>
    );
};

export default TabelNews;
