import axios from "axios";

class HttpService {
    private BASE_URL = process.env.REACT_APP_API_URL;

    get<T>(url: string, accessTokenCtx: string) {
        const axiosP = axios.create({
            baseURL: this.BASE_URL,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessTokenCtx}`,
            },
        });

        const controller = new AbortController();
        const request = axiosP.get<T>(url, {
            signal: controller.signal,
        });
        return { request, cancelHandler: () => controller.abort() };
    }

    postCount<T>(
        counties,
        cities,
        streets,
        numbers,
        isEmailSelected,
        isSmsSelected,
        isPushSelected,
        accessTokenCtx
    ) {
        const axiosP = axios.create({
            baseURL: this.BASE_URL,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessTokenCtx}`,
            },
        });

        const controller = new AbortController();
        // const request = axiosP.post<T>(url, {});

        const request = axiosP.post(
            "/admin/news/count",
            {
                counties: counties,
                cities: cities,
                streets: streets,
                numbers: numbers,
                isEmailSelected: isEmailSelected,
                isSmsSelected: isSmsSelected,
                isPushSelected: isPushSelected,
            },
            {
                signal: controller.signal,
            }
        );
        return { request, cancelHandler: () => controller.abort() };
    }

    postNews<T>(data, accessTokenCtx) {
        const axiosP = axios.create({
            baseURL: this.BASE_URL,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessTokenCtx}`,
            },
        });

        // const controller = new AbortController();
        // // const request = axiosP.post<T>(url, {});

        const request = axiosP.post("/admin/news", {
            title: data.title,
            date: data.date,
            counties: data.counties.length > 0 ? data.counties : null,
            cities: data.cities.length > 0 ? data.cities : null,
            streets: data.streets.length > 0 ? data.streets : null,
            numbers: data.numbers.length > 0 ? data.numbers : null,
            isEmailSelected: data.isEmailSelected,
            isSmsSelected: data.isSmsSelected,
            isPushSelected: data.isPushSelected,
            widgetActive: data.widgetActive,
            htmlBody: data.htmlBody,
            companyId: data.companyId,
        });
        return { request };
    }

    patchBodyElement = (data, accessTokenCtx, newsId) => {
        const axiosP = axios.create({
            baseURL: this.BASE_URL,
            headers: {
                "Content-Type": "application/json-patch+json",
                Authorization: `Bearer ${accessTokenCtx}`,
            },
        });
        const request = axiosP.patch(`/admin/news/${newsId}`, data);

        return { request };
    };

    patchNewsDetails = (data, accessTokenCtx, newsId) => {
        const axiosP = axios.create({
            baseURL: this.BASE_URL,
            headers: {
                "Content-Type": "application/json-patch+json",
                Authorization: `Bearer ${accessTokenCtx}`,
            },
        });
        const request = axiosP.patch(`/admin/news/${newsId}`, [
            {
                op: "/replace",
                path: data.path,
                value: data.value,
            },
        ]);

        return { request };
    };

    patchWdigetElement = (data, accessTokenCtx, newsId) => {
        const axiosP = axios.create({
            baseURL: this.BASE_URL,
            headers: {
                "Content-Type": "application/json-patch+json",
                Authorization: `Bearer ${accessTokenCtx}`,
            },
        });
        const request = axiosP.patch(`/admin/news/${newsId}`, [
            {
                op: "replace",
                path: "/active",
                value: data,
            },
        ]);

        return { request };
    };
}

const createHttpService = new HttpService();

export default createHttpService;
