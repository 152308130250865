import {useContext, useEffect} from "react";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {axiosPrivate} from "../api/customAxios";
import AuthContext from "../context/AuthContext";
import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const history = useHistory();
    const {accessTokenCtx, logoutCtx} = useContext(AuthContext);
    const currentPath = useLocation()?.pathname;

    let refreshTokenPromise: Promise<any> | null;
    // this holds any in-progress token refresh requests

    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config: any) => {
                if (!config.headers["Authorization"]) {
                    // when authorization header doesn't exist, we know it's not a retry
                    config.headers[
                        "Authorization"
                        ] = `Bearer ${accessTokenCtx}`;
                }
                return config;
            },
            (error: any) => Promise.reject(error)
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response: any) => response,
            (error: {
                config: { headers: { [x: string]: string } };
                response: { status: number };
            }) => {
                if (
                    error.config &&
                    error.response &&
                    error.response.status === 401
                ) {
                    if (!refreshTokenPromise) {
                        // check for an existing in-progress request
                        // if nothing is in-progress, start a new refresh request
                        refreshTokenPromise = refresh({fcmToken: ""}).then((newToken) => {
                            refreshTokenPromise = null; // clear state
                            return newToken; // resolve with the new token
                        });
                    }

                    return refreshTokenPromise
                        .then((newAccessToken) => {
                            // after receiving the new access token, resend your initial request
                            // using the same data stored in error.config, but with the new token in the header
                            error.config.headers[
                                "Authorization"
                                ] = `Bearer ${newAccessToken}`;
                            return axiosPrivate(error.config);
                        })
                        .catch((err) => {
                            // if the refresh token expired (response 400), the user must be logged out
                            logoutCtx();
                            if (!currentPath.includes("/pay")) {
                                history.replace("/login?expiredRefresh=true");
                            }

                            return Promise.reject(err);
                        });
                }

                return Promise.reject(error);
            }
        );

        return () => {
            //remove interceptors at cleanup, so they don't pile forever
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [accessTokenCtx, refresh]);


    return axiosPrivate;
};

export default useAxiosPrivate;
