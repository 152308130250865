import React, {useContext, useState} from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import useFetchCaching from "../../hooks/useFetchCaching";
import TabelContractDemands from "./TableContractDemands/TableContractDemands";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import { ContractDemandPageRequest } from "../../types/requestTypes";
import useQuery from "../../hooks/useQuery";
import {SettingsContext} from "../../context/SettingsContext";

const ContractDemandsPage: React.FC = () => {
    const query = useQuery();
    const {isStaging} = useContext(SettingsContext)
    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );

    const { data } = useFetchCaching<ContractDemandPageRequest>(
        query.get("searchTerm") === null
            ? `/admin/contract-demands?page=${query.get("page")}`
            : `/admin/contract-demands?page=${query.get(
                  "page"
              )}&searchTerm=${query.get("searchTerm")}`
    );

    return (
        <>
            {isStaging ? <IonPage>
            <SidebarLayout className="others-page" layoutType={"submenu"}>
                <SkeletonList
                    loading={!data}
                    type={"simple"}
                    skeletonWidth={"1234px"}
                />

                {data && (
                    <TabelContractDemands
                        key={query.get("page")}
                        contractDemands={data}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        query={query}
                    />
                )}
            </SidebarLayout>
        </IonPage>: ""}
        </>

    );
};

export default ContractDemandsPage;
