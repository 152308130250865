import React, { useContext } from "react";
import { IoLogOutOutline } from "react-icons/io5";
import { IonItem, IonRouterLink } from "@ionic/react";
import { LogoutData } from "../../types/requestTypes";
import { useHistory } from "react-router";
import useLogout from "../../hooks/useLogout";
import AuthContext from "../../context/AuthContext";
import { toastAdd } from "../CustomToast/CustomToasts";
import { useTranslation } from "react-i18next";
import { ExceptionDto } from "../../types/schema";
import { useWindowSize } from "../../hooks/useWindowSize";

const HelpLinks: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory<History>();
    const logout = useLogout();
    const size = useWindowSize();
    const { mailCtx, nameCtx } = useContext(AuthContext);

    const onClickLogout = async () => {
        const data: LogoutData = {
            userEmail: mailCtx,
            fcmToken: "",
        };
        try {
            await logout(data);
            history.replace("/login");
        } catch (err) {
            const ex: ExceptionDto = err.response.data;
            toastAdd(`err.${ex.message}`, "error");
        }
    };

    return (
        <IonItem
            slot="secondary"
            lines="none"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {size.width > 520 && (
                <>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                            style={{
                                whiteSpace: "nowrap",
                                fontWeight: "600",
                                fontSize: "13px",
                            }}
                            key={nameCtx}
                        >
                            {nameCtx}
                        </span>
                        <span
                            style={{
                                whiteSpace: "nowrap",
                                fontWeight: "500",
                                fontSize: "13px",
                                color: "gray",
                            }}
                            key={mailCtx}
                        >
                            {mailCtx}
                        </span>
                    </div>

                    <IonRouterLink
                        onClick={onClickLogout}
                        className="icon-link"
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <IoLogOutOutline
                            title={t("help.title.logout")}
                            className="icon"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        />
                    </IonRouterLink>
                </>
            )}
        </IonItem>
    );
};

export default HelpLinks;
