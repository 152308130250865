import {IonLabel, IonPage} from "@ionic/react";
import React from "react";
import SidebarLayout from '../../../../components/SidebarLayout/SidebarLayout';
import {PrivacyContentRO} from "./PrivacyContentRO";

export const PrivacyPage: React.FC = () => {


  return (
    <IonPage>
      <SidebarLayout layoutType={'back'}>
        <IonLabel className="ag-h2 black">Politică de Confidențialitate</IonLabel>
            <PrivacyContentRO />
      </SidebarLayout>
    </IonPage>
  );
};
