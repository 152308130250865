import React, { useRef, useState } from "react";
import { IonIcon, IonLabel } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { searchOutline } from "ionicons/icons";

import styles from "./SearchBar.module.scss";

interface SearchBarProps {
    query: URLSearchParams;
    urlStringPart: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ query, urlStringPart }) => {
    const { t } = useTranslation();
    const history = useHistory<History>();

    const inputRef = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>("");

    const bounceQuery: string = `&isMandatory=true&isOptional=${query.get(
        "isOptional"
    )}&isRectified=${query.get("isRectified")}`;

    const userQuery: string = `&withContractOnly=${query.get(
        "withContractOnly"
    )}`;

    const sortQuery: string = `&sortIndex=${query.get(
        "sortIndex"
    )}&sortDirection=${query.get("sortDirection")}`;

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            const target = event.target as HTMLIonSearchbarElement;
            query.set("searchTerm", target.value.toLowerCase());

            if (query.get("isMandatory") === null) {
                if (query.get("withContractOnly") !== null) {
                    if (query.get("sortIndex") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${userQuery}${sortQuery}`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${userQuery}`
                        );
                    }
                } else {
                    if (query.get("sortIndex") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${sortQuery}`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}`
                        );
                    }
                }
            } else {
                if (query.get("withContractOnly") !== null) {
                    if (query.get("sortIndex") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${bounceQuery}${userQuery}${sortQuery}`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${bounceQuery}${userQuery}`
                        );
                    }
                } else {
                    if (query.get("sortIndex") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${bounceQuery}${sortQuery}`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${target.value.toLowerCase()}${bounceQuery}`
                        );
                    }
                }
            }

            if (inputRef.current) {
                inputRef.current.value = "";
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleDeleteSearchTerm = () => {
        query.set("searchTerm", "");

        if (query.get("isMandatory") === null) {
            if (query.get("withContractOnly") !== null) {
                history.push(`/${urlStringPart}?page=1&${userQuery}`);
            } else {
                history.push(`/${urlStringPart}?page=1`);
            }
        } else {
            if (query.get("withContractOnly") !== null) {
                history.push(
                    `/${urlStringPart}?page=1&${bounceQuery}${userQuery}`
                );
            } else {
                history.push(`/${urlStringPart}?page=1&${bounceQuery}`);
            }
        }
    };

    return (
        <div className={styles.searchWrapper}>
            <div className={styles.search}>
                <input
                    type="text"
                    ref={inputRef}
                    onKeyUp={(ev) => handleKeyPress(ev)}
                    onChange={(ev) => handleChange(ev)}
                    className={styles.searchBar}
                    placeholder={t("search.placeholder")}
                />

                <IonIcon icon={searchOutline} />
            </div>

            {query.get("searchTerm") !== null && (
                <IonLabel
                    title={t("delete.title")}
                    className={styles.searchTerm}
                    onClick={handleDeleteSearchTerm}
                >
                    "{query.get("searchTerm")}" x
                </IonLabel>
            )}
        </div>
    );
};

export default SearchBar;
