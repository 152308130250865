import { IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import AuthorizedRoutes from "../../routes/AuthorizedRoutes";
import AuthContext from "../../context/AuthContext";
import UserContext from "../../context/UserContext";
import jwtDecode, { JwtPayload } from "jwt-decode";
import Footer from "../../components/Footer/Footer";

interface Props {
    setSelected: (selected: string) => void;
    selected: string;
}

const AuthenticatedPages: React.FC<Props> = ({ selected, setSelected }) => {
    const userData = useContext(AuthContext);
    const { companiesCtx } = useContext(UserContext);

    const companyIdList: number[] = userData.userCompanies;
    const [companyType, setCompanyType] = useState<string>(null);

    const token: string = userData.accessTokenCtx;
    const userDecoded: any = jwtDecode<JwtPayload>(token);

    const userRoles: string[] = userDecoded.authorities; // get the roles from the token, and we can map through them

    useEffect(() => {
        if (
            companyIdList !== null &&
            companyIdList !== undefined &&
            companyIdList.length !== 0
        ) {
            const company = companiesCtx.find(
                (company) => company.id === companyIdList[0]
            );
            return setCompanyType(company.type);
        }
        return setCompanyType("NO_COMPANY");
    }, [companyIdList]);

    return (
        <>
            <IonPage id="main-content">
                <NavigationBar
                    companyType={companyType}
                    userRoles={userRoles}
                    userData={userData}
                    setSelected={setSelected}
                    selected={selected}
                />

                <AuthorizedRoutes />
            </IonPage>

            <Footer setSelected={setSelected} />
        </>
    );
};

export default AuthenticatedPages;
