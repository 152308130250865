import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { AppStatus, Company } from "../types/responseTypes";
import axiosBasic, { axiosPrivate } from "./customAxios";
import { ContractDemandStatus } from "../types/requestTypes";

class AqmeterActions {
    async getAppStatus(): Promise<AppStatus> {
        try {
            const response = await axiosBasic.get("/app/status");
            return response?.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getMobileVersion() {
        try {
            if (Capacitor.isNativePlatform()) {
                const mobileAppInfo = await App.getInfo();
                return mobileAppInfo?.version;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getCompanies(): Promise<Company[]> {
        try {
            const response = await axiosBasic.get("/app/companies");
            return response?.data;
        } catch (error) {
            console.log(error);
        }
    }

    async updateStatusOfContractRequest(data: ContractDemandStatus) {
        try {
            const response = await axiosPrivate.patch(
                `/admin/contract-demands/${data.id}`,
                data
            );
            return response?.status;
        } catch (error) {
            throw error;
        }
    }

    async getSignature() {
        try {
            const response = await axiosPrivate.get("/download/signature");
            return response;
        } catch (error) {
            console.log(error);
        }
    }
}

export const aqmeterActions = new AqmeterActions();
