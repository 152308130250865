import React, { useEffect, useState } from "react";
import { IonCard, IonIcon } from "@ionic/react";
import { DetailsPageUserData } from "../../../types/requestTypes";
import {
    arrowForwardOutline,
    checkmarkCircleOutline,
    ellipseOutline,
} from "ionicons/icons";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

import styles from "./DetailPage.module.scss";
import Card from "../../../components/UI/Card";

type DetailContractCardProps = {
    data: DetailsPageUserData;
    companyIdList: number[];
    layoutCtx: boolean;
};

const DetailContractCard: React.FC<DetailContractCardProps> = ({
    data,
    companyIdList,
}) => {
    const { t } = useTranslation();

    const [selectedContract, setSelectedContract] = useState<string>();

    useEffect(() => {
        for (let i = 0; i < data.contracts.length; i++) {
            if (data.contracts[i].companyId === companyIdList[0]) {
                setSelectedContract(data.contracts[i].number);
                break;
            }
        }
    }, [data]);

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {data.contracts.length > 0 ? (
                <>
                    <IonCard className={styles.contractSelectCard}>
                        <h1 className={styles.title}>
                            {t("users.detail.contracts")}
                        </h1>
                        {data.contracts.map((contract) => {
                            return (
                                <div key={contract.number}>
                                    {companyIdList[0] === contract.companyId ? (
                                        <div
                                            className={
                                                styles.contractSelectCardWrapper
                                            }
                                        >
                                            <div
                                                className={
                                                    selectedContract ===
                                                    contract.number
                                                        ? styles.contractSelected
                                                        : styles.contractNotSelected
                                                }
                                                onClick={() => {
                                                    setSelectedContract(
                                                        contract.number
                                                    );
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        className={
                                                            styles.contractName
                                                        }
                                                    >
                                                        {contract.name}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.contractAddress
                                                        }
                                                    >
                                                        {t(
                                                            "users.detail.contracts.address.county"
                                                        ) +
                                                            contract.address
                                                                .county}
                                                        ,{" "}
                                                        {contract.address.city}
                                                        {t(
                                                            "users.detail.contracts.address.street"
                                                        ) +
                                                            contract.address
                                                                .street}
                                                        {contract.address.number
                                                            ? t(
                                                                  "users.detail.contracts.address.number"
                                                              ) +
                                                              contract.address
                                                                  .number
                                                            : null}
                                                        {contract.address
                                                            .building
                                                            ? t(
                                                                  "users.detail.contracts.address.block"
                                                              ) +
                                                              contract.address
                                                                  .number
                                                            : null}
                                                        {contract.address
                                                            .entrance
                                                            ? t(
                                                                  "users.detail.contracts.address.sc"
                                                              ) +
                                                              contract.address
                                                                  .entrance
                                                            : null}
                                                        {contract.address.floor
                                                            ? t(
                                                                  "users.detail.contracts.address.et"
                                                              ) +
                                                              contract.address
                                                                  .floor
                                                            : null}
                                                        {contract.address.ap
                                                            ? t(
                                                                  "users.detail.contracts.address.apartment"
                                                              ) +
                                                              contract.address
                                                                  .ap
                                                            : null}
                                                    </div>
                                                </div>
                                                <IonIcon
                                                    icon={arrowForwardOutline}
                                                    size="large"
                                                    className={
                                                        selectedContract ===
                                                        contract.number
                                                            ? styles.arrow
                                                            : styles.arrowHidden
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            );
                        })}
                    </IonCard>
                    <IonCard className={styles.contractCard}>
                        <h1 className={styles.title}>
                            {t("users.detail.infoContract")}
                        </h1>
                        {data.contracts.map((contract) => {
                            return (
                                <div
                                    key={contract.number}
                                    className={styles.contractDetailWrapper}
                                >
                                    {selectedContract === contract.number ? (
                                        <>
                                            <Card className={styles.antet}>
                                                <div
                                                    className={
                                                        styles.mainWrapperAntet
                                                    }
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "5px",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.headerInfo
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles.starDateDescription
                                                                }
                                                                style={{
                                                                    paddingRight:
                                                                        "4px",
                                                                }}
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.number"
                                                                )}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles.starDate
                                                                }
                                                                style={{
                                                                    color: "rgba(99, 118, 139, 1)",
                                                                }}
                                                            >
                                                                {
                                                                    contract.number
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.headerInfo
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles.starDateDescription
                                                                }
                                                                style={{
                                                                    paddingRight:
                                                                        "4px",
                                                                }}
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.startDate"
                                                                )}
                                                            </p>
                                                            <p
                                                                className={
                                                                    styles.starDate
                                                                }
                                                            >
                                                                {moment(
                                                                    contract.startDate
                                                                ).format("L")}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.wrapper2Spans
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.electronicInvoiceWrapper
                                                        }
                                                    >
                                                        <p
                                                            className={
                                                                styles.electronicInvoiceDescription
                                                            }
                                                        >
                                                            {t(
                                                                "users.detail.contracts.electronicInvoice"
                                                            )}
                                                        </p>
                                                        {!contract.hasElectronicInvoice ? (
                                                            <p
                                                                className={
                                                                    styles.electronicInvoiceInactive
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.electronicInvoice.inactive"
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p
                                                                className={
                                                                    styles.electronicInvoiceActive
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.electronicInvoice.active"
                                                                )}
                                                                {moment(
                                                                    contract.electronicInvoiceActivationDate
                                                                ).format("L")}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>

                                            <div
                                                className={
                                                    styles.contractInvoiceWrapper
                                                }
                                            >
                                                <div>
                                                    <div
                                                        className={
                                                            styles.invoiceTitle
                                                        }
                                                    >
                                                        {t(
                                                            "users.detail.contracts.lastInvoice"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.invoiceCardWrapper
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.lastInvoice.number"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceNumber
                                                                }
                                                            >
                                                                {contract.lastAvailableInvoice
                                                                    ? contract
                                                                          .lastAvailableInvoice
                                                                          .serial +
                                                                      "/" +
                                                                      contract
                                                                          .lastAvailableInvoice
                                                                          .number
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.lastInvoiceDate"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceValues
                                                                }
                                                            >
                                                                {contract.lastAvailableInvoice
                                                                    ? moment(
                                                                          contract
                                                                              .lastAvailableInvoice
                                                                              .date
                                                                      ).format(
                                                                          "L"
                                                                      )
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                            style={{
                                                                paddingBottom:
                                                                    "15px",
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.value"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceValues
                                                                }
                                                            >
                                                                {contract.lastAvailableInvoice
                                                                    ? `${parseFloat(
                                                                          contract
                                                                              .lastAvailableInvoice
                                                                              .totalAmount
                                                                      ).toLocaleString(
                                                                          "ro-RO",
                                                                          {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2,
                                                                          }
                                                                      )} ${t(
                                                                          "users.detail.contracts.valueCurrency"
                                                                      )}`
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        className={
                                                            styles.invoiceTitle
                                                        }
                                                    >
                                                        {t(
                                                            "users.detail.contracts.lastInvoicePaid"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.invoiceCardWrapper
                                                        }
                                                        style={{
                                                            height: "188px",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.lastInvoice.number"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceNumber
                                                                }
                                                            >
                                                                {contract.lastPayedInvoice
                                                                    ? contract
                                                                          .lastPayedInvoice
                                                                          .serial +
                                                                      "/" +
                                                                      contract
                                                                          .lastPayedInvoice
                                                                          .number
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.lastInvoiceDate"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceValues
                                                                }
                                                            >
                                                                {contract.lastPayedInvoice
                                                                    ? moment(
                                                                          contract
                                                                              .lastPayedInvoice
                                                                              .invoiceDate
                                                                      ).format(
                                                                          "L"
                                                                      )
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.value"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceValues
                                                                }
                                                            >
                                                                {contract.lastPayedInvoice &&
                                                                contract
                                                                    .lastPayedInvoice
                                                                    .totalAmount
                                                                    ? `${parseFloat(
                                                                          contract
                                                                              .lastPayedInvoice
                                                                              .totalAmount
                                                                      ).toLocaleString(
                                                                          "ro-RO",
                                                                          {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2,
                                                                          }
                                                                      )} ${t(
                                                                          "users.detail.contracts.valueCurrency"
                                                                      )}`
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.paymentDate"
                                                                )}
                                                            </div>

                                                            <div
                                                                className={
                                                                    styles.invoiceValues
                                                                }
                                                            >
                                                                {contract.lastPayedInvoice &&
                                                                contract
                                                                    .lastPayedInvoice
                                                                    .paymentDate
                                                                    ? moment(
                                                                          contract
                                                                              .lastPayedInvoice
                                                                              .paymentDate
                                                                      ).format(
                                                                          "L"
                                                                      )
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.invoiceContentWrapper
                                                            }
                                                            style={{
                                                                paddingBottom:
                                                                    "15px",
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.invoiceDescription
                                                                }
                                                            >
                                                                {t(
                                                                    "users.detail.contracts.lastInvoicePaidDocument"
                                                                )}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.invoiceValues
                                                                }
                                                            >
                                                                {contract.lastPayedInvoice &&
                                                                contract
                                                                    .lastPayedInvoice
                                                                    .paymentDocument
                                                                    ? contract
                                                                          .lastPayedInvoice
                                                                          .paymentDocument
                                                                    : t(
                                                                          "noData"
                                                                      )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {contract.userPreferences && (
                                                <div
                                                    className={
                                                        styles.preferencesWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.invoiceTitle
                                                        }
                                                    >
                                                        {t(
                                                            "users.detail.contracts.notifications"
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.preferencesCardWrapper
                                                        }
                                                    >
                                                        <table
                                                            className={
                                                                styles.table
                                                            }
                                                        >
                                                            <thead>
                                                                <tr>
                                                                    <th
                                                                        style={{
                                                                            width: "38px",
                                                                        }}
                                                                    ></th>
                                                                    <th
                                                                        className={
                                                                            styles.tableHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.invoice"
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className={
                                                                            styles.tableHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.paymentConfirmation"
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className={
                                                                            styles.tableHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.paymentReminder"
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className={
                                                                            styles.tableHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.info"
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className={
                                                                            styles.tableHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.readingPeriod"
                                                                        )}
                                                                    </th>
                                                                    <th
                                                                        className={
                                                                            styles.tableHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.newReading"
                                                                        )}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th
                                                                        className={
                                                                            styles.verticalHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.email"
                                                                        )}
                                                                    </th>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .generateInvoiceEmail ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .payInvoiceEmail ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .dueInvoiceEmail ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .newsEmail ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>

                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .readingReminderEmail ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            border: "0",
                                                                        }}
                                                                    >
                                                                        {contract
                                                                            .userPreferences
                                                                            .readingAddedEmail ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        className={
                                                                            styles.verticalHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.sms"
                                                                        )}
                                                                    </th>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .generateInvoiceSms ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .payInvoiceSms ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .dueInvoiceSms ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .newsSms ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>

                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .readingReminderSms ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            border: "0",
                                                                        }}
                                                                    >
                                                                        {contract
                                                                            .userPreferences
                                                                            .readingAddedSms ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th
                                                                        className={
                                                                            styles.verticalHeader
                                                                        }
                                                                    >
                                                                        {t(
                                                                            "users.detail.contracts.notifications.push"
                                                                        )}
                                                                    </th>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .generateInvoicePush ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .payInvoicePush ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .dueInvoicePush ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .newsPush ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>

                                                                    <td>
                                                                        {contract
                                                                            .userPreferences
                                                                            .readingReminderPush ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            border: "0",
                                                                        }}
                                                                    >
                                                                        {contract
                                                                            .userPreferences
                                                                            .readingAddedPush ? (
                                                                            <IonIcon
                                                                                icon={
                                                                                    checkmarkCircleOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                                color="tertiary"
                                                                            />
                                                                        ) : (
                                                                            <IonIcon
                                                                                icon={
                                                                                    ellipseOutline
                                                                                }
                                                                                size={
                                                                                    "small"
                                                                                }
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            );
                        })}
                    </IonCard>
                </>
            ) : (
                <>
                    <IonCard className={styles.contractSelectCard}>
                        <h1 className={styles.title}>
                            {t("users.detail.contracts")}
                        </h1>
                        <div
                            className={styles.contractSelectCardWrapper}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                paddingTop: 0,
                                paddingBottom: "310px",
                            }}
                        >
                            {t("users.detail.noContracts")}
                        </div>
                    </IonCard>
                    <IonCard className={styles.contractCard}>
                        <h1 className={styles.title}>
                            {t("users.detail.infoContract")}
                        </h1>
                    </IonCard>
                </>
            )}
        </div>
    );
};

export default DetailContractCard;
