import React, {useMemo} from "react";
import { ReadingDetailAdmin } from "../../../types/responseTypes";
import Card from "../../../components/UI/Card";
import moment from "moment";
import { t } from "i18next";
import {IonIcon, IonLabel} from "@ionic/react";
import { locationSharp } from "ionicons/icons";

import styles from "../Readings.module.scss";

interface TabelProps {
    readingDetails: ReadingDetailAdmin;
}

const TabelInvoiceDetailPage: React.FC<TabelProps> = ({readingDetails}) => {
    const data = useMemo(() => readingDetails.readings, []);

    return (
        <>
            <IonLabel
                className={styles.title}
                style={{
                    marginBottom: "30px",
                }}
            >
                {t("readings.details.title")}
            </IonLabel>
            <Card className={styles.antet}>
                <div className={styles.mainWrapperAntet}>
                    <div style={{display: "flex", alignItems: "center", gap: "5px",
                        }}
                    ><img className={styles.imageUser} src={"https://test.aqmeter.com/v2/img/avatar.png"}alt="avatar"
                        />
                        <span>{`${readingDetails.contract.name}`}</span>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IonIcon icon={locationSharp} color={"tertiary"} size={"large"}/>
                        <span>{readingDetails.contract.address.county}
                            {readingDetails.contract.address.city
                                ? t("address.city") +
                                readingDetails.contract.address.city
                                : null}
                            {readingDetails.contract.address.street
                                ? t("address.street") +
                                readingDetails.contract.address.street
                                : null}
                            {readingDetails.contract.address.building
                                ? t("address.block") +
                                readingDetails.contract.address.building
                                : null}
                            {readingDetails.contract.address.entrance
                                ? t("address.sc") +
                                readingDetails.contract.address.entrance
                                : null}
                            {readingDetails.contract.address.floor
                                ? t("address.et") +
                                readingDetails.contract.address.floor
                                : null}
                            {readingDetails.contract.address.ap
                                ? t("address.ap") +
                                readingDetails.contract.address.ap
                                : null}
                            {readingDetails.contract.address.number
                                ? t("address.number") +
                                readingDetails.contract.address.number
                                : null}
                        </span>
                    </div>
                </div>
                <div className={styles.wrapper2Spans}>
                    <span>{`${t("readings.detailHeader.contractNumber")} ${
                        readingDetails.contract.number
                    }`}</span>
                </div>
            </Card>
            <table style={{ margin: "0 auto" }}>
                <thead className={styles.headerWrapper}>
                    <tr className={styles.headerDetail}>
                        <th>
                            {t("readings.detailTableColumns.seriesAndNumber")}
                        </th>
                        <th>{t("readings.detailTableColumns.readingDate")}</th>
                        <th>{t("readings.detailTableColumns.index")}</th>
                        <th>
                            {t(
                                "readings.detailTableColumns.differenceFromLastIndex"
                            )}
                        </th>
                        <th>{t("readings.detailTableColumns.readerName")}</th>
                    </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                {data.map((reading) => {
                    return (
                        <tr key={reading.id}>
                            <td>{`${reading.meterSerial} / ${
                                reading.meterNumber === null
                                    ? "-"
                                    : reading.meterNumber
                            }`}</td>
                            <td>{`${moment(reading.readingDate).format(
                                "L"
                            )}`}</td>
                            <td>{`${reading.value} mc`}</td>
                            <td>{`${reading.differenceFromLastIndex} mc`}</td>
                            <td>{`${
                                reading.readerName === null
                                    ? "-"
                                    : reading.readerName
                            }`}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </>
    );
};

export default TabelInvoiceDetailPage;
