import React, { useMemo } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import TablePagination from "../../components/Pagination/TablePagination";
import { ReadingAdmin, ReadingsAdmin } from "../../types/responseTypes";
import styles from "./Readings.module.scss";
import { t } from "i18next";
import TableHeader from "../../components/UI/TableHeader";
import SortingIcons from "../../components/UI/SortingIcons";

interface TabelProps {
    readingsData: ReadingsAdmin;
    setPageNumber: (pageNumber: number) => void;
    pageNumber: number;
    query?: URLSearchParams;
}

const TabelReadings: React.FC<TabelProps> = ({
    readingsData,
    setPageNumber,
    pageNumber,
    query,
}) => {
    const history = useHistory<History>();

    const data = useMemo<ReadingAdmin[]>(
        () => readingsData.content,
        [readingsData]
    );
    const totalPages = useMemo<number>(
        () => readingsData.totalPages,
        [readingsData]
    );
    const totalElements = useMemo<number>(
        () => readingsData.totalElements,
        [readingsData]
    );

    return (
        <>
            <table style={{ width: "100%" }}>
                <TableHeader
                    query={query}
                    totalElements={totalElements}
                    titleHeader={t("readings.title")}
                    urlStringPart={"readings"}
                    subtitleHeaderWithNumberOfElements={t(
                        "totalSubtitle.readings",
                        { readingsCount: totalElements }
                    )}
                />
                <thead className={styles.headerWrapper}>
                    <tr>
                        <SortingIcons
                            columnLabel={t("readings.tableColumns.Contracts")}
                            query={query}
                            urlStringPart={"readings"}
                            sortIndex={0}
                            deafultSort={false}
                        />
                        <SortingIcons
                            columnLabel={t("readings.tableColumns.Name")}
                            query={query}
                            urlStringPart={"readings"}
                            sortIndex={1}
                            deafultSort={false}
                            width={"270px"}
                        />
                        <th colSpan={4}>
                            {t("readings.tableColumns.Address")}
                        </th>
                        <SortingIcons
                            columnLabel={t("readings.tableColumns.readingDate")}
                            query={query}
                            urlStringPart={"readings"}
                            sortIndex={3}
                            deafultSort={true}
                            width={"200px"}
                        />
                        <SortingIcons
                            columnLabel={t("readings.tableColumns.index")}
                            urlStringPart={"readings"}
                            sortIndex={null}
                            deafultSort={false}
                        />
                    </tr>
                </thead>

                <tbody className={styles.bodyWrapper}>
                    {data.map((reading) => {
                        return (
                            <tr
                                key={reading.id}
                                onClick={() => {
                                    history.push(`/readings/${reading.id}`);
                                }}
                            >
                                <td
                                    className={"invoiceSerial"}
                                >{`${reading.contractNumber}`}</td>
                                <td
                                    style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {reading.contractName}
                                </td>
                                {reading.address !== null ? (
                                    <td colSpan={4}>
                                        {reading.address.county}
                                        {reading.address.city
                                            ? t("address.city") +
                                              reading.address.city
                                            : null}
                                        {reading.address.street
                                            ? t("address.street") +
                                              reading.address.street
                                            : null}
                                        {reading.address.building
                                            ? t("address.block") +
                                              reading.address.building
                                            : null}
                                        {reading.address.entrance
                                            ? t("address.sc") +
                                              reading.address.entrance
                                            : null}
                                        {reading.address.floor
                                            ? t("address.et") +
                                              reading.address.floor
                                            : null}
                                        {reading.address.ap
                                            ? t("address.ap") +
                                              reading.address.ap
                                            : null}
                                        {reading.address.number
                                            ? t("address.number") +
                                              reading.address.number
                                            : null}
                                    </td>
                                ) : (
                                    <td colSpan={4}>{t("noData")}</td>
                                )}
                                <td>
                                    {moment(reading.readingDate).format("L")}
                                </td>
                                <td>{`${reading.value} ${reading.unit} `}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"readings"}
            />
        </>
    );
};

export default TabelReadings;
