import React, { useContext, useEffect, useState } from "react";
import { GraphType } from "../../types/requestTypes";
import { useTranslation } from "react-i18next";
import UserContext from "../../context/UserContext";
import NoGraph from "./NoGraph";
import BarCharts from "./BarChart";
import AreaCharts from "./AreaChart";
import AuthContext from "../../context/AuthContext";

import styles from "./Dashboard.module.scss";

interface DashboardGraphsProps {
    dataUtilizatori: GraphType[];
    dataFacturiElectronice: GraphType[];
    dataPlatiOnline: GraphType[];
    dataAutocitiri: GraphType[];
}

const DashboardGraphs: React.FC<DashboardGraphsProps> = ({
    dataUtilizatori,
    dataFacturiElectronice,
    dataPlatiOnline,
    dataAutocitiri,
}) => {
    const { t } = useTranslation();
    const { layoutCtx, companiesCtx } = useContext(UserContext);
    const { userCompanies } = useContext(AuthContext);

    const [companyType, setCompanyType] = useState<string>("");

    useEffect(() => {
        companiesCtx.forEach((company) => {
            if (userCompanies.includes(company.id)) {
                setCompanyType(company.type);
            }
        });
    }, []);

    return (
        <>
            <div
                className={`${styles.container} ${
                    layoutCtx ? styles.extended : styles.restrained
                }`}
            >
                <div>
                    {dataUtilizatori.length > 0 ? (
                        <AreaCharts
                            data={dataUtilizatori}
                            title={t("dashboard.graph.title.users")}
                            barColor={"#007CEF"}
                            tooltipText={t("dashboard.graph.tooltip.users")}
                        />
                    ) : (
                        <NoGraph />
                    )}
                    {dataPlatiOnline.length > 0 ? (
                        <BarCharts
                            data={dataPlatiOnline}
                            title={t("dashboard.graph.title.payments")}
                            barColor={"#00C27C"}
                            tooltipText={t("dashboard.graph.tooltip.payments")}
                        />
                    ) : (
                        <NoGraph />
                    )}
                </div>
                <div>
                    {dataFacturiElectronice.length > 0 ? (
                        <BarCharts
                            data={dataFacturiElectronice}
                            title={t("dashboard.graph.title.invoices")}
                            barColor={"#59005c"}
                            tooltipText={t("dashboard.graph.tooltip.invoices")}
                        />
                    ) : (
                        <NoGraph />
                    )}

                    {dataAutocitiri.length > 0 ? (
                        companyType !== "WASTE" && (
                            <AreaCharts
                                data={dataAutocitiri}
                                title={t("dashboard.graph.title.readings")}
                                barColor={"#64DCF6"}
                                tooltipText={t(
                                    "dashboard.graph.tooltip.readings"
                                )}
                            />
                        )
                    ) : (
                        <NoGraph />
                    )}
                </div>
            </div>
        </>
    );
};

export default DashboardGraphs;
