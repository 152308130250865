import React from "react";
import { IonLabel, IonIcon, IonRouterLink } from "@ionic/react";
import {
    documentsOutline,
    logOutOutline,
    mailOpenOutline,
} from "ionicons/icons";

import "../NavigationBar.scss";

interface Props {
    extendMenu: boolean;
    onClickLogout: () => void;
}

const SuperAdminIcons: React.FC<Props> = ({ extendMenu, onClickLogout }) => {
    return (
        <>
            <IonRouterLink
                {...(!extendMenu && { title: "Arhivare facturi" })}
                routerLink={"/demandsC"}
                className="icon-menu"
                slot="start"
            >
                <IonLabel className="menu-element">
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={documentsOutline}
                    />

                    {extendMenu && (
                        <div className="menu-text">Arhivare facturi</div>
                    )}
                </IonLabel>
            </IonRouterLink>
            <IonRouterLink
                {...((!extendMenu && { title: "Dezarhivare facturi" }) || {})}
                routerLink={"/"}
                className="icon-menu"
                slot="start"
            >
                <IonLabel style={{ width: "auto" }}>
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={documentsOutline}
                    />

                    {extendMenu && (
                        <div className="menu-text">Dezarhivare facturi</div>
                    )}
                </IonLabel>
            </IonRouterLink>
            <IonRouterLink
                {...((!extendMenu && { title: "Arhivare citiri" }) || {})}
                routerLink={"/"}
                className="icon-menu"
                slot="start"
            >
                <IonLabel style={{ width: "auto" }}>
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={documentsOutline}
                    />

                    {extendMenu && (
                        <div className="menu-text">Arhivare citiri</div>
                    )}
                </IonLabel>
            </IonRouterLink>
            <IonRouterLink
                {...((!extendMenu && { title: "Dezarhivare citiri" }) || {})}
                routerLink={"/"}
                className="icon-menu"
                slot="start"
            >
                <IonLabel style={{ width: "auto" }}>
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={mailOpenOutline}
                    />

                    {extendMenu && (
                        <div className="menu-text">Dezarhivare citiri</div>
                    )}
                </IonLabel>
            </IonRouterLink>

            <IonRouterLink
                {...((!extendMenu && { title: "Logout" }) || {})}
                className="icon-menu"
                slot="start"
                onClick={onClickLogout}
            >
                <IonLabel style={{ width: "auto" }}>
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={logOutOutline}
                    />

                    {extendMenu && <div className="menu-text">Logout</div>}
                </IonLabel>
            </IonRouterLink>
        </>
    );
};

export default SuperAdminIcons;
