import StandardInput from "../../../components/UI/StandardInput";
import React from "react";

interface TitleInputPros {
    titleValue: string;
    setTitleValue: (title: string) => void;
    className?: string;
}

const TitleInput: React.FC<TitleInputPros> = ({
    titleValue,
    setTitleValue,
    className,
}) => {
    return (
        <>
            <StandardInput
                inputValue={titleValue}
                labelName={"Titlu"}
                setInputValue={(ev) => setTitleValue(ev.detail.value)}
                className={className}
            />
        </>
    );
};

export default TitleInput;
