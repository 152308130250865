import React, { useContext, useEffect, useState } from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonMenu,
    IonMenuToggle,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { menuOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import HelpLinks from "./HelpLinks";
import MainMenu from "./MainMenu";
import UserContext from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import { Company } from "../../types/responseTypes";
import { aqmeterActions } from "../../api/AqmeterActions";

import "./NavigationBar.scss";

type WindowSize = {
    innerWidth: number;
    innerHeight: number;
};

interface Props {
    companyType: string;
    userRoles: string[];
    userData: any;
    setSelected: (selected: string) => void;
    selected: string;
}

const NavigationBar: React.FC<Props> = ({
    companyType,
    userRoles,
    userData,
    setSelected,
    selected,
}) => {
    const { t } = useTranslation();

    const { setLayoutCtx, layoutCtx } = useContext(UserContext);

    const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (windowSize.innerWidth < 900) {
            setLayoutCtx(false);
        }
    }, [windowSize]);

    const [company, setCompany] = useState<Company[]>();

    const companyId: number = userData.userCompanies
        ? userData.userCompanies[0]
        : null;

    useEffect(() => {
        aqmeterActions.getCompanies().then((res) => {
            setCompany(res);
        });
    }, []);

    return (
        <>
            <IonMenu contentId="main-content" style={{ pointerEvents: "auto" }}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Menu Content</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonMenuToggle>
                        <IonButtons>
                            <div
                                className="icons"
                                style={{
                                    height: windowSize.innerHeight - 65,
                                }}
                            >
                                <MainMenu
                                    extendMenu={true}
                                    userRoles={userRoles}
                                    userData={userData}
                                    companyType={companyType}
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </div>
                        </IonButtons>
                    </IonMenuToggle>
                </IonContent>
            </IonMenu>

            <IonToolbar>
                <IonButtons slot="start">
                    <div className="main-icon">
                        {windowSize.innerWidth > 420 ? (
                            <IonButton onClick={() => setLayoutCtx(!layoutCtx)}>
                                <IonIcon slot="icon-only" icon={menuOutline} />
                            </IonButton>
                        ) : (
                            <>
                                <IonButtons slot="start">
                                    <div className="main-icon">
                                        <IonMenuToggle>
                                            <IonButton>
                                                <IonIcon
                                                    slot="icon-only"
                                                    icon={menuOutline}
                                                />
                                            </IonButton>
                                        </IonMenuToggle>
                                    </div>
                                </IonButtons>
                            </>
                        )}
                    </div>
                </IonButtons>

                <IonItem
                    onClick={() => setSelected("")}
                    slot="start"
                    lines="none"
                >
                    <Link className="icon-link" to="/dashboard">
                        <div className="link-wrapper">
                            <img
                                className="logo-image"
                                src="/assets/img/aqmeter-small.png"
                                alt="Aqmeter"
                            />
                            <p>{t("logo.text")}</p>
                        </div>
                    </Link>
                </IonItem>

                <CompanyName
                    company={company}
                    companyId={companyId}
                    windowSize={windowSize}
                />

                <HelpLinks />
            </IonToolbar>

            {windowSize.innerWidth > 420 && (
                <IonButtons slot="start">
                    <div
                        className="icons"
                        style={{
                            height: windowSize.innerHeight - 65,
                        }}
                    >
                        <MainMenu
                            extendMenu={layoutCtx}
                            userRoles={userRoles}
                            userData={userData}
                            companyType={companyType}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    </div>
                </IonButtons>
            )}
        </>
    );
};

export default NavigationBar;

const getWindowSize = (): WindowSize => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
};

interface CompanyNameProps {
    company: Company[];
    companyId: number;
    windowSize: WindowSize;
}

const CompanyName: ({
    company,
    companyId,
    windowSize,
}: CompanyNameProps) => JSX.Element = ({ company, companyId, windowSize }) => {
    return (
        <>
            {company !== undefined &&
                company
                    .filter((comp: Company) => comp.id === companyId)
                    .map((comp: Company) => {
                        if (windowSize.innerWidth >= 900) {
                            return (
                                <p
                                    key={comp.id}
                                    style={{
                                        fontSize: "16.5px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                    }}
                                >
                                    {comp.name}
                                </p>
                            );
                        } else if (windowSize.innerWidth < 620) {
                            return null;
                        } else {
                            return (
                                <p
                                    key={comp.id}
                                    style={{
                                        fontSize: "16.5px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                    }}
                                >
                                    {comp.shortName}
                                </p>
                            );
                        }
                    })}
        </>
    );
};
