import {IonAccordion, IonAccordionGroup, IonItem} from "@ionic/react";
import React from 'react';
import {Link} from "react-router-dom";

export const FaqContentRO: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <IonAccordionGroup multiple={true}>
        <IonAccordion value="ask1">
          <IonItem slot="header">
            <h6>Ce este Aqmeter?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>Aqmeter este o platformă mobilă și web dedicată companiilor de utilități.</p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask2">
          <IonItem slot="header">
            <h6>Care sunt funcționalitățile aplicației și care sunt beneficiile utilizatorilor?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Platforma oferă următoarele funcționalități în relația companiei cu abonații acesteia: înscrierea
              abonaților utilizând aplicații web și mobile, actualizarea datelor de contact contractuale (telefon,
              e-mail, etc.), autocitirea indexului contorului de apă, vizualizarea istoricului facturilor, plata
              online a facturilor, introducerea unei cereri de citire autorizate a contorului, trimiterea de alerte
              și notificări către abonați.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask3">
          <IonItem slot="header">
            <h6>Cum mă înregistrez ca utilizator Aqmeter?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Accesează secțiunea <Link to="/register">Creează cont nou</Link> și completează datele solicitate în
              formular. Înregistrarea este posibilă pentru utilizatorii care au contract cu un furnizor de utilități
              integrat cu platforma Aqmeter.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask4">
          <IonItem slot="header">
            <h6>Cât mă costa pentru a fi utilizator Aqmeter?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>Nu te costă nimic. Utilizarea aplicației este gratuită.</p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask5">
          <IonItem slot="header">
            <h6>Pot sa plătesc factura online?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Da, poți plăti factura online folosind un card bancar accesând secțiunea{" "}
              <Link to="/invoices">Facturi</Link>.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask6">
          <IonItem slot="header">
            <h6>Unde găsesc informaţiile despre conturile bancare în care pot face plata facturii?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Dacă vrei să achiți factura prin transfer bancar clasic, poți găsi contul bancar pe factura tipărită
              sau, dacă ai ales emiterea facturii în format electronic, în fișierul .PDF pe care îl poți descărca
              din contul tău, accesand secțiunea <Link to="/invoices">Facturi</Link>.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask7">
          <IonItem slot="header">
            <h6>Datele mele sunt în siguranță?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Da. Prelucrarea datelor cu caracter personal se află sub incidența legislației naționale privind
              protecția datelor, iar utilizatorul are dreptul la accesarea datelor, la informarea și la refuzul
              colectării acestora.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask8">
          <IonItem slot="header">
            <h6>Ce notificări pot să primesc de la platformă?</h6>
          </IonItem>
          <IonItem slot="content">
            <div>
              <p>Platforma Aqmeter poate trimite următoarele tipuri de notificări, în funcție de setările din contul
                 tău, pentru fiecare <Link to="/contracts">Contract</Link> în parte:</p>
              <ul>
                <li>Confirmarea plății facturilor folosind card bancar.</li>
                <li>Dacă optezi pentru factura electronică, vei primi un email cu un fisier .PDF atașat.</li>
                <li>
                  Dacă ai contract cu un furnizor de apă și funcția de autocitire activă, îți putem aduce aminte când
                  trebuie să
                  înregistrezi autocitirea contorul.
                </li>
                <li>Îți putem trimite alerte la depășirea scadenței unei facturi.</li>
              </ul>
            </div>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask9">
          <IonItem slot="header">
            <h6>Ce pot face dacă primesc factura electronică cu întârziere sau nu o primesc deloc?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Te rugăm să anunți situaţia, specificând cât mai multe detalii, folosind{" "}
              <Link to="/contact">formularul de contact</Link> din platformă. Dacă situația se datorează furnizorului
              tău de utilități, vom lua legătura cu acesta. În oricare dintre situații, vei fi ținut la curent cu
              rezolvarea.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask10">
          <IonItem slot="header">
            <h6>Cât timp rămân logat în aplicația Aqmeter?</h6>
          </IonItem>
          <IonItem slot="content" lines="none">
            <p>
              Pentru siguranța contului tău, portalul Aqmeter te păstrează logat în cont până la maxim 40 de zile de la
              ultima utilizare. Pentru durate mai lungi de inactivitate, vei fi nevoit să îți reintroduci datele de
              acces. Dacă nu dorești ca aplicația să te mențină logat, este suficient să ieși din cont după ce ai
              terminat de
              utilizat portalul.
            </p>
          </IonItem>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  );
};
