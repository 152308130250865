import { IonIcon } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import React from "react";
import { PasswordValidationType } from "../../types/otherTypes";
import styles from "./PasswordValidationTooltip.module.scss";

interface PasswordValidationTooltipProps {
    showTooltip: boolean;
    showValidationState: PasswordValidationType;
    position?: "top" | "bottom";
}

const PasswordValidationTooltip: React.FC<PasswordValidationTooltipProps> = ({
    showTooltip,
    showValidationState,
    position,
}) => {
    return (
        <div
            className={`${styles.tooltipWrapper} ${
                showTooltip ? styles.show : ""
            } ${position === "bottom" ? styles.bottom : ""}`}
        >
            <div className={styles.tooltipContent}>
                <IonIcon icon={informationCircle} />
                <p>{"Parola trebuie să conțină minim:"}</p>
                <ul>
                    <li
                        className={
                            showValidationState.minLength ? styles.valid : ""
                        }
                    >
                        {"8 caractere în total"}
                    </li>
                    <li
                        className={
                            showValidationState.lowerChar ? styles.valid : ""
                        }
                    >
                        {"o literă mică"}
                    </li>
                    <li
                        className={
                            showValidationState.upperChar ? styles.valid : ""
                        }
                    >
                        {"o literă mare"}
                    </li>
                    <li
                        className={
                            showValidationState.digit ? styles.valid : ""
                        }
                    >
                        {"o cifră"}
                    </li>
                    <li
                        className={
                            showValidationState.specialChar ? styles.valid : ""
                        }
                    >
                        {"un caracter special"}
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PasswordValidationTooltip;
