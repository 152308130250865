import React, { useState } from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import useQuery from "../../hooks/useQuery";
import TabelEmailReturn from "./Table/EmailReturnTable";
import useFetchCaching from "../../hooks/useFetchCaching";
import { BounceEmailRequest } from "../../types/requestTypes";

const EmailReturnPage: React.FC = () => {
    let query = useQuery();

    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );

    const urlWithSearch: string = `/admin/bounces?page=${query.get(
        "page"
    )}&searchTerm=${query.get(`searchTerm`)}&isMandatory=${query.get(
        "isMandatory"
    )}&isOptional=${query.get("isOptional")}&isRectified=${query.get(
        "isRectified"
    )}`;

    const urlWithoutSearch: string = `/admin/bounces?page=${query.get(
        "page"
    )}&isMandatory=${query.get("isMandatory")}&isOptional=${query.get(
        "isOptional"
    )}&isRectified=${query.get("isRectified")}`;

    const urlWithSearchSorted: string = `/admin/bounces?page=${query.get(
        "page"
    )}&searchTerm=${query.get(`searchTerm`)}&isMandatory=${query.get(
        "isMandatory"
    )}&isOptional=${query.get("isOptional")}&isRectified=${query.get(
        "isRectified"
    )}&sortIndex=${query.get("sortIndex")}&sortDirection=${query.get(
        "sortDirection"
    )}`;

    const urlWithoutSearchSorted: string = `/admin/bounces?page=${query.get(
        "page"
    )}&isMandatory=${query.get("isMandatory")}&isOptional=${query.get(
        "isOptional"
    )}&isRectified=${query.get("isRectified")}&sortIndex=${query.get(
        "sortIndex"
    )}&sortDirection=${query.get("sortDirection")}`;

    const { data } = useFetchCaching<BounceEmailRequest>(
        query.get("sortIndex") === null
            ? query.get("searchTerm") === null
                ? urlWithoutSearch
                : urlWithSearch
            : query.get("searchTerm") === null
            ? urlWithoutSearchSorted
            : urlWithSearchSorted
    );

    return (
        <IonPage>
            <SidebarLayout
                className="others-page"
                layoutType={"submenu"}
                menuSelected={"item1"}
            >
                <SkeletonList
                    loading={!data}
                    type={"simple"}
                    skeletonWidth={"1470px"}
                />

                {data && (
                    <TabelEmailReturn
                        key={query.get("page")}
                        query={query}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        bounceEmail={data}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default EmailReturnPage;
