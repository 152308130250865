import { IonButton, IonIcon, IonInput } from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import React, { useState } from "react";
import styles from "./PasswordInput.module.scss";

type PasswordInputProps = {
    value: string;
    placeholder: string;
    size?: "large";
    onChange: (e: any) => void;
    required: boolean;
    tooltip: "on" | "off";
    setShowTooltip: (value: boolean) => void;
};

const PasswordInput: React.FC<PasswordInputProps> = ({
    value,
    placeholder,
    size,
    onChange,
    required,
    tooltip,
    setShowTooltip,
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div
            className={`${styles.passWrapper} ${
                size === "large" ? styles.large : ""
            }`}
        >
            <IonButton
                onClick={() => setShowPassword((prevState) => !prevState)}
                className={showPassword ? styles.off : styles.on}
            >
                <IonIcon icon={showPassword ? eyeOff : eye} />
            </IonButton>
            <IonInput
                className={styles.passInput}
                value={value}
                onIonChange={(e) => onChange(e)}
                onIonBlur={() =>
                    tooltip === "on" ? setShowTooltip(false) : ""
                }
                onIonFocus={() =>
                    tooltip === "on" ? setShowTooltip(true) : ""
                }
                clearOnEdit={false}
                type={showPassword ? "text" : "password"}
                inputmode="text"
                autocomplete="off"
                placeholder={placeholder}
                required={required}
            />
        </div>
    );
};

export default PasswordInput;
