import React, {useContext} from "react";
import { IonIcon, IonLabel, IonRouterLink } from "@ionic/react";
import {
    documentAttachOutline,
    documentOutline,
    documentsOutline,
    documentTextOutline,
    informationCircleOutline,
    logOutOutline,
    mailOpenOutline,
    peopleOutline,
    settingsOutline,
    timerOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import IconMenuButton from "../IconMenuButton/IconMenuButton";

import "../NavigationBar.scss";
import {SettingsContext} from "../../../context/SettingsContext";

interface WaterAdminIconsProps {
    selected: string;
    setSelected: (selected: string) => void;
    extendMenu: boolean;
    onClickLogout: () => void;
}

const WaterAdminIcons: React.FC<WaterAdminIconsProps> = ({
    extendMenu,
    onClickLogout,
    selected,
    setSelected,
}) => {
    const { t } = useTranslation();
    const {isStaging} = useContext(SettingsContext)
    return (
        <>
            {isStaging ? <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/contract-demands?page=1"}
                icon={documentsOutline}
                tooltip={"navigation.contractDemands.title"}
                menuContent={"navigation.contractDemands"}
            /> : ""}
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/users?page=1&withContractOnly=true"}
                icon={peopleOutline}
                tooltip={"navigation.users.title"}
                menuContent={"navigation.users"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/contracts?page=1"}
                icon={documentOutline}
                tooltip={"navigation.contracts.title"}
                menuContent={"navigation.contracts"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/invoices?page=1"}
                icon={documentAttachOutline}
                tooltip={"navigation.invoices.title"}
                menuContent={"navigation.invoices"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/readings?page=1"}
                icon={timerOutline}
                tooltip={"navigation.readings"}
                menuContent={"navigation.readings"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/reading/export"}
                icon={documentTextOutline}
                tooltip={"navigation.export"}
                menuContent={"navigation.export"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={
                    "/bounce?page=1&isMandatory=true&isOptional=false&isRectified=false"
                }
                icon={mailOpenOutline}
                tooltip={"navigation.bounces"}
                menuContent={"navigation.bounces"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/news?page=1"}
                icon={informationCircleOutline}
                tooltip={"navigation.widgets"}
                menuContent={"navigation.widgets"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/more"}
                icon={settingsOutline}
                tooltip={"navigation.settings"}
                menuContent={"navigation.settings"}
            />
            <IonRouterLink
                title={extendMenu ? "" : t("navigation.logout")}
                className="icon-menu"
                slot="start"
                onClick={onClickLogout}
                color={selected === "logOutOutline" ? "tertiary" : ""}
            >
                <IonLabel
                    className="menu-element"
                    style={{
                        color: selected === "logOutOutline" ? "white" : "",
                    }}
                >
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={logOutOutline}
                    />

                    {extendMenu && (
                        <div className="menu-text">
                            {t("navigation.logout")}
                        </div>
                    )}
                </IonLabel>
            </IonRouterLink>
        </>
    );
};

export default WaterAdminIcons;
