import React from "react";
import { IonContent, IonRouterOutlet } from "@ionic/react";
import { Redirect, Route, Switch } from "react-router";
import DashboardPage from "../pages/1.Dashboard/DashboardPage";
import { TermsPage } from "../pages/5.More/Help/Terms/TermsPage";
import { PrivacyPage } from "../pages/5.More/Help/Privacy/PrivacyPage";
import { CookiesPage } from "../pages/5.More/Help/Cookies/CookiesPage";
import { FaqPage } from "../pages/5.More/Help/FAQ/FaqPage";
import ContractDemandsPage from "../pages/2. Contract Demands/ContractDemandsPage";
import MorePage from "../pages/5.More/MorePage";
import ProfilePage from "../pages/5.More/Admin/ProfilePage";
import SettingsPage from "../pages/5.More/Admin/SettingsPage";
import ChangePasswordPage from "../pages/5.More/Admin/ChangePasswordPage";
import UsersPage from "../pages/3.Users/UsersPage";
import InvoicesPage from "../pages/10. Invoices/InvoicesPage";
import DetailsPage from "../pages/3.Users/Details/DetailsPageUser";
import ContractsPage from "../pages/4.Contracts/ContractsPage";
import DetailsPageContract from "../pages/4.Contracts/Details/ContractDetailPage";
import DetailsInvoicePage from "../pages/10. Invoices/Details/DetailsInvoicePage";
import ReadingsPage from "../pages/6. Readings/ReadingsPage";
import ExportReadingsPage from "../pages/9.Export/ExportPage";
import DetailsReadingPage from "../pages/6. Readings/Details/DetailsReadingPage";
import NewsPage from "../pages/7. News/NewsPage";
import CreateNewsPage from "../pages/7. News/CreateNewsPage";
import DetailsNewsPage from "../pages/7. News/Details/DetailsNewsPage";
import EmailReturnPage from "../pages/8.EmailReturn/EmailReturnPage";

const AuthorizedRoutes: React.FC = () => {
    return (
        <IonContent>
            <IonRouterOutlet>
                <Switch>
                    <Route exact path="/" component={DashboardPage} />
                    <Route exact path="/terms" component={TermsPage} />
                    <Route exact path="/privacy" component={PrivacyPage} />
                    <Route exact path="/cookies" component={CookiesPage} />
                    <Route exact path="/faq" component={FaqPage} />
                    <Route exact path="/dashboard" component={DashboardPage} />
                    <Route
                        exact
                        path="/contract-demands"
                        component={ContractDemandsPage}
                    />
                    <Route exact path="/invoices" component={InvoicesPage} />
                    <Route
                        exact
                        path="/invoices/:invoiceId"
                        component={DetailsInvoicePage}
                    />

                    <Route exact path="/readings" component={ReadingsPage} />
                    <Route
                        exact
                        path="/readings/:readingId"
                        component={DetailsReadingPage}
                    />
                    <Route exact path="/news" component={NewsPage} />
                    <Route
                        exact
                        path="/news/create"
                        component={CreateNewsPage}
                    />
                    <Route
                        exact
                        path="/news/:newsId"
                        component={DetailsNewsPage}
                    />

                    <Route path={`/users/`} component={UsersPage} />
                    <Route path={`/user/:userId`} component={DetailsPage} />
                    <Route
                        exact
                        path="/home"
                        render={() => <Redirect to="/" />}
                    />
                    <Route path={`/contracts/`} component={ContractsPage} />
                    <Route
                        path={`/contract/:contractId`}
                        component={DetailsPageContract}
                    />
                    <Route
                        path={`/reading/export`}
                        component={ExportReadingsPage}
                    />
                    <Route path={`/bounce/`} component={EmailReturnPage} />
                    <Route exact path="/more" component={MorePage} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/settings" component={SettingsPage} />
                    <Route
                        exact
                        path="/change-password"
                        component={ChangePasswordPage}
                    />
                    <Route render={() => <Redirect to="/dashboard" />} />
                </Switch>
            </IonRouterOutlet>
        </IonContent>
    );
};

export default AuthorizedRoutes;
