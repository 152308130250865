import React, { createContext, ReactNode, useMemo, useState } from "react";
import { LoginResponse, Profile } from "../types/responseTypes";

type AuthContextValue = {
    isLoggedIn: boolean;
    nameCtx: string | null;
    mailCtx: string | null;
    accessTokenCtx: string | null;
    userId: number | null;
    userCompanies: number[];
    updateAuthCtx: (requestData: LoginResponse) => void;
    logoutCtx: () => void;
    updateNameCtx: (newName: string) => void;
    updateProfileCtx: (data: Profile) => void;
    versionCtx: string | null;
    updateVersionCtx: (newVersion: string) => void;
    sessionInfo: boolean;
    updateSessionInfo: (session: boolean) => void;
};

type Props = {
    children: ReactNode;
};

const AuthContext = createContext<AuthContextValue>({
    isLoggedIn: false,
    nameCtx: null,
    mailCtx: null,
    accessTokenCtx: null,
    userId: null,
    versionCtx: null,
    sessionInfo: false,
    userCompanies: [],
    updateAuthCtx: () => {},
    logoutCtx: () => {},
    updateNameCtx: () => {},
    updateProfileCtx: () => {},
    updateVersionCtx: () => {},
    updateSessionInfo:() => {}
});

export const AuthProvider = ({ children }: Props) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [nameCtx, setNameCtx] = useState<string | null>(null);
    const [mailCtx, setMailCtx] = useState<string | null>(null);
    const [accessTokenCtx, setAccessTokenCtx] = useState<string | null>(null);
    const [userId, setUserId] = useState<number | null>(null);
    const [userCompanies, setUserCompanies] = useState<number[]>([]);
    const [versionCtx, setVersionCtx] = useState(null);
    const [sessionInfo, setSessionInfo] = useState(null);

    const updateAuthCtx = (requestData: LoginResponse) => {
        const accessToken = requestData?.accessToken;

        setAccessTokenCtx(accessToken);
        setIsLoggedIn(true);
        setNameCtx(requestData.name);
        setMailCtx(requestData.email);
        setUserCompanies(
            !requestData.companyIdList ? [] : requestData.companyIdList
        );
        localStorage.setItem("login", 'true')
    };

    const logoutCtx = () => {
        setIsLoggedIn(false);
        setNameCtx(null);
        setMailCtx(null);
        setAccessTokenCtx(null);
    };

    const updateNameCtx = (newName: string) => {
        setNameCtx(newName);
    };

    const updateVersionCtx = (newVersion: string) => {
        setVersionCtx(newVersion);
    }
    const updateSessionInfo = (session: boolean) => {
        setSessionInfo(session);
    }

    const updateProfileCtx = (data: Profile) => {
        setNameCtx(data.name);
        setMailCtx(data.email);
        setUserId(data.userId);
    };

    const contextValue: AuthContextValue = useMemo(
        () => ({
            isLoggedIn,
            nameCtx,
            versionCtx,
            sessionInfo,
            mailCtx,
            accessTokenCtx,
            userId,
            userCompanies,
            updateAuthCtx,
            logoutCtx,
            updateNameCtx,
            updateProfileCtx,
            updateVersionCtx,
            updateSessionInfo
        }),
        [
            isLoggedIn,
            nameCtx,
            versionCtx,
            sessionInfo,
            mailCtx,
            accessTokenCtx,
            userId,
            userCompanies,
            updateAuthCtx,
            logoutCtx,
            updateNameCtx,
            updateProfileCtx,
            updateVersionCtx,
            updateSessionInfo
        ]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
