import { IonLabel, IonPage } from "@ionic/react";
import React from "react";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useTranslation } from "react-i18next";

import styles from "./ForgotPasswordPage.module.scss";

const ForgotPasswordPage: React.FC = () => {
    const { t } = useTranslation();
    return (
        <IonPage className={`layout--`}>
            <SidebarLayout layoutType={"back"}>
                <section className={styles.forgotPassWrapper}>
                    <img
                        src="/assets/img/aqmeter-small.png"
                        alt="Aqmeter"
                        className={styles.logo}
                    />
                    <IonLabel className={styles.title}>
                        {t("passwordChange.title")}
                    </IonLabel>

                    <ForgotPasswordForm />
                </section>
            </SidebarLayout>
        </IonPage>
    );
};

export default ForgotPasswordPage;
