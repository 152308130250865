import { IonButton, IonInput, IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import validator from "validator";
import { userActions } from "../../../api/UserActions";
import PasswordInput from "../../../components/PasswordComponents/PasswordInput";
import PasswordValidationTooltip from "../../../components/PasswordComponents/PasswordValidationTooltip";
import ResendConfirmationNotice from "../../../features/ResendConfirmationNotice";
import { UnconfirmedResponse } from "../../../types/otherTypes";
import { ChangePassword } from "../../../types/requestTypes";
import { ExceptionDto } from "../../../types/schema";
import { passMinLength, regex } from "../../../utils/globalVariables";
import styles from "./ForgotPasswordPage.module.scss";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import { useTranslation } from "react-i18next";

const ForgotPasswordForm: React.FC = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>("");
    const [pass1, setPass1] = useState<string>("");
    const [pass2, setPass2] = useState<string>("");
    const [validCaptcha] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [unconfirmedType, setUnconfirmedType] =
        useState<UnconfirmedResponse>();
    const [showValidationState, setShowValidationState] = useState({
        minLength: false,
        specialChar: false,
        lowerChar: false,
        upperChar: false,
        digit: false,
    });

    const validatePass1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue: string = e.target.value;
        setPass1(inputValue);
        setShowValidationState({
            minLength: inputValue.length >= passMinLength,
            specialChar: regex.specialChar.test(inputValue),
            lowerChar: regex.lowerChar.test(inputValue),
            upperChar: regex.upperChar.test(inputValue),
            digit: regex.digit.test(inputValue),
        });
    };

    const changePass2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPass2(e.target.value);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!email) {
            setLoading(false);
            toastAdd(t("err.MANDATORY_EMAIL"), "error");
            return;
        }
        if (!validator.isEmail(email)) {
            setLoading(false);
            toastAdd(t("err.EMAIL_NOT_VALID"), "error");
            return;
        }
        if (!pass1) {
            setLoading(false);
            toastAdd(t("err.MANDATORY_PASSWORD"), "error");
            return;
        }
        if (pass1.length < passMinLength) {
            setLoading(false);
            toastAdd(t("err.PASSWORD_MIN_8_CHARACTERS"), "error");
            return;
        }
        if (!regex.upperChar.test(pass1)) {
            setLoading(false);
            toastAdd(t("err.PASSWORD_MIN_1_UPPER"), "error");
            return;
        }
        if (!regex.lowerChar.test(pass1)) {
            setLoading(false);
            toastAdd(t("err.PASSWORD_MIN_1_AZ"), "error");
            return;
        }
        if (!regex.digit.test(pass1)) {
            setLoading(false);
            toastAdd(t("err.PASSWORD_MIN_1_09"), "error");
            return;
        }
        if (!regex.specialChar.test(pass1)) {
            setLoading(false);
            toastAdd(t("err.PASSWORD_MIN_1_SPECIAL_CHAR"), "error");
            return;
        }
        if (pass1 !== pass2) {
            setLoading(false);
            toastAdd(t("err.PASSWORD_IDENTIC_PASSWORD2"), "error");
            return;
        }

        try {
            const data: ChangePassword = {
                email: email,
                password: pass1,
            };
            await userActions.changeUserPass(data);
            setLoading(false);
            history.replace("./login");
            toastAdd(t("passwordChangeSuccess"), "info");
        } catch (err: any) {
            setLoading(false);
            const exception: ExceptionDto = err.response.data;
            if (exception.message === "USER_DOES_NOT_EXIST") {
                toastAdd(t("passwordChangeSuccess"), "info");
            } else if (exception.message === "USER_IS_UNCONFIRMED") {
                setUnconfirmedType({
                    type: "account",
                    resendUrl: exception.resendUrl,
                });
            } else {
                toastAdd(
                    t([`err.${exception.message}`, "passwordChangeFailed"]),
                    "error"
                );
            }
        }
    };

    useEffect(() => {
        if (!!unconfirmedType) {
            setUnconfirmedType(null);
        }
    }, [email, pass1, pass2]);

    return (
        <form
            className={styles.form}
            onSubmit={submitHandler}
            data-testid="forgot-password-form"
        >
            <IonInput
                onIonChange={(e) => setEmail(e.target.value as string)}
                value={email}
                type="text"
                inputmode="email"
                autocomplete="off"
                placeholder={t("passwordChange.email.placeholder")}
                required={true}
            />

            <PasswordValidationTooltip
                showTooltip={showTooltip}
                showValidationState={showValidationState}
            />

            <PasswordInput
                value={pass1}
                placeholder={t("passwordChange.newPassword.placeholder")}
                size={"large"}
                onChange={validatePass1}
                required={true}
                tooltip={"on"}
                setShowTooltip={setShowTooltip}
            />
            <PasswordInput
                value={pass2}
                placeholder={t("passwordChange.newPasswordRepeat.placeholder")}
                size={"large"}
                onChange={changePass2}
                required={true}
                tooltip={"off"}
                setShowTooltip={setShowTooltip}
            />

            {unconfirmedType?.type === "account" ? (
                <ResendConfirmationNotice
                    type={"account"}
                    resendUrl={unconfirmedType?.resendUrl}
                />
            ) : (
                ""
            )}

            {!unconfirmedType ? (
                <>
                    <div className={styles.captcha}></div>

                    <IonButton
                        strong={true}
                        type="submit"
                        color="primary"
                        expand="block"
                        disabled={isLoading || !email || !pass1 || !pass2}
                    >
                        {isLoading ? (
                            <>
                                <IonSpinner name="circles" />
                                {t("passwordChange.loading")}
                            </>
                        ) : (
                            t("passwordChange.button")
                        )}
                    </IonButton>

                    <input
                        type="submit"
                        className="submit-enter"
                        disabled={isLoading || !validCaptcha}
                    />
                </>
            ) : (
                ""
            )}
        </form>
    );
};

export default ForgotPasswordForm;
