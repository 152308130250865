import { useEffect, useReducer, useRef } from "react";
import useAxiosPrivate from "./useAxiosPrivate";

const cacheMaxAge = 3000; //30 minutes

interface State<T> {
    data: T | null;
    error?: Error;
}

type Cache<T> = { [url: string]: { value: T | null; created: number } };



// discriminated union type
type Action<T> =
    | { type: "loading" }
    | { type: "fetched"; payload: T }
    | { type: "error"; payload: Error };

//acest hook a fost modificat și răzmodificat. nu s-a acordat suficient timp pentru a fi înlocuit și gestionat cum trebuie
//rămîne o mostră a vechilor timpuri
function useFetchCaching<T = unknown>(
    url?: string,
    reload?: number,
    type?: "reload.soft" | "reload.hard"
): State<T> {
    const cache = useRef<Cache<T>>({});

    const axiosPrivate = useAxiosPrivate();

    // Used to prevent state update if the component is unmounted
    // const cancelRequest = useRef<boolean>(false);
    let cancelRequest = false;
    const initialState: State<T> = {
        error: undefined,
        data: null,
    };

    // Keep state logic separated
    const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
        switch (action.type) {
            case "loading":
                return { ...initialState };
            case "fetched":
                return { ...initialState, data: action.payload };
            case "error":
                return { ...initialState, error: action.payload };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(fetchReducer, initialState);

    useEffect(() => {
        cancelRequest = false;

        if (reload) {
            if (url && cache.current[url]) {
                if (type === "reload.hard") {
                    cache.current[url] = { value: null, created: Date.now() };
                }
            } else if (!url) {
                cache.current = {};
            }
        }

        // Do nothing if the url is not given
        if (!url) return;

        const fetchData = async () => {
            dispatch({ type: "loading" });

            // If a cache exists for this url, return it
            if (
                cache.current[url] &&
                cache.current[url].value &&
                Date.now() - cache.current[url].created > cacheMaxAge
            ) {
                dispatch({
                    type: "fetched",
                    payload: cache.current[url].value!!,
                });
                return;
            }

            try {
                const response = await axiosPrivate.get(url);
                if (!(response.status >= 200 && response.status)) {
                    throw new Error(response.statusText);
                }

                //const data = (await response.json()) as T;
                const data = response.data as T;
                cache.current[url] = { value: data, created: Date.now() };

                if (cancelRequest) {
                    // console.log("cancel request: " + url);
                    return;
                }

                dispatch({ type: "fetched", payload: data });
            } catch (error) {
                if (cancelRequest) return;

                dispatch({ type: "error", payload: error as Error });
            }
        };

        fetchData();

        // Use the cleanup function for avoiding a possibly...
        // ...state update after the component was unmounted
        return () => {
            cancelRequest = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, reload]);


        return state;


}

export default useFetchCaching;
