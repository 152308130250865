import React from "react";
import {IonRouterOutlet} from "@ionic/react";
import {Redirect, Route} from "react-router";
import LoginPage from "../pages/0.Authentication/Login/LoginPage";
import NotFound from "../pages/5.More/NotFound/NotFound";
import ForgotPasswordPage from "../pages/0.Authentication/ForgotPassword/ForgotPasswordPage";
import ConfirmNewPasswordPage from "../components/ConfirmNewPassword/ConfirmNewPasswordPage";

const UnauthorizedRoutes: React.FC = () => {
    return (
        <IonRouterOutlet>
            <Route exact path="/login" component={LoginPage}/>
            <Route exact path="/login/:returnUrl" component={LoginPage}/>
            <Route exact path="/" component={LoginPage}/>
            <Route exact path="/not-found" component={NotFound}/>
            <Route
                exact
                path={"/forgot-password"}
                component={ForgotPasswordPage}
            />
            <Redirect to="/login"/>

            {/*RUTE EXISTENTE IN AUTHORIZED ROUTES*/}
            <Route exact path="/terms" component={LoginPage}/>
            <Route exact path="/privacy" component={LoginPage}/>
            <Route exact path="/cookies" component={LoginPage}/>
            <Route exact path="/faq" component={LoginPage}/>
            <Route exact path="/home" component={LoginPage}/>
            <Route exact path="/password-recovery/:token" component={ConfirmNewPasswordPage}
            />
        </IonRouterOutlet>
    );
};

export default UnauthorizedRoutes;
