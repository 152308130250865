import PropTypes from "prop-types";
import { FC, ReactNode, useMemo } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { Language } from "../types/otherTypes";

interface Settings {
    direction?: "ltr" | "rtl";
    language: Language;
    pinSidebar: boolean;
    theme?: string;
    maxw: number;
    env: string; // "development" | "staging" | "production"
    version: string;
    showEcoStep: boolean;
    recaptchaSiteKey: string;
    domain: string;
    apiUrl: string;
}

export interface SettingsContextValue {
    settings: Settings;
    saveSettings: (update: Settings) => void;
    isStaging: boolean;
}

interface SettingsProviderProps {
    children?: ReactNode;
}

// e ok sa fie cheia de recaptcha aici, pentru ca este restrictionata
// sa poata fi utilizata doar pe domeniul aqmeter
const initialSettings: Settings = {
    direction: "ltr",
    language: "ro",
    pinSidebar: true,
    theme: "light",
    maxw: 620,
    env: process.env.REACT_APP_ENV,
    version: "6.8.0",
    showEcoStep: false,
    recaptchaSiteKey: "6LfGMo0UAAAAABr0x1REY6o3kvnce-pfWfDNTsd2",
    domain: process.env.REACT_APP_DOMAIN,
    apiUrl: process.env.REACT_APP_API_URL,
};

export const restoreSettings = (): Settings | null => {
    let settings = null;

    try {
        const storedData = window.localStorage.getItem("settings");

        if (storedData) {
            settings = JSON.parse(storedData);
        } else {
            settings = {
                direction: "ltr",
                language: "ro",
                pinSidebar: true,
                theme: "light",
                // theme: window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
            };
        }
    } catch (err) {
        console.error(err);
        // If stored data is not a strigified JSON this will fail,
        // that's why we catch the error
    }

    return settings;
};

export const storeSettings = (settings: Settings): void => {
    window.localStorage.setItem("settings", JSON.stringify(settings));
};

export const SettingsContext = createContext<SettingsContextValue>({
    settings: initialSettings,
    saveSettings: () => {},
    isStaging: initialSettings.env === "staging"
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
    const { children } = props;
    const [settings, setSettings] = useState<Settings>(initialSettings);
    let isStaging

    useEffect(() => {
        const restoredSettings = restoreSettings();

        if (restoredSettings) {
            setSettings(restoredSettings);
        }
    }, []);

    const saveSettings = (updatedSettings: Settings): void => {
        setSettings(updatedSettings);
        storeSettings(updatedSettings);
    };

    const contextValue: SettingsContextValue = useMemo(
        () => ({
            settings,
            saveSettings,
            isStaging
        }),
        [settings, saveSettings]
    );

    return (
        <SettingsContext.Provider value={contextValue}>
            {children}
        </SettingsContext.Provider>
    );
};

SettingsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export const useSettings = () => useContext(SettingsContext);
