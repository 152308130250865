import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React from "react";
import { BounceEmail } from "../../../types/requestTypes";
import { closeOutline } from "ionicons/icons";

interface EmailReturnModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    bounceEmail: BounceEmail;
}

const EmailReturnModal: React.FC<EmailReturnModalProps> = ({
    setShowModal,
    showModal,
    bounceEmail,
}) => {
    function createMarkup() {
        return { __html: bounceEmail.bounceDescription };
    }
    return (
        <IonModal
            isOpen={showModal}
            onDidDismiss={() => setShowModal(false)}
            backdropDismiss={false}
        >
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        {bounceEmail.type + " " + bounceEmail.subType}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            onClick={(event) => {
                                event.stopPropagation();
                                setShowModal(false);
                            }}
                        >
                            <IonIcon slot="icon-only" icon={closeOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonText
                    className="confirm-deletion"
                    dangerouslySetInnerHTML={createMarkup()}
                />
            </IonContent>
        </IonModal>
    );
};

export default EmailReturnModal;
