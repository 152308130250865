import React, {useMemo} from "react";
import {InvoiceDetailAdmin} from "../../../types/responseTypes";
import Card from "../../../components/UI/Card";
import moment from "moment";
import {t} from "i18next";
import {IonIcon, IonLabel} from "@ionic/react";
import {locationSharp} from "ionicons/icons";

import styles from "../Invoices.module.scss";

interface TabelProps {
    invoiceDetails: InvoiceDetailAdmin;
}

const TabelInvoiceDetailPage: React.FC<TabelProps> = ({invoiceDetails}) => {
    const data = useMemo(() => invoiceDetails.details, []);

    const MakeRoStringWith2Digits = (value) => {
        if (typeof value === "string") {
            return parseFloat(value).toLocaleString("ro", {
                minimumFractionDigits: 2,
            });
        }
        return value===null ? "-" : value.toLocaleString("ro", {
            minimumFractionDigits: 2,
        });
    };

    return (
        <>
            <IonLabel
                className={styles.title}
                style={{
                    marginBottom: "30px",
                }}
            >
                {t("invoices.detail.title")}
            </IonLabel>
            <Card className={styles.antet}>
                <div className={styles.mainWrapperAntet}>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <img
                            className={styles.imageUser}
                            src={"https://test.aqmeter.com/v2/img/avatar.png"}
                            alt="avatar"
                        />
                        <span>{`${invoiceDetails.name}`}</span>
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <IonIcon
                            icon={locationSharp}
                            color={"tertiary"}
                            size={"large"}
                        />
                        <span>{!(invoiceDetails.address===null) ? (`
                            ${invoiceDetails.address.county
                                ? invoiceDetails.address.county
                                : "" +invoiceDetails.address.city
                                ? t("address.city") +
                                invoiceDetails.address.city
                                : ""}
                            ${invoiceDetails.address.street
                                ? t("address.street") +
                                invoiceDetails.address.street
                                : ""}
                            ${invoiceDetails.address.building
                                ? t("address.block") +
                                invoiceDetails.address.building
                                : ""}
                            ${invoiceDetails.address.entrance
                                ? t("address.sc") +
                                invoiceDetails.address.entrance
                                : ""}
                            ${invoiceDetails.address.floor
                                ? t("address.et") +
                                invoiceDetails.address.floor
                                : ""}
                            ${invoiceDetails.address.ap
                                ? t("address.ap") +
                                invoiceDetails.address.ap
                                : ""}
                            ${invoiceDetails.address.number
                                ? t("address.number") +
                                invoiceDetails.address.number
                                : ""}`) : "-"}</span>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "50px",
                        justifyContent: "space-between",
                    }}
                >
                    <div className={styles.wrapper2Spans}>
                        <span>{`${t("invoices.detailHeader.seriesAndNumber")} ${
                            invoiceDetails.serial
                        }/${invoiceDetails.number}`}</span>
                        <span>
                            {`${t(
                                "invoices.detailHeader.invoiceDate"
                            )} ${moment(invoiceDetails.invoiceDate).format(
                                "L"
                            )}`}
                        </span>
                        <span>{`${t("invoices.detailHeader.dueDate")} ${moment(
                            invoiceDetails.dueDate
                        ).format("L")}`}</span>
                    </div>
                    <div className={styles.wrapper2Spans}>
                        <span>{`${t("invoices.detailHeader.invoiceAmount")} ${MakeRoStringWith2Digits(invoiceDetails.invoiceAmount)} ${t("currency")}`}</span>
                        {parseFloat(invoiceDetails.dueAmount)===0.0 ? (<div style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <span
                                style={{
                                    color: "var(--ion-color-tertiary)",
                                    backgroundColor: "rgba(108, 184, 255, 0.29)",
                                    alignSelf: 'right',
                                    padding: "3px 10px",
                                    borderRadius: "23px",
                                    fontWeight: "600"
                                }}>Achitată
                            </span>
                        </div>) : (
                            <span>{`${t("invoices.detailHeader.dueAmount")} ${MakeRoStringWith2Digits(invoiceDetails.dueAmount)} ${t("currency")}`}</span>
                        )}
                    </div>
                </div>
                {/*<div className={styles.wrapper2Spans}>*/}
                {/*    <span>{`${*/}
                {/*        invoiceDetails.paid ? t("invoices.detailHeader.paid") : t("invoices.detailHeader.!paid")*/}
                {/*    }`}</span>*/}
                {/*</div>*/}
            </Card>
            {data.length > 0 ? (
                <table style={{margin: "0 auto"}}>
                    <thead className={styles.headerWrapper}>
                    <tr className={styles.headerDetail}>
                        <th>
                            {t("invoices.detailTableColumns.consumption")}
                        </th>
                        <th>{t("invoices.detailTableColumns.um")}</th>
                        <th>
                            {t("invoices.detailTableColumns.description")}
                        </th>
                        <th>
                            {t("invoices.detailTableColumns.unitPrice")}
                        </th>
                        <th>{t("invoices.detailTableColumns.amount")}</th>
                        <th>{t("invoices.detailTableColumns.vatRate")}</th>
                        <th>{t("invoices.detailTableColumns.vatValue")}</th>
                    </tr>
                    </thead>
                    <tbody className={styles.bodyWrapper}>
                    {data.map((invoice) => {
                        return (
                            <tr key={invoice.id}>
                                <td>{`${invoice.consumption}`}</td>
                                <td>{`${invoice.um}`}</td>
                                <td>{`${
                                    invoice.description === null
                                        ? "-"
                                        : invoice.description
                                }`}</td>
                                <td>{`${MakeRoStringWith2Digits(
                                    invoice.unitPrice
                                )} ${invoice.unitPrice === null ? "" : t("currency")}`}</td>
                                <td>{`${MakeRoStringWith2Digits(
                                    invoice.amount
                                )} ${t("currency")}`}</td>
                                <td>{`${invoice.vatRate}`}</td>
                                <td>{`${MakeRoStringWith2Digits(
                                    invoice.vatValue
                                )} ${t("currency")}`}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            ) : (
                <h4 style={{textAlign: "center", color: "gray", fontWeight: "500"}}>
                    Nu există detalii suplimentare.
                </h4>
            )}
        </>
    );
};

export default TabelInvoiceDetailPage;
