import React, {useMemo} from "react";
import {IonButton} from "@ionic/react";
import {Invoice, InvoicesData} from "../../../types/requestTypes";
import moment from "moment";
import {useHistory} from "react-router";
import TablePagination from "../../../components/Pagination/TablePagination";
import {t} from "i18next";
import TableHeader from "../../../components/UI/TableHeader";

import styles from "../Invoices.module.scss";
import SortingIcons from "../../../components/UI/SortingIcons";

interface TabelProps {
    invoicesData: InvoicesData;
    setPageNumber: (pageNumber: number) => void;
    pageNumber: number;
    query?: URLSearchParams;
}

const TabelInvoices: React.FC<TabelProps> = ({
                                                 invoicesData,
                                                 setPageNumber,
                                                 pageNumber,
                                                 query,
                                             }) => {
    const history = useHistory<History>();

    const data = useMemo<Invoice[]>(() => invoicesData.content, [invoicesData]);
    const totalPages = useMemo<number>(
        () => invoicesData.totalPages,
        [invoicesData]
    );
    const totalElements = useMemo<number>(
        () => invoicesData.totalElements,
        [invoicesData]
    );

    return (
        <>
            <table style={{width: "100%"}}>
                <TableHeader
                    query={query}
                    totalElements={totalElements}
                    titleHeader={t("invoices.title")}
                    urlStringPart={"invoices"}
                    subtitleHeaderWithNumberOfElements={t(
                        "totalSubtitle.invoices",
                        {invoicesCount: totalElements}
                    )}
                />
                <thead className={styles.headerWrapper}>
                <tr>
                    <SortingIcons
                        columnLabel={t(
                            "invoices.tableColumns.seriesAndNumber"
                        )}
                        query={query}
                        urlStringPart={"invoices"}
                        sortIndex={0}
                        deafultSort={false}
                        width={"200px"}
                    />
                    <SortingIcons
                        columnLabel={t("invoices.tableColumns.name")}
                        query={query}
                        urlStringPart={"invoices"}
                        sortIndex={1}
                        deafultSort={false}
                        width={"200px"}
                    />
                    <SortingIcons
                        columnLabel={t("invoices.tableColumns.address")}
                        urlStringPart={"invoices"}
                        sortIndex={null}
                        deafultSort={false}
                    />
                    <SortingIcons
                        columnLabel={t("invoices.tableColumns.invoiceDate")}
                        query={query}
                        urlStringPart={"invoices"}
                        sortIndex={3}
                        deafultSort={true}
                        width={"139px"}
                    />
                    <SortingIcons
                        columnLabel={t("invoices.tableColumns.dueDate")}
                        query={query}
                        urlStringPart={"invoices"}
                        sortIndex={4}
                        deafultSort={false}
                        width={"139px"}
                    />
                    <SortingIcons
                        columnLabel={t(
                            "invoices.tableColumns.invoiceAmount"
                        )}
                        urlStringPart={"invoices"}
                        sortIndex={null}
                        deafultSort={false}
                        width={"139px"}
                    />
                    <SortingIcons
                        columnLabel={t("invoices.tableColumns.invoiceLink")}
                        urlStringPart={"invoices"}
                        sortIndex={null}
                        deafultSort={false}
                        width={"139px"}
                    />
                </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                {data.map((invoice) => {
                    return (
                        <tr
                            key={invoice.id}
                            onClick={() => {
                                history.push(`/invoices/${invoice.id}`);
                            }}
                        >
                            <td
                                className={"invoiceSerial"}
                            >{`${invoice.serial}/${invoice.number}`}</td>
                            <td
                                style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                }}
                            >
                                {invoice.name}
                            </td>
                            <td>
                                {invoice.address !== null
                                    ? `
                                ${
                                        invoice.address.county
                                            ? invoice.address.county
                                            : "" + invoice.address.city
                                                ? t("address.city") +
                                                invoice.address.city
                                                : ""
                                    }${
                                        invoice.address.street
                                            ? t("address.street") +
                                            invoice.address.street
                                            : ""
                                    }${
                                        invoice.address.building
                                            ? t("address.block") +
                                            invoice.address.building
                                            : ""
                                    }${
                                        invoice.address.entrance
                                            ? t("address.sc") +
                                            invoice.address.entrance
                                            : ""
                                    }${
                                        invoice.address.floor
                                            ? t("address.et") +
                                            invoice.address.floor
                                            : ""
                                    }${
                                        invoice.address.ap
                                            ? t("address.ap") +
                                            invoice.address.ap
                                            : ""
                                    }${
                                        invoice.address.number
                                            ? t("address.number") +
                                            invoice.address.number
                                            : ""
                                    }`
                                    : "-"}
                            </td>
                            <td>
                                {moment(invoice.invoiceDate).format("L")}
                            </td>
                            <td>{moment(invoice.dueDate).format("L")}</td>
                            <td>{`${parseFloat(
                                invoice.invoiceAmount
                            ).toLocaleString("ro", {
                                minimumFractionDigits: 2,
                            })} RON`}</td>
                            <td>
                                <a
                                    style={{padding: "0 auto", pointerEvents: invoice.shouldPdfBeAvailable ? "auto" : "none"}}
                                    href={invoice.invoiceLink}
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <IonButton
                                        fill={"clear"}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <img
                                            src={invoice.shouldPdfBeAvailable ?
                                                "/assets/img/pdf-image40.png" : "/assets/img/pdf-image40-black-and-white.png"
                                            }
                                            alt={"pdf icon"}
                                        />
                                    </IonButton>
                                </a>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"invoices"}
            />
        </>
    );
};

export default TabelInvoices;
