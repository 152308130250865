import React, { useContext } from "react";
import { IonContent, IonPage } from "@ionic/react";
import useFetchCaching from "../../../hooks/useFetchCaching";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import DetailPageUserInfo from "./DetailPageUserInfo";
import { DetailsPageUserData } from "../../../types/requestTypes";
import { useParams } from "react-router-dom";
import UserContext from "../../../context/UserContext";

import styles from "../../../components/SidebarLayout/SidebarLayout.module.scss";

const DetailsPageUser: React.FC = () => {
    const { userId } = useParams<{ userId: string }>();
    const { layoutCtx } = useContext(UserContext);
    const { data } = useFetchCaching<DetailsPageUserData>(
        `/admin/users/${userId}`
    );

    return (
        <IonPage>
            <IonContent fullscreen>
                <section className={styles.wrapper}>
                    <aside></aside>

                    <div
                        className={`${styles.card} ${
                            layoutCtx ? styles.mainRestraint : styles.main
                        } `}
                    >
                        <SkeletonList loading={!data} type={"simple"} />

                        {data && <DetailPageUserInfo data={data} />}
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
};

export default DetailsPageUser;
