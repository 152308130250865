import React from "react";
import { Link } from "react-router-dom";

export const PrivacyContentRO: React.FC = () => {
    return (
        <div className="help-page-text-wrapper">
            <p className="help-last-update">
                Ultima modificare: 5 aprilie 2018
            </p>

            <ol className="help-nested-list">
                <li>
                    <span className="help-strong">Dispoziții Generale</span>

                    <span className="help-block">
                        Politica de Confidențialitate oferă informații în
                        legătură cu utilizarea, stocarea și
                        transmiterea/transferulPolitica de Confidențialitate de
                        către Set Mobile S.R.L., o societate cu răspundere
                        limitată, constituită și funcționând în conformitate cu
                        legile din România, având sediul social în Bucuresti,
                        str. Rovine, nr.3, Sc.A, Ap.21, număr de înregistrare în
                        Registrul Comerțului J40/16520/2003, cod unic de
                        identificare RO15967256 (denumită în continuare{" "}
                        <span className="help-strong">"Aqmeter"</span>) a
                        datelor cu caracter personal obținute prin intermediul
                        aplicației având domeniul aqmeter.com și aplicația
                        online pentru telefoane mobile disponibilă pe Google
                        Store și Apple App Store. Prin utilizarea aplicației web
                        având domeniul aqmeter.com (
                        <span className="help-strong">"Platforma"</span>) și a
                        Aplicației disponibilă pe Google Store și Apple App
                        Store (<span className="help-strong">"Aplicația"</span>
                        ), a serviciilor disponibile pe Platformă/Aplicație sau
                        prin intermediul acesteia (
                        <span className="help-strong">"Serviciile"</span>), vă
                        exprimați acordul cu privire la regulile prevăzute în
                        prezenta Politică de Confidențialitate.
                    </span>
                    <span className="help-block">
                        Politica de Confidențialitate trebuie citită împreună cu
                        şi face parte integrantă din{" "}
                        <Link to="/terms">Termenii și Condițiile</Link> de
                        utilizare și{" "}
                        <Link to="/cookies">Politica de Cookies</Link> a
                        platformei. Toţi Termenii cu majusculă, care nu sunt
                        definiţi altfel în prezenta Politică de
                        Confidenţialitate, exceptând cazul în care contextul
                        impune un alt sens, vor avea înţelesurile stabilite în
                        Termenii și Condițiile de utilizare.
                    </span>
                </li>

                <li>
                    <span className="help-strong">Definiții. Scop</span>
                    <span className="help-block">
                        Următorii termeni folosiți cu majuscule vor avea
                        înţelesurile de mai jos, cu excepția cazului în care se
                        prevede expres altfel:
                    </span>

                    <span className="definitions">
                        <label>"Aplicația Aqmeter" sau "Aplicația" </label>
                        <span>
                            înseamnă aplicația software dezvoltată de către
                            Companie și comercializată de către aceasta prin
                            intermediul acestei denumiri, prin care Utilizatorii
                            pot efectua plăți online, accesibilă la site-ul web
                            descris în preambul și eventual pentru a fi
                            descărcată de către Utilizatori prin intermediul
                            Apple App Store și Google Play.
                        </span>
                    </span>
                    <span className="definitions">
                        <label>”Compania” sau ”Prestatorul” </label>
                        <span>
                            înseamnă Set Mobile S.R.L., o societate cu
                            răspundere limitată, constituită și funcționând în
                            conformitate cu legile din România, având sediul
                            social în Bucuresti, str. Rovine, nr.3, Sc.A, Ap.21,
                            număr de înregistrare în Registrul Comerțului
                            J40/16520/2003, cod unic de identificare RO15967256.
                        </span>
                    </span>
                    <span className="definitions">
                        <label>“Cont” </label>
                        <span>
                            înseamnă secțiunea din Platformă accesibilă
                            Utilizatorului prin utilizarea, cu rol de
                            identificare, a unei adrese de e-mail și a unei
                            parole, care permite Utilizatorului transmiterea
                            citirilor de contoare de apă și plata unei facturi
                            de servicii către Operator și care conține
                            informații despre Utilizator și istoricul
                            Utilizatorului în Platformă (e.g., Citiri
                            anterioare, Facturi fiscale etc.).
                        </span>
                    </span>
                    <span className="definitions">
                        <label>"Client" </label>
                        <span>
                            Înseamnă Utilizatorul/Furnizorul agreat care
                            beneficiează de serviciile Aplicației/Plaftormei
                            contracost.
                        </span>
                    </span>
                    <span className="definitions">
                        <label>
                            "Date Personale" sau "Date cu Caracter Personal"{" "}
                        </label>
                        <span>
                            înseamnă orice informații referitoare la o persoana
                            fizică identificata sau identificabilă; o persoană
                            identificabilă este acea persoană care poate fi
                            identificată, direct sau indirect, în mod particular
                            prin referire la un număr de identificare ori la
                            unul sau la mai mulți factori specifici identității
                            sale fizice, fiziologice, psihice, economice,
                            culturale sau sociale.
                        </span>
                    </span>
                    <span className="definitions">
                        <label>”Furnizor Agreat” </label>
                        <span>
                            înseamnă o persoană juridică inclusă în Aplicația
                            Aqmeter sau site-ul web aqmeter.com, ca urmare a
                            unui contract încheiat între unul sau mai mulți
                            dintre furnizorii de apă și Companie.
                        </span>
                    </span>
                    <span className="definitions">
                        <label>“Servicii” </label>
                        <span>
                            înseamnă orice servicii care sunt oferite
                            Utilizatorului în vederea achiziţionării, contra
                            cost sau gratuit prin intermediul
                            Platformei/Aplicației.
                        </span>
                    </span>
                    <span className="definitions">
                        <label>“Utilizator” </label>
                        <span>
                            înseamnă persoana fizică ce accesează Platforma, își
                            înregistrează un Cont și utilizează Aplicațiile și
                            Serviciile oferite de către Platformă .
                        </span>
                    </span>
                </li>

                <li>
                    <span className="help-strong">Date Personale</span>
                    <span className="help-block">
                        Ca urmare a consimțământului acordat în mod expres de
                        către fiecare Utilizator la momentul creării unui cont
                        în Aplicația Aqmeter/Platforma, următoarele Date cu
                        Caracter Personal ale Utilizatorului sunt prelucrate de
                        către Companie în vederea operării Aplicației
                        Aqmeter/Platformei:
                    </span>
                    <ol>
                        <li>
                            informații privind Utilizatorul, cum ar fi: nume,
                            prenume, adresă de e-mail, număr de telefon, nume de
                            utilizator si parola;
                        </li>
                        <li>
                            informații necesare pentru efectuarea operațiunilor
                            de plată de către Utilizator, cum ar fi: adresele
                            aferente locurilor de consum, informații despre
                            facturi, informații despre contoarele de apă,
                            informații despre indexul de apă, numere de telefon
                            încărcate electronic, date privind contul bancar al
                            Utilizatorului;
                            <span className="help-block">și</span>
                        </li>
                        <li>
                            informații despre modul de utilizare a Aplicației
                            Aqmeter/Platforma: timpul petrecut de către
                            Utilizator pe anumite instanțe ale Aplicației
                            Aqmeter/Platforma, frecvența de utilizare a
                            Aplicației Aqmtere/Platformei și a Serviciilor.
                        </li>
                    </ol>
                    <span className="help-block">
                        Utilizatorul își poate retrage consimțământul privind
                        prelucrarea Datelor sale cu Caracter Personal în orice
                        moment. În această situație, cel puțin o parte din sau
                        toate funcționalitățile Aplicației Aqmeter/Platformei nu
                        vor mai putea fi utilizate.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Colectarea și utilizarea datelor
                    </span>
                    <span className="help-block">
                        Puteți utiliza Platforma fără a vă dezvălui datele cu
                        caracter personal. Cu toate acestea, în cazul în care
                        doriți să beneficiați de Serviciile noastre, vi se va
                        solicita să furnizați Datele dumneavoastră în timpul
                        unui proces de înregistrare. Datele solicitate pot
                        include: numele complet, CNP, date din buletin, numărul
                        de telefon, adresa de e-mail, adresa de domiciliu si/sau
                        adresa de livrare şi informații privind cardul de debit
                        sau de credit, după caz, urmând a fi utilizate pentru
                        (denumite în continuare "
                        <span className="help-strong">
                            Scopuri de Prelucrare
                        </span>
                        "):
                    </span>
                    <ol>
                        <li>
                            înregistrarea unui Cont în secţiunea dedicată a
                            Platformei/Aplicației Aqmeter;
                        </li>
                        <li>
                            plasarea unei Comenzi prin intermediul Platformei,
                            în scopul achiziţionării de servicii ("
                            <span className="help-strong">Produsele</span>");
                        </li>{" "}
                        <li>procesarea Comenzilor;</li>
                        <li>
                            executarea unui Contract cu privire la vânzarea,
                            respectiv achiziţionarea, unuia sau mai multor
                            Servicii de pe Platformă, prin lansarea unei Comenzi
                            de către Utilizator şi acceptarea ei de către
                            Aqmeter, cu respectarea prevederilor legale şi a
                            termenilor şi condițiilor pentru vânzarea online a
                            serviciilor şi produselor Aqmeter, sau pentru a lua
                            măsuri, la solicitarea dumneavoastră, înainte de a
                            încheia un Contract;
                        </li>{" "}
                        <li>procesarea Tranzacţiilor;</li>
                        <li>
                            procesarea oricăror cereri pe care le transmiteţi
                            prin intermediul secţiunii de Contact a
                            Platformei/Aplicației Aqmeter;
                        </li>
                        <li>
                            pentru îmbunătățirea constantă a funcționalităților
                            Aplicației Aqmeter/Platformei și a Serviciilor
                            oferite Utilizatorilor;
                        </li>
                        <li>
                            crearea unui profil pentru fiecare Utilizator în
                            vederea îmbunătățirii experienței de utilizare a
                            Aplicației Aqmeter, transmiterii de oferte, mesaje
                            publicitare şi de marketing.
                        </li>
                    </ol>
                    <span className="help-block">
                        Orice date personale comunicate în cadrul procesului de
                        înregistrare vor fi procesate de către Aqmeter, în
                        calitate de operator de date cu caracter personal,
                        conform Legii pentru protecţia persoanelor cu privire la
                        prelucrarea datelor cu caracter personal şi libera
                        circulaţie a acestor date (Legea nr. 677/2001) sau a
                        oricărui act normativ care completează, modifică sau
                        înlocuieşte aceasta Lege.
                    </span>
                    <span className="help-block">
                        Datele dumneavoastră vor fi utilizate exclusiv în
                        Scopurile de Prelucrare pentru care au fost inițial
                        colectate. Datele pot fi prelucrate în vederea
                        îndeplinirii unui interes legitim diferit de Scopurile
                        de Prelucrare numai dacă un astfel de interes legitim
                        este strâns legat de Scopurile de Prelucrare. În cazul
                        intenției de a prelucra ulterior Datele în alt scop
                        decât Scopurile de Prelucrare stabilite prin această
                        Politică de Confidențialitate, vă vom furniza, anterior
                        aceastei prelucrări ulterioare, informații relevante și
                        detaliate privind prelucrarea avută în vedere.
                    </span>
                    <span className="help-block">
                        Sunteți obligat să furnizați Aqmeter date corecte și
                        complete. Nerespectarea acestei obligaţii constituie o
                        încălcare a Termenilor și Condițiilor și a Politicii de
                        Confidențialitate, care poate duce la încetarea imediată
                        de către Aqmeter a (a) Contului dumneavoastră pe
                        Platformă/Aplicație sau (b) furnizării oricăror Servicii
                        comandate. În plus, sunteți de acord în mod expres să vă
                        mențineți informațiile de înregistrare actualizate și să
                        vă asigurați că acestea sunt în permanență corecte și
                        complete.
                    </span>
                    <span className="help-block">
                        Politica noastră este ca aceste Date să nu fie
                        dezvăluite, transmise, vândute sau închiriate unor terțe
                        părți, altele decât cele prevăzute în mod expres în
                        această Politică de Confidențialitate.
                    </span>
                    <span className="help-block">
                        Dacă un Utilizator nu dorește să primească notificări și
                        informații de natură comercială prin intermediul
                        Aplicației Aqmeter/Platformei sau prin e-mail în
                        legătură cu Aplicația Aqmeter/Platforma, se poate
                        dezabona accesând link-ul de „Dezabonare" sau
                        „Unsubscribe", prezent în oricare mesaj transmis de
                        către Companie către Utilizator.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Înregistrarea Contului de Utilizator
                    </span>

                    <span className="help-block">
                        Plasarea unei Comenzi prin intermediul Platformei se
                        face prin înregistrarea unui Cont în secțiunea dedicată
                        din Platformă, Cont care va conține informații despre
                        Client și istoricul activitaţii Clientului pe Platformă
                        (e.g., Comenzi anterioare, facturi fiscale, etc.).
                        Înregistrarea Contului se realizează prin utilizarea, cu
                        rol de identificare, a unei adrese de e-mail și a unei
                        parole, numele complet, numărul de telefon, adresa de
                        domiciliu. În timp ce facem tot posibilul pentru a vă
                        proteja informațiile cu caracter personal, sunteți
                        singurul responsabil pentru păstrarea confidențialității
                        oricăror parole sau alte informații referitoare la Cont.
                    </span>
                    <span className="help-block">
                        Refuzul de a finaliza procesul de înregistrare prin
                        nefurnizarea tuturor Datelor solicitate, corecte şi
                        complete, va împiedica finalizarea procesului de
                        înregistrare a Contului şi accesarea istoricului
                        activităţii dvs. pe Platformă.
                    </span>
                    <span className="help-block">
                        Vă oferim și posibilitatea de a vă înregistra în
                        Platforma Aqmeter prin rețelele de socializare Facebook
                        sau Google. Dacă optați pentru una dintre aceste
                        variante, veți fi direcționați către o pagină
                        administrată de Facebook sau Google, unde aceștia vă vor
                        informa cu privire la transferul datelor dumneavăastră
                        către Aqmeter. Pentru mai multe detalii, puteți consulta
                        politicile de confidențialitate
                        <Link
                            to="https://www.facebook.com/privacy/policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            Facebook
                        </Link>
                        , respectiv
                        <Link
                            to="https://policies.google.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            Google
                        </Link>
                        .
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Furnizarea Produselor/Serviciilor şi Procesarea
                        Tranzacţiilor
                    </span>

                    <span className="help-block">
                        Plasarea unei Comenzi prin intermediul Platformei se
                        realizează prin utilizarea, cu rol de identificare, a
                        unei adrese de e-mail, numele complet, CNP, date de
                        identificare din buletin, numărul de telefon, adresa de
                        domiciliu si/sau adresa de livrare. În cazul în care
                        optaţi pentru plata Serviciilor online, Aqmeter poate
                        cere informații privind Cardul de Debit sau de Credit și
                        poate solicita să introduceți astfel de informații pe
                        Platforma exclusiv în scopul procesării Tranzacţiilor
                        (operaţiunea de plată efectuată de către Client,
                        respectiv încasare de către Aqmeter în calitate de
                        Prestator, a unei sume de bani ca urmare a vânzării de
                        către Aqmeter, respectiv achiziţionării de către Client,
                        a unuia sau mai multor Servicii).
                    </span>
                    <span className="help-block">
                        Refuzul de a finaliza procesul de înregistrare prin
                        nefurnizarea tuturor Datelor solicitate, corecte şi
                        complete, va împiedica finalizarea procesului de plasare
                        a Comenzii sau, dupa caz, procesarea Tranzacţiilor
                        (efectuarea plăţilor), conducând la încetarea de către
                        Aqmeter a furnizării oricăror Produse comandate.
                    </span>
                    <span className="help-block">
                        Raporturilor născute între Aqmeter şi fiecare Client
                        care utilizează Platforma în vederea, cu ocazia sau după
                        efectuarea unei Comenzi sunt supuse Termenilor şi
                        Condiţiilor de utilizare şi prezentei Politici de
                        Confidenţialitate.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Utilizarea Cookies și Statistici privind utilizarea prin
                        intermediul Google Analytics
                    </span>

                    <span className="help-block">
                        Pentru Platforma noastră utilizăm cookie-uri, care vă
                        oferă o experiență de navigare mai rapidă, în scopuri
                        statistice și de îmbunătățire a Platformei. Utilizarea
                        unui cookie nu este în niciun fel legată de informaţiile
                        de identificare personală în timpul unei vizite pe
                        Platformă.
                    </span>
                    <span className="help-block">
                        De asemenea, folosim Google Analytics pentru a analiza
                        informațiile colectate de la vizitatorii Platformei.
                        Aceste informații permit Aqmeter să înțeleagă mai bine
                        utilizatorii Platformei și modul în care este utilizată.
                        În acest tip de raportare nu sunt incluse informații
                        personale de identificare.
                    </span>
                    <span className="help-block">
                        Noi nu folosim cookie-uri pentru a colecta Date cu
                        caracter personal și nicio tehnologie care face legătura
                        între informațiile colectate prin cookie-uri și Datele
                        utilizatorilor Platformei.
                    </span>
                    <span className="help-block">
                        Pentru informații detaliate privind utilizarea
                        cookie-urilor și statisticile de utilizare prin
                        intermediul Google Analytics pe Platformă, precum și
                        modul în care puteți renunța la anumite tipuri de
                        procesare, dezactiva stocarea cookie-urilor sau elimina
                        cookie-urile înregistrate, vă rugăm să accesați{" "}
                        <Link to="/cookies">Politica de utilizare Cookies</Link>{" "}
                        .
                    </span>
                </li>

                <li>
                    <span className="help-strong">Link-uri</span>

                    <span className="help-block">
                        Din când în când, Platforma/Aplicația poate conține
                        link-uri către alte site-uri sau resurse de pe Internet.
                        Rețineți că aceste alte site-uri sau resurse nu sunt
                        controlate de Aqmeter și confirmaţi și acceptați că
                        Aqmeter nu este, în niciun caz, responsabil sau
                        răspunzător, direct sau indirect, pentru practicile sau
                        acțiunile privind confidențialitatea Datelor cu caracter
                        personal ale acestor site-uri sau ale operatorilor
                        acestora.
                    </span>
                    <span className="help-block">
                        Includerea oricărui astfel de link nu implică aprobarea
                        de către Aqmeter și nicio asociere cu operatorii săi. Vă
                        încurajăm să fiți atenți atunci când părăsiți Platforma
                        și să citiți declarațiile de confidențialitate ale
                        fiecărui site care colectează Date cu caracter personal.
                        Aceasta Politică de Confidențialitate se aplică numai
                        informațiilor colectate si procesate de Aqmeter pentru
                        Scopurile de Prelucrare menţionate.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Destinatari și Transferul de Date
                    </span>

                    <span className="help-block">
                        Aqmeter vă poate transfera Datele, prin dezvăluirea sau
                        acordarea unor drepturi de acces de la distanță, numai
                        prin intermediul aplicațiilor securizate, unor terțe
                        părți, cum ar fi entități afiliate și alți parteneri
                        comerciali ai Aqmeter, care acționează în calitate de
                        împuterniciţi, procesând date cu caracter personal
                        pentru și în numele Aqmeter (de exemplu, stocare a
                        Datelor pe servere tip "cloud", consultanți juridici și
                        financiari, furnizori de servicii tehnice sau furnizori
                        de servicii de asistență la expediere), cu care Aqmeter
                        a încheiat acordurile contractuale necesare în
                        conformitate cu reglementările UE și naționale.
                    </span>
                    <span className="help-block">
                        Vom transfera Date către terțe părți exclusiv în măsura
                        necesară îndeplinirii Scopurilor de Prelucrare
                        aplicabile pentru care datele dumneavoastră sunt
                        colectate și procesate.
                    </span>
                    <span className="help-block">
                        Datele cu caracter personal colectate și prelucrate în
                        Scopurile de Prelucrare descrise în această Politică de
                        Confidențialitate pot fi stocate pe servere situate în
                        strainătate sau transferate către entități afiliate
                        având sediul în afara teritoriului Uniunii Europene. În
                        situaţia transferului de Date către state terţe, Aqmeter
                        vă va comunica intenţia de transfer precum şi statele
                        terţe vizate, scopul transferului şi solicitarea
                        consimţamantului, atunci cand un astfel de consimţământ
                        este necesar potrivit dispoziţiilor legale în vigoare.
                    </span>
                    <span className="help-block">
                        Aqmeter poate dezvălui Datele pentru a respecta
                        prevederile legale sau ca răspuns la o cerere din partea
                        unei instanțe sau a unei alte autorități publice.
                        Aqmeter va putea, de asemenea, să divulge informațiile,
                        dacă consideră, cu bună-credință, că este necesar pentru
                        a preveni sau împiedica savârşirea unor fraude sau
                        limitarea consecinţelor acestora, pentru a proteja
                        Aqmeter și angajații săi sau pentru a respecta Termenii
                        și Condițiile de utilizare.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Durata de stocare a Datelor
                    </span>

                    <span className="help-block">
                        Datele cu caracter personal colectate și utilizate
                        pentru prestarea Serviciilor de către Aqmeter vor fi
                        stocate pentru o perioadă de 5 ani de la încetarea
                        relațiilor contractuale sau orice altă perioadă mai
                        lungă impusă de lege, regulamente sau norme aplicabile
                        privind obligaţii de păstrare a documentelor contabile
                        sau solicitări ale autorităţilor publice.
                    </span>
                    <span className="help-block">
                        Imediat după încheierea perioadei aplicabile de stocare,
                        datele vor fi:
                    </span>
                    <ol>
                        <li>
                            şterse sau distruse;
                            <span className="help-block">sau</span>
                        </li>
                        <li>
                            anonimizate; <span className="help-block">sau</span>
                        </li>
                        <li>
                            transferate într-o arhivă (cu excepția cazului în
                            care acest lucru este interzis prin lege sau
                            regulament aplicabil prvind păstrare a
                            înregistrărilor).
                        </li>
                    </ol>
                    <span className="help-block">
                        Datele cu caracter personal colectate și utilizate
                        pentru crearea Contului dumneavoastră vor fi șterse
                        imediat, in cazul în care vă închideți contul.
                    </span>
                    <span className="help-block">
                        Pentru a vă asigura că Datele nu sunt păstrate mai mult
                        decât este necesar, Aqmeter va revizui periodic Datele
                        și, dacă este cazul, le va șterge.
                    </span>
                </li>

                <li>
                    <span className="help-strong">Securitate</span>

                    <span className="help-block">
                        Ca parte a administrării Platformei, am luat măsuri
                        tehnice și organizatorice pentru a asigura un nivel de
                        securitate corespunzător riscurilor pe care le comportă
                        prelucrarea Datelor, în special prin utilizarea
                        necorespunzătoare, distrugerea accidentală, accesarea
                        ilegală sau neautorizată, pierderea, alterarea,
                        divulgarea, manipularea intenționată sau accidentală,
                        accesul terților, ștergerea și modificarea. În acest
                        scop, am dezvoltat și implementat politici de securitate
                        a datelor și alte practici de confidențialitate. În
                        plus, procedurile noastre de securitate sunt revizuite
                        în permanență pe baza noilor evoluții tehnologice.
                    </span>
                    <span className="help-block">
                        Pentru informații suplimentare cu privire la practicile
                        noastre de securitate, vă rugăm să completați formularul
                        de contact din sectiunea Contact a Platformei.
                    </span>
                    <span className="help-block">
                        Veți fi notificat cu privire la o încălcare a
                        securității datelor, într-o perioadă rezonabilă de timp
                        după descoperirea unei astfel de încălcări, cu excepția
                        cazului în care un organism public abilitat determină că
                        notificarea ar împiedica o cercetare penală sau ar aduce
                        prejudicii securității naționale. În acest caz,
                        notificarea va fi amânată, conform instrucțiunilor unui
                        astfel de organism. Vom răspunde cu promptitudine la
                        întrebările dumneavoastră referitoare la o astfel de
                        încălcare a securității datelor.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Drepturile Utilizatorului
                    </span>

                    <span className="help-block">
                        Aveți dreptul de a accesa și de a rectifica Datele
                        dumneavoastră, precum și dreptul de a transfera și de a
                        solicita ștergerea acestora (în cazul în care
                        prelucrarea este efectuată pe bază de consimţământ și nu
                        există niciun alt temei juridic pentru prelucrarea
                        datelor, în condiţiile prevăzute de reglementările
                        legale aplicabile).
                    </span>
                    <span className="help-block">
                        Vom comunica orice rectificare sau ștergere a Datelor cu
                        caracter personal sau restricționare a prelucrării
                        efectuate în conformitate cu legea aplicabilă, fiecărui
                        destinatar căruia i-au fost dezvăluite Datele, cu
                        excepția cazului în care acest lucru se dovedește
                        imposibil sau presupune eforturi disproporționate.
                    </span>
                    <span className="help-block">
                        În cazul în care Aqmeter a făcut publice Datele cu
                        caracter personal, vom lua măsuri rezonabile, luând în
                        considerare tehnologia disponibilă și costul de
                        implementare, pentru a informa operatorii care
                        prelucrează Datele cu caracter personal cu privire la
                        solicitarea dumneavoastră de ștergere a oricăror
                        link-uri sau de copiere sau replicare a Datelor
                        respective. În orice situaţie, veți primi o notificare
                        prealabilă și o cerere de consimțământ (cu excepția
                        cazului în care o astfel de prelucrare este necesară
                        pentru îndeplinirea unei obligații legale) cu privire la
                        intenția Aqmeter de a face publice oricare dintre Datele
                        dumneavoastră cu caracter personal.
                    </span>
                    <span className="help-block">
                        Ne puteți contacta în cazul în care considerați că
                        Datele dumneavoastră cu caracter personal au fost
                        furnizate fără consimțământ, trimițând o solicitare
                        scrisă și datată însoțită de o dovadă a identității
                        dumneavoastră către Aqmeter la datele de contact din
                        secţiunea{" "}
                        <span className="help-strong">Date de Contact</span>.
                    </span>
                    <span className="help-block">
                        În afară de cele de mai sus, aveți dreptul de a nu fi
                        supus unei decizii bazate exclusiv pe prelucrarea
                        automatizată a datelor cu caracter personal, inclusiv
                        colectarea de informații, care produc efecte juridice
                        sau vă afectează semnificativ în mod similar, cu
                        excepția cazului în care este necesar pentru încheierea
                        sau executarea unui Contract între dumneavoastră și
                        Aqmeter.
                    </span>
                </li>

                <li>
                    <span className="help-strong">
                        Modificarea Politicii de Confidenţialitate
                    </span>

                    <span className="help-block">
                        Aqmeter îşi rezervă dreptul de a modifica Politica de
                        Confidențialitate în orice moment, în mod discreţionar.
                        Orice modificare va fi publicată pe Platformă, astfel
                        încât să aveți întotdeauna acces la informațiile pe care
                        le colectăm, modul în care le folosim și în ce
                        circumstanțe le dezvăluim, dacă este cazul. Orice astfel
                        de modificare, semnificativă sau de altă natură, va
                        intra în vigoare cu efect imediat la data publicării pe
                        Platformă.
                    </span>
                    <span className="help-block">
                        Dacă în orice moment decidem să folosim Date cu caracter
                        personal într-un alt scop decât cel prevăzut la momentul
                        colectării Datelor, vă vom informa în prealabil, prin
                        e-mail. Veți avea posibilitatea de a alege dacă putem
                        utiliza datele dumneavoastră pentru noile scopuri avute
                        în vedere. În caz contrar, dacă este posibil, vom
                        utiliza datele în conformitate cu Politica de
                        Confidențialitate în vigoare la data colectării Datelor.
                    </span>
                </li>

                <li>
                    <span className="help-strong">Date de Contact</span>

                    <span className="help-block">
                        În cazul în care aveți întrebări suplimentare cu privire
                        la modul în care prelucrăm informațiile cu caracter
                        personal sau dacă doriți să vă exercitați oricare dintre
                        drepturi în conformitate cu această Politică de
                        Confidenţialitate, vă rugăm să ne trimiteți o cerere
                        scrisă și datată, însoțită de o dovadă a identității
                        dumneavoastră, utilizând următoarele date de contact sau
                        formularul de contact din secţiunea Contact existentă pe
                        Platformă:{" "}
                        <Link to="mailto:contact@aqmeter.ro">
                            contact@aqmeter.ro
                        </Link>{" "}
                        .
                    </span>
                    <span className="help-block">
                        Cererile de acces, de rectificare, de restricționare a
                        prelucrării Datelor sau de ștergere, pot fi făcute doar
                        în scris și sunt supuse restricțiilor legale aplicabile.
                    </span>
                    <span className="help-block">
                        Ne vom asigura că se iau măsurile adecvate pentru a
                        răspunde solicitării dumneavoastră fără întârzieri
                        nejustificate și, în orice caz, în termen de o lună de
                        la primirea cererii. Informațiile sunt furnizate pe cale
                        electronică, dacă este posibil, cu excepția cazului în
                        care ne solicitați altfel, în mod expres, în scris.
                    </span>
                </li>
            </ol>
        </div>
    );
};
