import { IonIcon, IonItem, IonLabel, IonList, IonPage } from "@ionic/react";
import {
    chevronForward,
    helpCircle,
    personCircle,
    settings,
    sync,
} from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toastAdd } from "../../components/CustomToast/CustomToasts";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import AuthContext from "../../context/AuthContext";
import useLogout from "../../hooks/useLogout";
import { LogoutData } from "../../types/requestTypes";
import { t } from "i18next";

const MorePage: React.FC = () => {
    const logout = useLogout();
    const history = useHistory();
    const { nameCtx, mailCtx } = useContext(AuthContext);

    const [fcmToken, setFcmToken] = useState<string>("");

    useEffect(() => {
        const storedFcm = localStorage.getItem("fcm");
        if (storedFcm) {
            setFcmToken(storedFcm);
        }
    }, []);

    const onClickLogout = async () => {
        const data: LogoutData = {
            userEmail: mailCtx,
            fcmToken: fcmToken,
        };
        try {
            await logout(data);
            history.replace("/login");
        } catch (err: any) {
            toastAdd("Delogare nereusita", "error");
        }
    };

    return (
        <IonPage className={`layout--desktop`}>
            <SidebarLayout
                className="others-page"
                layoutType="back"
                url={"/dashboard"}
            >
                <IonList className="others-menu" lines="none">
                    <IonItem
                        key="profile"
                        className="cursor-hover"
                        routerLink="/profile"
                        detail={false}
                    >
                        <div className="others-icon-bg">
                            <IonIcon icon={personCircle} />
                        </div>
                        <IonLabel>
                            <span className="ag-cta black">
                                {nameCtx
                                    ? nameCtx
                                    : t("settings.label.profile")}
                            </span>
                            <br />
                            <span className="ag-number-description black">
                                {mailCtx ? mailCtx : ""}
                            </span>
                        </IonLabel>
                        <IonIcon className="chevron" icon={chevronForward} />
                    </IonItem>

                    <IonItem
                        key="change-password"
                        className="cursor-hover"
                        routerLink="/change-password"
                        detail={false}
                    >
                        <div className="others-icon-bg">
                            <IonIcon icon={sync} />
                        </div>
                        <IonLabel className="ag-cta black">
                            {t("settings.label.changePassword")}
                        </IonLabel>
                        <IonIcon className="chevron" icon={chevronForward} />
                    </IonItem>

                    <IonItem
                        key="settings"
                        className="cursor-hover"
                        routerLink="/settings"
                        detail={false}
                    >
                        <div className="others-icon-bg">
                            <IonIcon icon={settings} />
                        </div>
                        <IonLabel className="ag-cta black">
                            {t("settings.label.setting")}
                        </IonLabel>
                        <IonIcon className="chevron" icon={chevronForward} />
                    </IonItem>

                    <IonItem
                        key="help"
                        className="next-is-divider"
                        routerLink="/faq"
                        detail={false}
                    >
                        <div className="others-icon-bg">
                            <IonIcon icon={helpCircle} />
                        </div>
                        <IonLabel className="ag-cta black">
                            {t("settings.label.help")}
                        </IonLabel>
                        <IonIcon className="chevron" icon={chevronForward} />
                    </IonItem>
                </IonList>
            </SidebarLayout>
        </IonPage>
    );
};

export default MorePage;
