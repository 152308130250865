import { IonButton, IonSpinner } from "@ionic/react";
import React, { useState } from "react";
import { userActions } from "../api/UserActions";
import FormFeedback from "../components/UI/FormFeedback";
import InfoBox from "../components/UI/InfoBox";
import { ActionStatus, UnconfirmedResponse } from "../types/otherTypes";
import styles from "./ResendConfirmationNotice.module.scss";

const ResendConfirmationNotice: React.FC<UnconfirmedResponse> = ({
    type,
    resendUrl,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [actionStatus, setActionStatus] = useState<ActionStatus>();

    const resendConfirmationHandler = async (url) => {
        setIsLoading(true);
        await userActions
            .resendEmailConfirmation(url)
            .then((response) => {
                setIsLoading(false);
                if (response) {
                    setActionStatus({
                        type: "success",
                        message:
                            "Retrimiterea emailului de confirmare a fost efectuată cu succes!",
                    });
                } else {
                    setActionStatus({
                        type: "error",
                        message:
                            "Retrimiterea emailului de confirmare a eșuat!",
                    });
                }
            })
            .catch(() => {
                setIsLoading(false);
                setActionStatus({
                    type: "error",
                    message: "Retrimiterea emailului de confirmare a eșuat!",
                });
            });
    };

    return (
        <section
            className={styles.wrapper}
            data-testid={"resend-confirmation-notice"}
        >
            <InfoBox color={"tertiary"} noMargin={true}>
                {type === "account" ? (
                    <>
                        {"Utilizator neconfirmat"} {". "}{" "}
                        {
                            "Confirmă-ți contul prin accesarea link-ului primit pe email."
                        }
                    </>
                ) : (
                    <>
                        {"Parolă neconfirmată"} {". "}{" "}
                        {
                            "Confirmă-ți parola prin accesarea link-ului primit pe email."
                        }
                    </>
                )}
            </InfoBox>

            <IonButton
                expand="block"
                color="tertiary"
                disabled={isLoading}
                onClick={() => resendConfirmationHandler(resendUrl)}
            >
                {isLoading ? <IonSpinner name="circles" /> : ""}
                {"Retrimite email confirmare"}
            </IonButton>

            {actionStatus?.type === "success" ? (
                <FormFeedback type={"success"} noMargin={true}>
                    {actionStatus?.message}
                </FormFeedback>
            ) : actionStatus?.type === "error" ? (
                <FormFeedback type={"error"} noMargin={true}>
                    {actionStatus?.message}
                </FormFeedback>
            ) : (
                ""
            )}
        </section>
    );
};

export default ResendConfirmationNotice;
