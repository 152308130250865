import { useTranslation } from "react-i18next";

import styles from "./Dashboard.module.scss";

const NoGraph = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <p className={styles.title}>
                    {t("dashboard.graph.title.invoices")}
                </p>
            </div>
            <p className={styles.noData}>{t("noDataAvailable")}</p>
        </div>
    );
};

export default NoGraph;
