import useFetchCaching from "../../../hooks/useFetchCaching";
import { useParams } from "react-router-dom";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import React from "react";
import TableInvoiceDetailPage from "./TableInvoiceDetailPage";
import { InvoiceDetailAdmin } from "../../../types/responseTypes";

const DetailsInvoicePage: React.FC = () => {
    const { invoiceId } = useParams<{ invoiceId: string }>();
    const { data: invoiceDetails } = useFetchCaching<InvoiceDetailAdmin>(
        `admin/invoices/${invoiceId}`
    );

    return (
        <>
            <IonPage>
                <SidebarLayout className="others-page" layoutType={"submenu"}>
                    <SkeletonList loading={!invoiceDetails} type={"simple"} />
                    {invoiceDetails && (
                        <TableInvoiceDetailPage
                            invoiceDetails={invoiceDetails}
                        />
                    )}
                </SidebarLayout>
            </IonPage>
        </>
    );
};

export default DetailsInvoicePage;
