import React, { useMemo } from "react";
import { ContractContent, ContractsRequest } from "../../../types/requestTypes";
import moment from "moment/moment";
import { useHistory } from "react-router";
import TablePagination from "../../../components/Pagination/TablePagination";
import { useTranslation } from "react-i18next";
import TableHeader from "../../../components/UI/TableHeader";

import styles from "../ContractsPage.module.scss";
import SortingIcons from "../../../components/UI/SortingIcons";

interface TabelProps {
    contracts: ContractsRequest;
    query: URLSearchParams;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
}

const TabelContracts: React.FC<TabelProps> = ({
    contracts,
    query,
    pageNumber,
    setPageNumber,
}) => {
    const history = useHistory<History>();
    const { t } = useTranslation();

    const data = useMemo<ContractContent[]>(
        () => contracts.content,
        [contracts]
    );
    const totalPages = useMemo<number>(() => contracts.totalPages, [contracts]);
    const totalElements = useMemo<number>(
        () => contracts.totalElements,
        [contracts]
    );

    return (
        <>
            <table className={styles.table} style={{ width: "100%" }}>
                <TableHeader
                    query={query}
                    totalElements={totalElements}
                    titleHeader={t("contracts.table.title")}
                    urlStringPart={"contracts"}
                    subtitleHeaderWithNumberOfElements={t(
                        "totalSubtitle.contracts",
                        { contractsCount: totalElements }
                    )}
                />

                <thead className={styles.headerWrapper}>
                    <tr>
                        <SortingIcons
                            columnLabel={t("contracts.table.header.number")}
                            query={query}
                            urlStringPart={"contracts"}
                            sortIndex={0}
                            deafultSort={false}
                            width={"180px"}
                        />
                        <SortingIcons
                            columnLabel={t("contracts.table.header.name")}
                            query={query}
                            urlStringPart={"contracts"}
                            sortIndex={1}
                            deafultSort={false}
                            width={"190px"}
                        />
                        <th colSpan={2}>
                            {t("contracts.table.header.address")}
                        </th>
                        <SortingIcons
                            columnLabel={t("contracts.table.header.startDate")}
                            query={query}
                            urlStringPart={"contracts"}
                            sortIndex={3}
                            deafultSort={true}
                            width={"154px"}
                        />
                        <SortingIcons
                            columnLabel={t("contracts.table.header.endDate")}
                            query={query}
                            urlStringPart={"contracts"}
                            sortIndex={4}
                            deafultSort={false}
                            width={"154px"}
                        />
                    </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                    {data.map((contract) => {
                        return (
                            <tr
                                key={contract.id}
                                onClick={() => {
                                    history.push(`/contract/${contract.id}`);
                                }}
                            >
                                <td>{contract.number}</td>
                                <td
                                    style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                    }}
                                >
                                    {contract.name}
                                </td>
                                <td colSpan={2} data-testid="address">
                                    {contract.address.county},{" "}
                                    {contract.address.city},{" "}
                                    {contract.address.street}
                                    {contract.address.number
                                        ? t("contracts.address.number") +
                                          contract.address.number
                                        : null}
                                    {contract.address.building
                                        ? t("contracts.address.block") +
                                          contract.address.building
                                        : null}
                                    {contract.address.entrance
                                        ? t("contracts.address.sc") +
                                          contract.address.entrance
                                        : null}
                                    {contract.address.floor
                                        ? t("contracts.address.et") +
                                          contract.address.floor
                                        : null}
                                    {contract.address.ap
                                        ? t("contracts.address.apartment") +
                                          contract.address.ap
                                        : null}
                                </td>
                                <td>
                                    {contract.startDate
                                        ? moment(contract.startDate).format("L")
                                        : t("noData")}
                                </td>
                                <td>
                                    {contract.endDate
                                        ? moment(contract.endDate).format("L")
                                        : t("noData")}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"contracts"}
            />
        </>
    );
};

export default TabelContracts;
