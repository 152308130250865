import axiosBasic from "../api/customAxios";
import { LoginRequest } from "../types/requestTypes";
import { LoginResponse } from "../types/responseTypes";

const useLogin = () => {
    return async (data: LoginRequest): Promise<LoginResponse> => {
        let response: LoginResponse;

        const webResponse = await axiosBasic.post("/auth/login", data, {
            withCredentials: true,
        });
        response = webResponse?.data;
        return response;
    };
};

export default useLogin;
