import { IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";

interface InformationDetailInputProps {
    labelInput?: string;
    disabled?: boolean;
    allOptions?: string[];
    setDataCounties?: () => {};
    defaultData?: string[];
    onUpdate?: (selection: string[]) => void;
    selectedOptions?: string[];
    placeholder?: string;
}

const InformationDetailInput: React.FC<InformationDetailInputProps> = ({
    labelInput,
    disabled,
    selectedOptions,
    placeholder,
}) => {
    return (
        <IonList style={{padding: "0"}}>
            <IonLabel>{labelInput}</IonLabel>
            <IonItem
                className={"inputItemClassDots"}
                disabled={disabled}
                button={true}
                detail={false}
                // id={`select-${labelInput}`}
                lines={"none"}
                style={{
                    border: "1px solid #DEDEDE",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                }}
            >
                <IonLabel
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <span
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "346.1px",
                        }}
                    >
                        {selectedOptions?.length
                            ? selectedOptions.join(", ")
                            : placeholder}
                    </span>{" "}
                </IonLabel>
            </IonItem>
        </IonList>
    );
};

export default InformationDetailInput;
