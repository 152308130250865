import { IonInput, IonLabel } from "@ionic/react";
import React from "react";
import { TextFieldTypes } from "@ionic/core/dist/types/interface";
import { t } from "i18next";

interface StandardInputProps {
    labelName?: string;
    inputValue?: string;
    inputType?: TextFieldTypes;
    errorMsg?: string;
    setInputValue?: (event: any) => void;
    notRequired?: boolean;
    className?: string;
    disabled?: boolean;
}

const StandardInput: React.FC<StandardInputProps> = ({
    inputValue,
    setInputValue,
    labelName,
    inputType,
    className,
    disabled,
}) => {
    return (
        <div>
            {labelName && (
                <IonLabel
                    position={"stacked"}
                    style={{
                        fontSize: "14px",
                    }}
                >
                    {labelName} {/*{!notRequired ? (*/}
                    {/*    <IonIcon color={"danger"} icon={alertCircleOutline}/>*/}
                    {/*) : null}*/}
                </IonLabel>
            )}
            <IonInput
                onIonChange={setInputValue}
                className={`${
                    inputType === "datetime-local" ? "enabledInput" : ""
                } ${className ? className : null}` }
                value={inputValue}
                inputmode="text"
                placeholder={
                    t("news.create.label.placeholderTitle")
                    // : t("placeholder.addHere")
                }

                disabled={disabled}
                type={inputType}
                style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            />
        </div>
    );
};

export default StandardInput;
