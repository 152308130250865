import { useParams } from "react-router-dom";
import {
    IonButton,
    IonLabel,
    IonCardContent,
    IonPage,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonHeader,
    IonContent,
    IonText,
    IonToolbar,
    IonTitle,
} from "@ionic/react";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import React, { useContext, useEffect, useRef, useState } from "react";
import EdtiorApiTiny from "../../../components/UI/EdtiorApiTiny";
import SendingChannels from "./DetailsComponents/SendingChannels";
import { NewsAdminDetail } from "../../../types/responseTypes";
import HttpService from "../../../services/HttpService";
import AuthContext from "../../../context/AuthContext";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import { t } from "i18next";
import TitleInput from "./TitleInput";
import { PatchOperation } from "../../../types/otherTypes";
import StandardInput from "../../../components/UI/StandardInput";
import InformationDetailInput from "../../../components/InformationDetailInput";
import { useHistory } from "react-router";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import styles from "../News.module.scss";

const DetailsNewsPage: React.FC = () => {
    const { newsId } = useParams<{ newsId: string }>();
    const axiosul = useAxiosPrivate();
    const [newsDetails, setNewsDetails] = useState<NewsAdminDetail>(null);

    const { accessTokenCtx } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        axiosul.get(`admin/news/${newsId}`).then((dataReceived) => {
            setNewsDetails(dataReceived.data);
            setTitleValue(dataReceived.data.title);
        });
    }, []);

    const modal = useRef<HTMLIonModalElement>(null);
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [inputErrorClass, setInputErrorClass] = useState({
        title: "",
        editor: "",
    });
    const [titleValue, setTitleValue] = useState(null);

    const [, setInputError] = useState(false);

    useEffect(() => {
        if (titleValue !== "") {
            setInputErrorClass((prev) => {
                return {
                    ...prev,
                    title: "",
                };
            });
        }
    }, [titleValue]);

    function getFormErrors() {
        if (!titleValue)
            setInputErrorClass((prev) => {
                return {
                    ...prev,
                    title: "title",
                };
            });
        if (!editorRef.current.getContent())
            setInputErrorClass((prev) => {
                return {
                    ...prev,
                    editor: "editor",
                };
            });
        if (!(titleValue && editorRef.current.getContent())) {
            setInputError(true);
        }
    }

    const patchBodyHandler = async () => {
        setLoading(true);
        try {
            // result = []
            // dacă titlu diferă de original => result.push (obiect Patch Title)
            // dacă body diferă de original => result.push (obiect Patch Body)
            getFormErrors();
            if (!(titleValue && editorRef.current.getContent())) {
                setInputError(true);
                throw new Error(t("err.UNFILLED_INPUT_CREATE_NEWS"));
            }

            const result: PatchOperation[] = [];
            if (newsDetails.title !== titleValue) {
                result.push({
                    op: "replace",
                    path: "/title",
                    value: titleValue,
                });
            }
            if (newsDetails.htmlBody !== editorRef.current.getContent()) {
                result.push({
                    op: "replace",
                    path: "/body",
                    value: editorRef.current.getContent(),
                });
            }
            if (newsDetails.widgetActive !== theWidget) {
                result.push({
                    op: "replace",
                    path: "/active",
                    value: `${theWidget}`,
                });
            }
            if (result.length === 0) return;
            const { request } = await HttpService.patchBodyElement(
                result,
                accessTokenCtx,
                newsId
            );
            await request;
            toastAdd(t("news.details.bodyPatchToast.success"), "success");
            history.push("/news?page=1");
        } catch (err) {
            toastAdd(err.message, "error");
        } finally {
            setLoading(false);
        }
    };

    let theWidget = newsDetails?.widgetActive;

    function onUpdateWidget(widget: boolean) {
        theWidget = widget;
    }

    function deleteNews() {
        return axiosul.delete(`admin/news/${newsId}`);
    }

    return (
        <>
            <IonPage>
                <SidebarLayout className="others-page" layoutType={"submenu"}>
                    <SkeletonList loading={!newsDetails} type={"simple"} />
                    {newsDetails && (
                        <>
                            <IonLabel
                                className={styles.title}
                                style={{
                                    marginBottom: "30px",
                                }}
                            >
                                {t("news.details.title")}
                            </IonLabel>

                            <IonGrid fixed={true} style={{ width: "950px" }}>
                                <IonRow>
                                    <IonCol size="12" size-sm="6">
                                        <TitleInput
                                            titleValue={titleValue}
                                            setTitleValue={setTitleValue}
                                            className={
                                                inputErrorClass.title ===
                                                "title"
                                                    ? "input-error"
                                                    : ""
                                            }
                                        />
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <StandardInput
                                            labelName={t(
                                                "news.create.label.date"
                                            )}
                                            inputValue={newsDetails?.date}
                                            inputType={"datetime-local"}
                                            disabled={true}
                                        />
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <InformationDetailInput
                                            disabled={true}
                                            labelInput={t(
                                                "news.create.label.county"
                                            )}
                                            selectedOptions={
                                                newsDetails?.counties
                                            }
                                            placeholder={t(
                                                "news.create.label.placeholderCounty"
                                            )}
                                        />
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <InformationDetailInput
                                            disabled={true}
                                            labelInput={t(
                                                "news.create.label.city"
                                            )}
                                            selectedOptions={
                                                newsDetails?.cities
                                            }
                                            placeholder={t(
                                                "news.create.label.placeholderCity"
                                            )}
                                        />
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <InformationDetailInput
                                            disabled={true}
                                            labelInput={t(
                                                "news.create.label.street"
                                            )}
                                            selectedOptions={
                                                newsDetails.streets
                                            }
                                            placeholder={t(
                                                "news.create.label.placeholderStreet"
                                            )}
                                        />
                                    </IonCol>
                                    <IonCol size="12" size-sm="6">
                                        <InformationDetailInput
                                            disabled={true}
                                            labelInput={t(
                                                "news.create.label.number"
                                            )}
                                            selectedOptions={
                                                newsDetails?.numbers
                                            }
                                            placeholder={t(
                                                "news.create.label.placeholderNumber"
                                            )}
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                            <div>
                                <IonCardContent style={{ color: "black" }}>
                                    <p style={{ fontWeight: "500" }}>
                                        {t("news.details.sendingChannelsLabel")}
                                    </p>
                                    <SendingChannels
                                        whereTheComponentIs={"DetailsNewsPage"}
                                        channels={{
                                            emailSelected:
                                                newsDetails?.emailSelected,
                                            pushSelected:
                                                newsDetails?.pushSelected,
                                            smsSelected:
                                                newsDetails?.smsSelected,
                                        }}
                                        activeWidgetDetail={
                                            newsDetails?.widgetActive
                                        }
                                        newsId={newsId}
                                        onUpdateWidget={onUpdateWidget}
                                    />
                                    <EdtiorApiTiny
                                        className={`${styles.Editor} ${
                                            inputErrorClass.editor === "editor"
                                                ? "input-error"
                                                : ""
                                        }`}
                                        editorRef={editorRef}
                                        initialValue={newsDetails?.htmlBody}
                                        isDetailPage={true}
                                        setInputErrorClass={setInputErrorClass}
                                    />
                                    <div
                                        className={styles.buttonsDetailsWrapper}
                                    >
                                        <IonButton
                                            onClick={() => {
                                                patchBodyHandler();
                                            }}
                                            style={{
                                                width: "80%",
                                                margin: "0 auto",
                                            }}
                                        >
                                            {loading ? (
                                                <>
                                                    <IonSpinner name="circles" />{" "}
                                                    {t(
                                                        "news.details.inProcessSavingButtonLabel"
                                                    )}
                                                </>
                                            ) : (
                                                t(
                                                    "news.details.savingButtonLabel"
                                                )
                                            )}
                                        </IonButton>
                                        <IonButton
                                            style={{
                                                width: "80%",
                                                margin: "0 auto",
                                            }}
                                            color={"danger"}
                                            id={`delete-news`}
                                        >
                                            {loading ? (
                                                <>
                                                    <IonSpinner name="circles" />{" "}
                                                    {t(
                                                        "news.details.inProcessDeletingButtonLabel"
                                                    )}
                                                </>
                                            ) : (
                                                t(
                                                    "news.details.deletingButtonLabel"
                                                )
                                            )}
                                        </IonButton>
                                        <IonModal
                                            trigger={`delete-news`}
                                            ref={modal}
                                            backdropDismiss={false}
                                        >
                                            <IonHeader>
                                                <IonToolbar>
                                                    <IonTitle
                                                        style={{
                                                            textAlign: "center",
                                                            fontSize: "18px",
                                                        }}
                                                    >
                                                        {t(
                                                            "news.details.delete.modal.title"
                                                        )}
                                                    </IonTitle>
                                                </IonToolbar>
                                            </IonHeader>

                                            <IonContent>
                                                <IonText className="confirm-deletion">
                                                    {t(
                                                        "news.details.delete.modal.content"
                                                    )}
                                                </IonText>

                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonButton
                                                                color="tertiary"
                                                                expand="block"
                                                                onClick={async () => {
                                                                    let response =
                                                                        await deleteNews();
                                                                    console.log(
                                                                        response
                                                                    );
                                                                    if (
                                                                        response.status ===
                                                                        200
                                                                    ) {
                                                                        toastAdd(
                                                                            t(
                                                                                "news.details.delete.modal.success"
                                                                            ),
                                                                            "success"
                                                                        );
                                                                        history.push(
                                                                            "/news?page=1"
                                                                        );
                                                                        modal.current?.dismiss();
                                                                    }
                                                                    if (
                                                                        response.status ===
                                                                            400 ||
                                                                        response.status ===
                                                                            401 ||
                                                                        response.status ===
                                                                            500
                                                                    ) {
                                                                        toastAdd(
                                                                            t(
                                                                                "news.details.delete.modal.error"
                                                                            ),
                                                                            "error"
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                {t(
                                                                    "news.details.delete.modal.buttons.confirm"
                                                                )}
                                                            </IonButton>
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonButton
                                                                color="danger"
                                                                expand="block"
                                                                onClick={() => {
                                                                    modal.current?.dismiss();
                                                                }}
                                                            >
                                                                {t(
                                                                    "news.details.delete.modal.buttons.cancel"
                                                                )}
                                                            </IonButton>
                                                        </IonCol>
                                                    </IonRow>
                                                </IonGrid>
                                            </IonContent>
                                        </IonModal>
                                    </div>
                                </IonCardContent>
                            </div>
                        </>
                    )}
                </SidebarLayout>
            </IonPage>
        </>
    );
};

export default DetailsNewsPage;
