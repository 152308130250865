import {
    IonCheckbox,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonToggle,
} from "@ionic/react";
import React, { useState } from "react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import styles from "../../News.module.scss";
import { t } from "i18next";

interface SendingChannelsProps {
    whereTheComponentIs: string;
    data?: any;
    setData?: (data: any) => void;
    webWidget?: boolean;
    setWebWidget?: (webWidget: any) => void;
    newsId?: string;
    channels?: {
        pushSelected: boolean;
        smsSelected: boolean;
        emailSelected: boolean;
    };
    activeWidgetDetail?: boolean;
    onUpdateWidget?: (widget: boolean) => void;
    contractObjectCount?: {
        usersWithAqmeterContracts: number;
        totalContracts: number;
        emailDestinations: number;
        smsDestinations: number;
        pushDestinations: number;
    };
}

const SendingChannels: React.FC<SendingChannelsProps> = ({
    whereTheComponentIs,
    data,
    setData,
    webWidget,
    setWebWidget,
    channels,
    activeWidgetDetail,
    onUpdateWidget,
    contractObjectCount,
}) => {
    const [
        widgetActiveSendingChannelDetail,
        setWidgetActiveSendingChannelDetail,
    ] = useState(activeWidgetDetail);

    whereTheComponentIs === "DetailsNewsPage" &&
        onUpdateWidget(widgetActiveSendingChannelDetail);

    const [loading] = useState(false);
    return (
        <>
            {whereTheComponentIs === "CreateNewsPage" ? (
                <IonList
                    className={styles.channelsWrapper}
                >
                    <div
                        style={{
                            backgroundColor: "#f1f1f1",
                            borderRadius: "6px",
                            padding: "10px 15px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <span style={{ fontWeight: "500" }}>
                                {t("news.create.labelNotifications")}
                            </span>
                            <span style={{ color: "#828592" }}>
                                {t("news.create.labelContractNotifyiable")}
                            </span>
                        </div>
                    </div>
                    <IonItem
                        lines={"full"}
                        onClick={() => setWebWidget((prev) => !prev)}
                    >
                        <IonToggle
                            slot="start"
                            color={"tertiary"}
                            checked={webWidget}
                            style={{ paddingInlineEnd: "3px" }}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.active")}
                        </IonLabel>
                    </IonItem>
                    <IonItem
                        lines={"full"}
                        onClick={() => setWebWidget((prev) => !prev)}
                    >
                        <IonCheckbox
                            slot="start"
                            color={"tertiary"}
                            checked={webWidget}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.web")}
                        </IonLabel>
                    </IonItem>
                    <IonItem
                        lines={"full"}
                        onClick={() =>
                            setData((prev) => {
                                return {
                                    ...prev,
                                    isEmailSelected: !prev.isEmailSelected,
                                };
                            })
                        }
                    >
                        <IonCheckbox
                            slot="start"
                            color={"tertiary"}
                            checked={data.isEmailSelected}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.email")}
                        </IonLabel>
                        <span>
                            {contractObjectCount?.emailDestinations
                                ? contractObjectCount?.emailDestinations
                                : "-"}
                        </span>
                    </IonItem>
                    <IonItem
                        lines={"full"}
                        onClick={() =>
                            setData((prev) => {
                                return {
                                    ...prev,
                                    isSmsSelected: !prev.isSmsSelected,
                                };
                            })
                        }
                    >
                        <IonCheckbox
                            slot="start"
                            color={"tertiary"}
                            checked={data.isSmsSelected}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.sms")}
                        </IonLabel>
                        <span>
                            {contractObjectCount?.smsDestinations
                                ? contractObjectCount?.smsDestinations
                                : "-"}
                        </span>
                    </IonItem>
                    <IonItem
                        lines={"full"}
                        onClick={() =>
                            setData((prev) => {
                                return {
                                    ...prev,
                                    isPushSelected: !prev.isPushSelected,
                                };
                            })
                        }
                    >
                        <IonCheckbox
                            slot="start"
                            color={"tertiary"}
                            checked={data.isPushSelected}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.push")}
                        </IonLabel>
                        <span>
                            {contractObjectCount?.pushDestinations
                                ? contractObjectCount?.pushDestinations
                                : "-"}
                        </span>
                    </IonItem>
                    <p
                        style={{
                            textAlign: "right",
                            marginBottom: "0",
                        }}
                    >
                        <span
                            style={{
                                fontWeight: "500",
                                marginRight: "10px",
                            }}
                        >
                            {contractObjectCount?.totalContracts &&
                            (contractObjectCount?.pushDestinations ||
                                contractObjectCount?.emailDestinations ||
                                contractObjectCount?.smsDestinations)
                                ? `Total`
                                : ""}
                        </span>
                        <span style={{ color: "#007CEF" }}>{`${
                            contractObjectCount?.usersWithAqmeterContracts
                                ? `${contractObjectCount?.usersWithAqmeterContracts} din ${contractObjectCount?.totalContracts}`
                                : ""
                        }`}</span>
                    </p>
                </IonList>
            ) : whereTheComponentIs === "DetailsNewsPage" ? (
                <IonList
                    className={styles.channelsWrapperDetails}
                >
                    <span
                        style={{
                            display: "flex",
                        }}
                    >
                        <IonItem lines={"none"}>
                            <IonLabel>
                                {t("news.details.sendingChannels.active")}
                            </IonLabel>
                            <IonToggle
                                className={styles.toggleWebWidget}
                                style={{ paddingRight: "8px" }}
                                checked={widgetActiveSendingChannelDetail}
                                // enableOnOffLabels={true}
                                color={"tertiary"}
                                disabled={loading}
                                slot="start"
                                onClick={async () => {
                                    setWidgetActiveSendingChannelDetail(
                                        (prev) => !prev
                                    );
                                    // await patchWidgetActiveHandler();
                                }}
                            />
                        </IonItem>
                    </span>
                    <IonItem lines={"none"}>
                        <IonIcon
                            icon={
                                widgetActiveSendingChannelDetail
                                    ? checkmarkCircleOutline
                                    : ellipseOutline
                            }
                            style={{
                                fontSize: "20px",
                                marginRight: "5px",
                            }}
                            slot="start"
                            color={
                                widgetActiveSendingChannelDetail
                                    ? "tertiary"
                                    : ""
                            }
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.web")}
                        </IonLabel>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonIcon
                            icon={
                                channels.emailSelected
                                    ? checkmarkCircleOutline
                                    : ellipseOutline
                            }
                            style={{
                                fontSize: "20px",
                                marginRight: "5px",
                            }}
                            slot="start"
                            color={channels.emailSelected ? "tertiary" : ""}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.email")}
                        </IonLabel>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonIcon
                            icon={
                                channels.smsSelected
                                    ? checkmarkCircleOutline
                                    : ellipseOutline
                            }
                            style={{
                                fontSize: "20px",
                                marginRight: "5px",
                            }}
                            slot="start"
                            color={channels.smsSelected ? "tertiary" : ""}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.sms")}
                        </IonLabel>
                    </IonItem>
                    <IonItem lines={"none"}>
                        <IonIcon
                            icon={
                                channels.pushSelected
                                    ? checkmarkCircleOutline
                                    : ellipseOutline
                            }
                            style={{
                                fontSize: "20px",
                                marginRight: "5px",
                            }}
                            slot="start"
                            color={channels.pushSelected ? "tertiary" : ""}
                        />
                        <IonLabel>
                            {t("news.details.sendingChannels.push")}
                        </IonLabel>
                    </IonItem>
                </IonList>
            ) : null}
        </>
    );
};

export default SendingChannels;
