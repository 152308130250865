import { Capacitor } from "@capacitor/core";
import {
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonToggle,
} from "@ionic/react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { userActions } from "../../../api/UserActions";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import { useSettings } from "../../../context/SettingsContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { Settings } from "../../../types/responseTypes";
import { useTranslation } from "react-i18next";

import styles from "./UserPages.module.scss";

const SettingsPage: React.FC = () => {
    const { settings } = useSettings();
    const isMobile = Capacitor.isNativePlatform();
    const { t } = useTranslation();

    const [has2FA, setHas2FA] = useState();
    const [isCheckIconShown, setIsCheckIconShown] = useState(false);
    const [isXIconShown, setIsXIconShown] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [mobileVersion] = useState(null);

    const { data: settingsResponse, error } =
        useFetchCaching<Settings>("/users/settings");

    useEffect(() => {
        if (settingsResponse) {
            //@ts-ignore

            setHas2FA(settingsResponse?.has2Fa);
        }
    }, [settingsResponse?.has2Fa, settingsResponse]);

    const appVersion = isMobile ? mobileVersion : settings?.version;

    const toggle2FA = async () => {
        //@ts-ignore
        setHas2FA((prev) => !prev);

        if (!has2FA) {
            setIsCheckIconShown(true);
        } else {
            setIsXIconShown(true);
        }
        setIsDisabled(true);

        await userActions
            .changeUserSettings({
                has2Fa: !has2FA,
            })
            .then(() => {
                setTimeout(() => {
                    if (!has2FA) {
                        setIsCheckIconShown(false);
                    } else {
                        setIsXIconShown(false);
                    }
                    setIsDisabled(false);
                }, 500);
            });
    };

    return (
        <div>
            <IonPage className={`layout--desktop`}>
                <SidebarLayout layoutType={"back"} url={"/more"}>
                    <IonLabel className={styles.pageTitle}>
                        {t("settings.title")}
                    </IonLabel>

                    <SkeletonList
                        loading={!settingsResponse}
                        error={error}
                        type={"simple"}
                    />
                    {settingsResponse ? (
                        <IonList lines="full">
                            <IonItem>
                                <IonLabel color="medium">
                                    {t("settings.2FA")}
                                </IonLabel>
                                <IonToggle
                                    checked={has2FA as boolean | undefined}
                                    slot="end"
                                    color="tertiary"
                                    onClick={toggle2FA}
                                    disabled={isDisabled}
                                />
                                {isCheckIconShown || isXIconShown ? (
                                    <IonIcon
                                        className={styles.checkmark}
                                        icon={
                                            isCheckIconShown
                                                ? checkmarkCircleOutline
                                                : closeCircleOutline
                                        }
                                        size="medium"
                                        color={
                                            isCheckIconShown
                                                ? "primary"
                                                : "warning"
                                        }
                                    />
                                ) : (
                                    ""
                                )}
                            </IonItem>

                            <IonItem>
                                <IonLabel color="medium">
                                    {t("settings.version")}
                                </IonLabel>
                                <IonLabel slot="end" color="medium">
                                    {appVersion}
                                </IonLabel>
                            </IonItem>
                        </IonList>
                    ) : (
                        ""
                    )}
                </SidebarLayout>
            </IonPage>
        </div>
    );
};
export default SettingsPage;
