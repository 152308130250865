import React from "react";
import styles from "./Card.module.scss";

interface CardProps {
    className?: string;
}

const Card: React.FC<CardProps> = ({ className, children }) => {
    return (
        <div className={`${styles.card} ${className ? className : ""}`}>
            {children}
        </div>
    );
};

export default Card;
