import {IonLabel} from "@ionic/react";
import styles from "../../pages/3.Users/UsersPage.module.scss";
import SearchBar from "../SearchBar/SearchBar";
import React from "react";

interface PropsTableHeader  {
    query: URLSearchParams;
    totalElements: number;
    titleHeader: string;
    subtitleHeaderWithNumberOfElements: string;
    urlStringPart: string;
}


const TableHeader: React.FC<PropsTableHeader> = ({query, totalElements, titleHeader, subtitleHeaderWithNumberOfElements, urlStringPart}) =>{

    return (
        <caption>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "40px",
                }}
            >
                <div>
                    <IonLabel className={styles.title}>
                        {titleHeader}
                    </IonLabel>
                    <span style={{display: 'flex', marginTop: '3px', color: "gray"}}>{subtitleHeaderWithNumberOfElements}</span>
                </div>

                <SearchBar query={query} urlStringPart={urlStringPart} />
            </div>
        </caption>
    )
}

export default TableHeader