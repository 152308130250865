import React, { useEffect } from "react";
import { IonButton, IonButtons, IonItem, IonList } from "@ionic/react";
import styles from "./TablePagination.module.scss";
import { useHistory } from "react-router";

interface TablePaginationProps {
    setPageNumber: (updater: any) => void;
    totalPages: number;
    totalElements: number;
    pageNumber: number;
    query?: URLSearchParams;
    urlStringPart: string;
}

const TablePagination: React.FC<TablePaginationProps> = ({
    setPageNumber,
    totalPages,
    totalElements,
    query,
    pageNumber,
    urlStringPart,
}) => {
    let history = useHistory();
    const [params, setParams] = React.useState<string>("");
    const [userWithContract, setUserWithContract] = React.useState<string>("");
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [sortParam, setSortParam] = React.useState<string>("");
    const standardPaginationSetup = (totalPages: number) => {
        let arr = [];
        for (let i = 1; i <= totalPages; i++) {
            arr.push(i);
        }
        return arr;
    };

    useEffect(() => {
        if (query.get("isMandatory") !== null) {
            setParams(
                `&isMandatory=${query.get(
                    "isMandatory"
                )}&isOptional=${query.get(
                    "isOptional"
                )}&isRectified=${query.get("isRectified")}`
            );
        }
        if (
            query.get("searchTerm") !== null &&
            query.get("searchTerm") !== ""
        ) {
            setSearchTerm(`&searchTerm=${query.get("searchTerm")}`);
        } else {
            setSearchTerm("");
        }

        if (query.get("withContractOnly") !== null) {
            setUserWithContract(
                `&withContractOnly=${query.get("withContractOnly")}`
            );
        }

        if (query.get("sortIndex") !== null) {
            setSortParam(
                `&sortIndex=${query.get("sortIndex")}&sortDirection=${query.get(
                    "sortDirection"
                )}`
            );
        }
    }, [query]);

    const deltaButtons = (pageNumber: number, totalPages: number) => {
        let initialPage = 1;
        return (
            <>
                {pageNumber < 4 ? (
                    <>
                        <IonButton
                            // disabled={!(pageNumber-1)}
                            className={
                                pageNumber === initialPage + 1
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            onClick={() => {
                                setPageNumber(initialPage + 1);
                                query.set("page", `${initialPage + 1}`);
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {initialPage + 1}
                            </p>
                        </IonButton>
                        <IonButton
                            // disabled={!pageNumber}
                            className={
                                pageNumber === initialPage + 2
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            onClick={() => {
                                setPageNumber(initialPage + 2);
                                query.set("page", `${initialPage + 2}`);
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {initialPage + 2}
                            </p>
                        </IonButton>
                        <IonButton
                            disabled={!pageNumber}
                            className={
                                pageNumber === initialPage + 3
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            onClick={() => {
                                setPageNumber(initialPage + 3);
                                query.set("page", `${initialPage + 3}`);
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {initialPage + 3}
                            </p>
                        </IonButton>
                        <strong>...</strong>
                    </>
                ) : pageNumber > pageNumber + 2 ||
                  pageNumber < totalPages - 2 ? (
                    <>
                        <strong>...</strong>
                        <IonButton
                            // disabled={!(pageNumber-1)}
                            // className={styles.paginationPageNumber}
                            onClick={() => {
                                setPageNumber((prev) => prev - 1);
                                query.set(
                                    "page",
                                    `${parseInt(query.get("page")) - 1}`
                                );
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {pageNumber - 1}
                            </p>
                        </IonButton>
                        <IonButton
                            disabled={!pageNumber}
                            className={
                                pageNumber
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            // onClick={() => {
                            //     setPageNumber(1);
                            //     gotoPage(1);
                            // }}
                        >
                            <p className={styles.paginationButtonText}>
                                {pageNumber}
                            </p>
                        </IonButton>
                        <IonButton
                            disabled={!pageNumber}
                            // className={styles.paginationPageNumber}
                            onClick={() => {
                                setPageNumber((prev) => prev + 1);
                                query.set(
                                    "page",
                                    `${parseInt(query.get("page")) + 1}`
                                );
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {pageNumber + 1}
                            </p>
                        </IonButton>
                        <strong>...</strong>
                    </>
                ) : totalPages > totalPages - 3 ? (
                    <>
                        <strong>...</strong>
                        <IonButton
                            // disabled={!(pageNumber-1)}
                            className={
                                pageNumber === totalPages - 3
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            onClick={() => {
                                setPageNumber(totalPages - 3);
                                query.set("page", `${totalPages - 3}`);
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {totalPages - 3}
                            </p>
                        </IonButton>
                        <IonButton
                            // disabled={!(pageNumber-1)}
                            className={
                                pageNumber === totalPages - 2
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            onClick={() => {
                                setPageNumber(totalPages - 2);
                                query.set("page", `${totalPages - 2}`);
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {totalPages - 2}
                            </p>
                        </IonButton>
                        <IonButton
                            disabled={!pageNumber}
                            className={
                                pageNumber === totalPages - 1
                                    ? styles.paginationPageNumberSelected
                                    : null
                            }
                            onClick={() => {
                                setPageNumber(totalPages - 1);
                                query.set("page", `${totalPages - 1}`);
                                history.push(
                                    `/${urlStringPart}?page=${query.get(
                                        "page"
                                    )}${params}${searchTerm}${userWithContract}${sortParam}`
                                );
                            }}
                        >
                            <p className={styles.paginationButtonText}>
                                {totalPages - 1}
                            </p>
                        </IonButton>
                    </>
                ) : null}
            </>
        );
    };

    return (
        <IonItem lines="none">
            <div className={styles.paginationButtons}>
                <IonButton
                    className={styles.ionButton}
                    slot="end"
                    onClick={() => {
                        setPageNumber((prev) => prev - 1);
                        query.set("page", `${parseInt(query.get("page")) - 1}`);
                        history.push(
                            `/${urlStringPart}?page=${query.get(
                                "page"
                            )}${params}${searchTerm}${userWithContract}${sortParam}`
                        );
                    }}
                    disabled={pageNumber === 1 || pageNumber === 0}
                >
                    <p className={styles.paginationButtonArrow}>{"<"}</p>
                </IonButton>
                <IonList slot="end">
                    <IonButtons className={styles.paginationWrapper}>
                        {totalPages > 6 ? (
                            <>
                                <IonButton
                                    className={
                                        pageNumber === 1
                                            ? styles.paginationPageNumberSelected
                                            : null
                                    }
                                    onClick={() => {
                                        setPageNumber(1);
                                        query.set("page", `${1}`);
                                        history.push(
                                            `/${urlStringPart}?page=${query.get(
                                                "page"
                                            )}${params}${searchTerm}${userWithContract}${sortParam}`
                                        );
                                    }}
                                >
                                    <p className={styles.paginationButtonText}>
                                        {1}
                                    </p>
                                </IonButton>
                                {deltaButtons(pageNumber, totalPages)}
                                <IonButton
                                    // disabled={pageNumber === totalPages}
                                    className={
                                        pageNumber === totalPages
                                            ? styles.paginationPageNumberSelected
                                            : null
                                    }
                                    onClick={() => {
                                        setPageNumber(totalPages);
                                        query.set("page", `${totalPages}`);
                                        history.push(
                                            `/${urlStringPart}?page=${query.get(
                                                "page"
                                            )}${params}${searchTerm}${userWithContract}${sortParam}`
                                        );
                                    }}
                                >
                                    <p className={styles.paginationButtonText}>
                                        {totalPages}
                                    </p>
                                </IonButton>
                            </>
                        ) : (
                            standardPaginationSetup(totalPages).map((page) => {
                                return (
                                    <IonButton
                                        // disabled={pageNumber === totalPages}
                                        key={page}
                                        className={
                                            pageNumber === page
                                                ? styles.paginationPageNumberSelected
                                                : null
                                        }
                                        onClick={() => {
                                            setPageNumber(page);
                                            query.set("page", `${page}`);
                                            history.push(
                                                `/${urlStringPart}?page=${query.get(
                                                    "page"
                                                )}${params}${searchTerm}${userWithContract}${sortParam}`
                                            );
                                        }}
                                    >
                                        <p
                                            className={
                                                styles.paginationButtonText
                                            }
                                        >
                                            {page}
                                        </p>
                                    </IonButton>
                                );
                            })
                        )}
                    </IonButtons>
                </IonList>
                <IonButton
                    slot="end"
                    className={styles.ionButton}
                    onClick={() => {
                        setPageNumber((prev) => prev + 1);
                        query.set("page", `${parseInt(query.get("page")) + 1}`);
                        history.push(
                            `/${urlStringPart}?page=${query.get(
                                "page"
                            )}${params}${searchTerm}${userWithContract}${sortParam}`
                        );
                    }}
                    disabled={
                        query.get("page") === `${totalPages}` &&
                        totalPages === pageNumber
                    }
                >
                    <p className={styles.paginationButtonArrow}>{">"}</p>
                </IonButton>
            </div>
        </IonItem>
    );
};

export default TablePagination;
