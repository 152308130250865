import { IonContent } from "@ionic/react";
import React from "react";
import UnauthorizedRoutes from "../../routes/UnauthorizedRoutes";

const UnauthorizedPages: React.FC = () => {
    return (
        <IonContent>
            <UnauthorizedRoutes />
        </IonContent>
    );
};

export default UnauthorizedPages;
