import { IonLabel, IonPage } from "@ionic/react";
import React from "react";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import ChangePasswordPageForm from "./ChangePasswordPageForm";
import styles from "./UserPages.module.scss";

const ChangePasswordPage: React.FC = () => {
    return (
        <IonPage className={`layout--desktop`}>
            <SidebarLayout layoutType={"back"} url={"/more"}>
                <IonLabel className={styles.pageTitle}>
                    {"Schimbare parolă"}
                </IonLabel>
                <ChangePasswordPageForm />
            </SidebarLayout>
        </IonPage>
    );
};

export default ChangePasswordPage;
