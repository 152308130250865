import React from "react";
import { IonIcon, IonLabel, IonRouterLink } from "@ionic/react";
import { logOutOutline } from "ionicons/icons";

import "../NavigationBar.scss";

interface Props {
    extendMenu: boolean;
    onClickLogout: () => void;
}

const UserIcons: React.FC<Props> = ({ extendMenu, onClickLogout }) => {
    return (
        <IonRouterLink
            {...(!extendMenu && { title: "Logout" })}
            className="icon-menu"
            slot="start"
            onClick={onClickLogout}
            color="tertiary"
        >
            <IonLabel className="menu-element">
                <IonIcon slot="icon-only" size="medium" icon={logOutOutline} />

                {extendMenu && <div className="menu-text">Logout</div>}
            </IonLabel>
        </IonRouterLink>
    );
};

export default UserIcons;
