import React from "react";
import { IonPage, IonLabel } from "@ionic/react";
import { Link } from "react-router-dom";

import "./NotFound.scss";

const NotFound = () => {
    return (
        <IonPage className="page">
            <div className="page">
                <IonLabel className="title">Oops!</IonLabel>
                <IonLabel>404 - PAGE NOT FOUND</IonLabel>
                <IonLabel>PAGINA PE CARE O CAUTI NU EXISTA</IonLabel>
                <IonLabel>
                    INTOARCETE LA <Link to="/login">LOGIN</Link> PENTRU A
                    CONTINUA
                </IonLabel>
                <Link to="/login">
                    <img
                        alt="Aqmeter"
                        className="img"
                        src="/assets/img/aqmeter-small.png"
                    />
                </Link>
            </div>
        </IonPage>
    );
};

export default NotFound;
