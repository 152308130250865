import createHttpService from "./HttpService";

class AddressService {
    getCities(counties: string[], accessTokenCtx: string) {
        const path = "/admin/news/cities";
        const countiesQuery = AddressService.createQueryPart(
            counties,
            "counties"
        );

        //serviciu ce ar fi necesitat optimizare... nu s-a mai alocat timp

        const url = `${path}?${countiesQuery}`;
        return createHttpService.get<string[]>(url, accessTokenCtx);
    }

    getStreets(counties: string[], cities: string[], accessTokenCtx: string) {
        const path = "/admin/news/streets";
        const countiesQuery = AddressService.createQueryPart(
            counties,
            "counties"
        );
        const citiesQuery = AddressService.createQueryPart(cities, "cities");

        const url = `${path}?${countiesQuery}&${citiesQuery}`;

        return createHttpService.get<string[]>(url, accessTokenCtx);
    }

    getNumbers(
        counties: string[],
        cities: string[],
        streets: string[],
        accessTokenCtx: string
    ) {
        const path = "/admin/news/numbers";
        const countiesQuery = AddressService.createQueryPart(
            counties,
            "counties"
        );
        const citiesQuery = AddressService.createQueryPart(cities, "cities");
        const streetsQuery = AddressService.createQueryPart(streets, "streets");

        const url = `${path}?${countiesQuery}&${citiesQuery}&${streetsQuery}`;

        return createHttpService.get<string[]>(url, accessTokenCtx);
    }

    private static createQueryPart(
        subjectsOfSearching: string[],
        theCategoryOfSubject: string
    ) {
        if (!subjectsOfSearching || subjectsOfSearching.length === 0) return "";

        const stringArr = subjectsOfSearching.reduce(
            (accumulator, currentValue) =>
                accumulator + `${theCategoryOfSubject}[]=${currentValue}&`,
            ""
        );
        return encodeURI(stringArr).slice(0, -1);
    }
}

const create = new AddressService();

export default create;
