import React, { useState } from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import useFetchCaching from "../../hooks/useFetchCaching";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import { ContractsRequest } from "../../types/requestTypes";
import useQuery from "../../hooks/useQuery";
import TabelContracts from "./TableContracts/TableContracts";

const ContractsPage: React.FC = () => {
    let query = useQuery();

    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );

    const urlWithSearchTerm = `/admin/contracts?page=${query.get(
        "page"
    )}&searchTerm=${query.get("searchTerm")}`;

    const urlWithoutSearchTerm = `/admin/contracts?page=${query.get("page")}`;

    const urlWithSearchTermSorted = `/admin/contracts?page=${query.get(
        "page"
    )}&searchTerm=${query.get("searchTerm")}&sortIndex=${query.get(
        "sortIndex"
    )}&sortDirection=${query.get("sortDirection")}`;

    const urlWithoutSearchTermSorted = `/admin/contracts?page=${query.get(
        "page"
    )}&sortIndex=${query.get("sortIndex")}&sortDirection=${query.get(
        "sortDirection"
    )}`;

    const { data } = useFetchCaching<ContractsRequest>(
        query.get("sortIndex") === null
            ? query.get("searchTerm") === null
                ? urlWithoutSearchTerm
                : urlWithSearchTerm
            : query.get("searchTerm") === null
            ? urlWithoutSearchTermSorted
            : urlWithSearchTermSorted
    );

    return (
        <IonPage>
            <SidebarLayout
                className="others-page"
                layoutType={"submenu"}
                menuSelected={"item1"}
            >
                <SkeletonList
                    loading={!data}
                    type={"simple"}
                    skeletonWidth={"1200px"}
                />

                {data && (
                    <TabelContracts
                        key={query.get("page")}
                        contracts={data}
                        query={query}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default ContractsPage;
