import React from "react";
import { IonIcon, IonLabel, IonRouterLink } from "@ionic/react";
import {
    informationCircleOutline,
    logOutOutline,
    settingsOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import IconMenuButton from "../IconMenuButton/IconMenuButton";

import "../NavigationBar.scss";

interface WaterAdminIconsProps {
    selected: string;
    setSelected: (selected: string) => void;
    extendMenu: boolean;
    onClickLogout: () => void;
}

const NotificationAdminIcons: React.FC<WaterAdminIconsProps> = ({
    extendMenu,
    onClickLogout,
    selected,
    setSelected,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/news?page=1"}
                icon={informationCircleOutline}
                tooltip={"navigation.widgets"}
                menuContent={"navigation.widgets"}
            />
            <IconMenuButton
                selected={selected}
                setSelected={setSelected}
                extendMenu={extendMenu}
                routerLink={"/more"}
                icon={settingsOutline}
                tooltip={"navigation.settings"}
                menuContent={"navigation.settings"}
            />
            <IonRouterLink
                title={extendMenu ? "" : t("navigation.logout")}
                className="icon-menu"
                slot="start"
                onClick={onClickLogout}
                color={selected === "logOutOutline" ? "tertiary" : ""}
            >
                <IonLabel
                    className="menu-element"
                    style={{
                        color: selected === "logOutOutline" ? "white" : "",
                    }}
                >
                    <IonIcon
                        slot="icon-only"
                        size="medium"
                        icon={logOutOutline}
                    />

                    {extendMenu && (
                        <div className="menu-text">
                            {t("navigation.logout")}
                        </div>
                    )}
                </IonLabel>
            </IonRouterLink>
        </>
    );
};

export default NotificationAdminIcons;
