import React from "react";
import UserIcons from "./IconsMenu/UserIcons";
import WaterAdminIcons from "./IconsMenu/WaterAdminIcons";
import WasteAdminIcons from "./IconsMenu/WasteAdminIcons";
import SuperAdminIcons from "./IconsMenu/SuperAdminIcons";
import { LogoutData } from "../../types/requestTypes";
import { useHistory } from "react-router";
import useLogout from "../../hooks/useLogout";
import { ExceptionDto } from "../../types/schema";
import { toastAdd } from "../CustomToast/CustomToasts";
import NotificationAdminIcons from "./IconsMenu/NotificationAdminIcons";

interface Props {
    userRoles: string[];
    userData: any;
    companyType: string;
    extendMenu: boolean;
    selected: string;
    setSelected: (selected: string) => void;
}

const MainMenu: React.FC<Props> = ({
    userRoles,
    userData,
    companyType,
    extendMenu,
    selected,
    setSelected,
}) => {
    const history = useHistory();
    const logout = useLogout();

    const onClickLogout = async () => {
        const data: LogoutData = {
            userEmail: userData.mailCtx,
            fcmToken: "",
        };
        try {
            await logout(data);
            history.replace("/login");
        } catch (err: any) {
            const ex: ExceptionDto = err.response.data;
            toastAdd(`err.${ex.message}`, "error");
        }
    };

    const renderNotificationAdminMenu = () => {
        if (
            userRoles.find((role: string) => role === "ROLE_NOTIFICATION_ADMIN")
        ) {
            return (
                <NotificationAdminIcons
                    extendMenu={extendMenu}
                    onClickLogout={onClickLogout}
                    selected={selected}
                    setSelected={setSelected}
                />
            );
        }
    };

    const renderUserMenu = () => {
        if (userRoles.find((role: string) => role === "ROLE_USER")) {
            return (
                <UserIcons
                    extendMenu={extendMenu}
                    onClickLogout={onClickLogout}
                />
            );
        }
    };

    const renderWaterAdminMenu = () => {
        if (
            companyType === "WATER" &&
            userRoles.find(
                (role: string) => role === "ROLE_CSADMIN" || "ROLE_ITADMIN"
            )
        ) {
            return (
                <WaterAdminIcons
                    extendMenu={extendMenu}
                    onClickLogout={onClickLogout}
                    selected={selected}
                    setSelected={setSelected}
                />
            );
        }
    };

    const renderWasteAdminMenu = () => {
        if (
            companyType === "WASTE" &&
            userRoles.find(
                (role: string) => role === "ROLE_CSADMIN" || "ROLE_ITADMIN"
            )
        ) {
            return (
                <WasteAdminIcons
                    extendMenu={extendMenu}
                    onClickLogout={onClickLogout}
                    selected={selected}
                    setSelected={setSelected}
                />
            );
        }
        return null;
    };

    const renderSuperAdminMenu = () => {
        if (userRoles.find((role: string) => role === "ROLE_SUPERUSER")) {
            return (
                <SuperAdminIcons
                    extendMenu={extendMenu}
                    onClickLogout={onClickLogout}
                />
            );
        }
    };

    const renderMenu = () => {
        return (
            renderSuperAdminMenu() ||
            renderWasteAdminMenu() ||
            renderWaterAdminMenu() ||
            renderUserMenu() ||
            renderNotificationAdminMenu()
        );
    };

    return <>{renderMenu()}</>;
};

export default MainMenu;
