import {
    IonButton,
    IonItem,
    IonLabel,
    IonPage,
    IonSpinner,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import {userActions} from "../../api/UserActions";
import {toastAdd} from "../CustomToast/CustomToasts";
import ImageCardLayout from "../../layout/ImageCardLayout/ImageCardLayout";
import FormFeedback from "../UI/FormFeedback";

const ConfirmNewPasswordPage: React.FC = () => {
    const { t } = useTranslation();
    const { updateAuthCtx } = useContext(AuthContext);
    const { token } = useParams() as { token: string };

    const [error, setError] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        userActions
            .confirmNewPassword(token)
            .then((response) => {
                if (response) {
                    toastAdd(t("passwordRecoverySuccess"), "success");
                    updateAuthCtx(response);
                } else {
                    setError(t("passwordRecoveryFailed"));
                }
                setIsLoading(false);
            })
            .catch((err) => {
                setError(t(`err.${err.response?.data?.message}`));
                setIsLoading(false);
            });
    }, []);

    return (
        <IonPage>
            <ImageCardLayout
                title={t("passwordRecoveryTitle")}
                imgUrl={"./assets/img/register.jpg"}
                logo={true}
            >
                {isLoading ? (
                    <IonItem lines="none">
                        <IonSpinner slot="start" color="primary" />
                        <IonLabel color="medium">{t("suspense.loading")}</IonLabel>
                    </IonItem>
                ) : (
                    <>
                        {!!error ? (
                            <FormFeedback type={"error"}>{error}</FormFeedback>
                        ) : (
                            <FormFeedback type={"success"}>
                                {t("passwordRecoverySuccess")}
                            </FormFeedback>
                        )}
                        <IonButton color={!!error ? "dark" : "tertiary"} href={"/login"}>
                            {!!error ? t("backToLogin") : t("login.cta")}
                        </IonButton>
                    </>
                )}
            </ImageCardLayout>
        </IonPage>
    );
};

export default ConfirmNewPasswordPage;
