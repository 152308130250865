import { IonContent } from "@ionic/react";
import React, { useContext } from "react";
import { SecondaryMenuItemsType } from "../../types/otherTypes";
import Card from "../UI/Card";
import styles from "./SidebarLayout.module.scss";
import UserContext from "../../context/UserContext";
import { useWindowSize } from "../../hooks/useWindowSize";

interface SidebarLayoutProps {
    className?: string;
    layoutType: "submenu" | "back";
    menuSelected?: SecondaryMenuItemsType;
    url?: string;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({
    className,
    children,
}) => {
    const { layoutCtx } = useContext(UserContext);
    const size = useWindowSize();

    return (
        <IonContent className={`${className ? className : ""}`} fullscreen>
            <section className={styles.wrapper}>
                {/*tablet or desktop*/}
                <aside>
                    {/*daca layoutType e submenu, se afiseaza meniul secundar in stanga*/}
                    {/*daca layoutType e back, se afiseaza btn inapoi in stanga  */}
                    {/* {layoutType === "submenu" ? (
                        <SecondaryMenu
                            type={menuType as SecondaryMenuType}
                            selected={menuSelected as SecondaryMenuItemsType}
                        />
                    ) : url ? null : null}*/}
                </aside>

                {size.width > 420 ? (
                    <Card
                        className={`${
                            layoutCtx ? styles.mainRestraint : styles.main
                        } ${styles.layoutCard}`}
                    >
                        {children}
                    </Card>
                ) : (
                    <Card className={`${styles.main} ${styles.layoutCard}`}>
                        {children}
                    </Card>
                )}
            </section>
        </IonContent>
    );
};

export default SidebarLayout;
