import { IonLabel, IonPage } from "@ionic/react";
import React from "react";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { Profile } from "../../../types/responseTypes";
import ProfilePageForm from "./ProfilePageForm";
import styles from "./UserPages.module.scss";

const ProfilePage: React.FC = () => {
    const { data: profile, error } = useFetchCaching<Profile>("/users/profile");

    return (
        <IonPage className={`layout--desktop`}>
            <SidebarLayout
                className="others-page"
                layoutType={"back"}
                url={"/more"}
            >
                <IonLabel className={styles.pageTitle}>{"Profil"}</IonLabel>
                <SkeletonList
                    loading={!profile}
                    error={error}
                    type={"simple"}
                />
                {profile ? <ProfilePageForm profile={profile} /> : ""}
            </SidebarLayout>
        </IonPage>
    );
};

export default ProfilePage;
