import React, { useMemo } from "react";
import { IonBadge, IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import { UserContent, UsersRequest } from "../../../types/requestTypes";
import moment from "moment/moment";
import { useHistory } from "react-router";
import TablePagination from "../../../components/Pagination/TablePagination";
import { useTranslation } from "react-i18next";
import SortingIcons from "../../../components/UI/SortingIcons";
import SearchBar from "../../../components/SearchBar/SearchBar";

import styles from "../UsersPage.module.scss";

interface TabelProps {
    users: UsersRequest;
    query: URLSearchParams;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
}

const TabelUsers: React.FC<TabelProps> = ({
    users,
    query,
    pageNumber,
    setPageNumber,
}) => {
    const history = useHistory<History>();
    const { t } = useTranslation();

    const data = useMemo<UserContent[]>(() => users.content, [users]);
    const totalPages = useMemo<number>(() => users.totalPages, [users]);
    const totalElements = useMemo<number>(() => users.totalElements, [users]);

    const handleCheckbox = (e) => {
        if (e.target.checked) {
            if (query.get("searchTerm") === null) {
                query.set("withContractOnly", "false");
                if (query.get("sortIndex") !== null) {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${query.get(
                            "sortIndex"
                        )}&sortDirection=${query.get("sortDirection")}`
                    );
                } else {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&withContractOnly=${query.get("withContractOnly")}`
                    );
                }
            } else {
                query.set("withContractOnly", "false");
                if (query.get("sortIndex") !== null) {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&searchTerm=${query.get(
                            "searchTerm"
                        )}&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${query.get(
                            "sortIndex"
                        )}&sortDirection=${query.get("sortDirection")}`
                    );
                } else {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&searchTerm=${query.get(
                            "searchTerm"
                        )}&withContractOnly=${query.get("withContractOnly")}`
                    );
                }
            }
        } else {
            if (query.get("searchTerm") === null) {
                query.set("withContractOnly", "true");
                if (query.get("sortIndex") !== null) {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${query.get(
                            "sortIndex"
                        )}&sortDirection=${query.get("sortDirection")}`
                    );
                } else {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&withContractOnly=${query.get("withContractOnly")}`
                    );
                }
            } else {
                query.set("withContractOnly", "true");
                if (query.get("sortIndex") !== null) {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&searchTerm=${query.get(
                            "searchTerm"
                        )}&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${query.get(
                            "sortIndex"
                        )}&sortDirection=${query.get("sortDirection")}`
                    );
                } else {
                    history.push(
                        `/users?page=${query.get(
                            "page"
                        )}&searchTerm=${query.get(
                            "searchTerm"
                        )}&withContractOnly=${query.get("withContractOnly")}`
                    );
                }
            }
        }
    };

    return (
        <>
            <table style={{ width: "100%" }} className={styles.table}>
                <caption>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                        }}
                    >
                        <div>
                            <IonLabel className={styles.title}>
                                {t("users.table.title")}
                            </IonLabel>
                            <span
                                style={{
                                    display: "flex",
                                    marginTop: "3px",
                                    color: "gray",
                                }}
                            >
                                {t("totalSubtitle.users", {
                                    usersCount: totalElements,
                                })}
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <SearchBar query={query} urlStringPart={"users"} />
                            <IonItem lines="none">
                                <IonCheckbox
                                    checked={
                                        query.get("withContractOnly") ===
                                        "false"
                                    }
                                    color="tertiary"
                                    onClick={handleCheckbox}
                                />
                                <IonLabel>{t("users.table.checkbox")}</IonLabel>
                            </IonItem>
                        </div>
                    </div>
                </caption>

                <thead className={styles.headerWrapper}>
                    <tr>
                        <SortingIcons
                            columnLabel={t("users.table.header.name")}
                            query={query}
                            urlStringPart={"users"}
                            sortIndex={0}
                            deafultSort={false}
                        />
                        <SortingIcons
                            columnLabel={t("users.table.header.startDate")}
                            width={"230px"}
                            urlStringPart={"users"}
                            sortIndex={1}
                            query={query}
                            deafultSort={true}
                        />
                        <SortingIcons
                            columnLabel={t("users.table.header.lastLogin")}
                            width={"250px"}
                            urlStringPart={"users"}
                            sortIndex={2}
                            query={query}
                            deafultSort={false}
                        />
                        <SortingIcons
                            columnLabel={t("users.table.header.contracts")}
                            width={"150px"}
                            urlStringPart={"users"}
                            sortIndex={null}
                            deafultSort={false}
                        />
                    </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                    {data.map((user) => (
                        <tr
                            key={user.id}
                            onClick={() => {
                                history.push(`/user/${user.id}`);
                            }}
                        >
                            <td
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "flex-start",
                                    textAlign: "center",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={user.userAvatarUrl}
                                    alt="Avatar"
                                    width="38px"
                                />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <span style={{ textAlign: "start" }}>
                                        {user.name !== null
                                            ? `${user.name}`
                                            : t("noData")}
                                    </span>
                                    <span
                                        style={{
                                            textAlign: "start",
                                            color: "gray",
                                        }}
                                    >
                                        {user.email !== null
                                            ? `${user.email}`
                                            : t("noData")}
                                    </span>
                                </div>
                            </td>
                            <td>
                                {user.createDate
                                    ? moment(user.createDate).format("L")
                                    : t("noData")}
                            </td>
                            <td>
                                {user.lastLogin
                                    ? moment(user.lastLogin).format("L")
                                    : t("noData")}
                            </td>
                            <td>
                                <div
                                    style={{
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <IonBadge className={styles.badgeContracts}>
                                        {user.numberOfContracts !== null
                                            ? `${user.numberOfContracts}`
                                            : t("noData")}
                                    </IonBadge>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"users"}
            />
        </>
    );
};

export default TabelUsers;
