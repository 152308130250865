import { IonIcon } from "@ionic/react";
import {
  thumbsUp,
  close,
  alertCircle,
  informationCircle,
} from "ionicons/icons";
import { toast } from "react-toastify";
import styles from "./CustomToasts.module.scss";

export const toastAdd = (textToast: string, type: string) => {
  toast(textToast, {
    toastId: textToast,
    style: {
      display: "flex",
      alignItems: "center",
      background:
        type === "success"
          ? "var(--ion-color-primary)" // success
          : type === "error"
          ? "var(--ion-color-danger)" // error
          : "white", // info
      padding: "15px, 20px, 15px, 20px",
      maxWidth: "var(--max-element-size)",
      height: "auto",
      border: type === "info" ? "1px solid var(--ion-color-tertiary)" : "0",
      borderRadius: "20px",
      boxShadow: "0px 10px 18px -10px rgba(62, 87, 125, 0.06)",
      color: type === "info" ? "var(--ion-color-tertiary)" : "#fff", // error and success white, info tertiary
      fontSize: "14px",

    },
    icon: () => (
      <div className={styles.toastIconBg}>
        <IonIcon
          icon={
            type === "success"
              ? thumbsUp // success
              : type === "error"
              ? alertCircle // error
              : informationCircle // info
          }
          className={styles.toastIcon}
        />
      </div>
    ),
    closeButton: () => (
      <IonIcon icon={close} className={styles.toastCloseBtn} />
    ),
    hideProgressBar: true,
    bodyStyle: {
      display: "flex",
      alignItems: "center",
      gap: "15px",
      lineHeight: "21px",
      fontWeight: "600",
    },
  });
};
