import React, {useContext, useEffect, useRef, useState} from "react";
import {IonButton, IonCol, IonGrid, IonLabel, IonPage, IonRow, IonSpinner} from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import styles from "./News.module.scss";
import StandardInput from "../../components/UI/StandardInput";
import moment from "moment";
import NewsSelector from "../../components/UI/NewsSelector";
import EdtiorApiTiny from "../../components/UI/EdtiorApiTiny";
import SendingChannels from "./Details/DetailsComponents/SendingChannels";
import {toastAdd} from "../../components/CustomToast/CustomToasts";
import useFetchCaching from "../../hooks/useFetchCaching";
import {CountiesForNewsForm, PostNews} from "../../types/requestTypes";
import useAddresses from "../../hooks/useAddresses";
import httpService from "../../services/HttpService";
import AuthContext from "../../context/AuthContext";
import {useHistory} from "react-router";
import {t} from "i18next";

const CreateNewsPage: React.FC = () => {
    const [titleAndDate, setTitileAndDate] = useState({
        title: "",
        date: `${moment().locale("en").format("YYYY-MM-DDTHH:MM")}`,
    });

    const history = useHistory();

    const [editor, setEditor] = useState("");
    const [widgetWeb, setWidgetWeb] = useState(true);
    const {accessTokenCtx} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [inputErrorClass, setInputErrorClass] = useState({
        title: "",
        editor: ""
    });
    const [, setInputError] = useState(false);

    useEffect(() => {
        if (titleAndDate.title !== "") {
            setInputErrorClass((prev) => {
                return {
                    ...prev,
                    title: "",
                }
            })
        }

    }, [titleAndDate.title])

    function getFormErrors() {
        if (!titleAndDate.title) setInputErrorClass((prev) => {
            return {
                ...prev,
                title: "title",
            }
        })
        if (!editor) setInputErrorClass((prev) => {
            return {
                ...prev,
                editor: "editor",
            }
        })
        if (!(titleAndDate.title && editor)) {
            setInputError(true);
        }
    }

    const handleSubmitNews = async () => {
        setIsLoading(true);
        try {
            getFormErrors();
            if (!(titleAndDate.title && editor)) {
                setInputError(true);
                throw new Error(t("err.UNFILLED_INPUT_CREATE_NEWS"))
            }
            const {request} = httpService.postNews<PostNews>(
                {
                    title: titleAndDate.title,
                    date: titleAndDate.date,
                    counties: selectedCounties,
                    cities: selectedCities,
                    streets: selectedStreets,
                    numbers: selectedNumbers,
                    widgetActive: widgetWeb,
                    isEmailSelected: sendChannels.isEmailSelected,
                    isSmsSelected: sendChannels.isSmsSelected,
                    isPushSelected: sendChannels.isPushSelected,
                    htmlBody: editor,
                    companyId: null,
                },
                accessTokenCtx
            );
            await request;
            toastAdd(t("news.toastSuccess"), "success");
            history.push("/news?page=1");
        } catch (err) {
            // console.log(err.response.data);
            console.log(err)
            // if (err.response.data ==="")
            toastAdd(err.message, "error");
        } finally {
            setIsLoading(false);
        }
    };

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            setEditor(editorRef.current.getContent());
        }
    };

    // ----

    const {
        selectedCounties,
        setSelectedCounties,
        selectedCities,
        setSelectedCities,
        allCities,
        allStreets,
        setSelectedStreets,
        selectedStreets,
        allNumbers,
        setSelectedNumbers,
        selectedNumbers,
        contractObject,
        sendChannels,
        setSendChannels,
    } = useAddresses();

    // Init data
    const [initData, setInitData] = useState<CountiesForNewsForm>();

    //TODO: de redus nr. randări inexplicabile. de reconsiderat implementarea state-ului.

    const {data: initDataFetch} =
        useFetchCaching<CountiesForNewsForm>("/admin/news/init");

    if (initDataFetch && !initData) setInitData(initDataFetch);

    useEffect(() => {
        if (!initData) return;

        setSelectedCounties([initData.defaultCounty]);
    }, [initData]);

    return (
        <IonPage>
            <SidebarLayout className="others-page" layoutType={"submenu"}>
                <IonLabel
                    className={styles.title}
                    style={{
                        marginBottom: "30px",
                    }}
                >
                    {t("news.create.title")}
                </IonLabel>
                <IonGrid fixed={true} style={{width: "950px"}}>
                    <IonRow>
                        <IonCol size="12" size-sm="6">
                            <StandardInput
                                className={inputErrorClass.title === "title" ? "input-error" : ""}
                                labelName={t("news.create.label.title")}
                                inputValue={titleAndDate.title}
                                setInputValue={(ev) =>
                                    setTitileAndDate((prev) => {
                                        return {...prev, title: ev.target.value};
                                    })
                                }
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <StandardInput
                                labelName={t("news.create.label.date")}
                                inputValue={titleAndDate.date}
                                inputType={"datetime-local"}
                                setInputValue={(ev) =>
                                    setTitileAndDate((prev) => {
                                        return {...prev, date: ev.target.value};
                                    })
                                }
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <NewsSelector
                                disabled={false}
                                labelInput={t("news.create.label.county")}
                                allOptions={initData?.counties}
                                onUpdate={setSelectedCounties}
                                selectedOptions={selectedCounties}
                                placeholder={t("news.create.label.placeholderCounty")}
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <NewsSelector
                                disabled={allCities.length === 0 || !allCities}
                                labelInput={t("news.create.label.city")}
                                allOptions={allCities}
                                onUpdate={setSelectedCities}
                                selectedOptions={selectedCities}
                                placeholder={t("news.create.label.placeholderCity")}
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <NewsSelector
                                disabled={allStreets.length === 0 || !allStreets}
                                labelInput={t("news.create.label.street")}
                                allOptions={allStreets}
                                onUpdate={setSelectedStreets}
                                selectedOptions={selectedStreets}
                                placeholder={t("news.create.label.placeholderStreet")}
                            />
                        </IonCol>
                        <IonCol size="12" size-sm="6">
                            <NewsSelector
                                disabled={allNumbers.length === 0 || !allNumbers}
                                allOptions={allNumbers}
                                onUpdate={setSelectedNumbers}
                                selectedOptions={selectedNumbers}
                                labelInput={t("news.create.label.number")}
                                placeholder={t("news.create.label.placeholderNumber")}
                            />
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div
                    style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                    }}
                >
                    <SendingChannels
                        contractObjectCount={contractObject}
                        webWidget={widgetWeb}
                        setWebWidget={setWidgetWeb}
                        whereTheComponentIs={"CreateNewsPage"}
                        data={sendChannels}
                        setData={setSendChannels}
                    />
                </div>

                <div>
                    <EdtiorApiTiny
                        editorRef={editorRef}
                        initialValue={""}
                        setEditor={setEditor}
                        className={inputErrorClass.editor === "editor" ? "input-error" : ""}
                        setInputErrorClass={setInputErrorClass}
                        isDetailPage={false}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                    }}
                >
                    <IonButton
                        style={{
                            width: "80%",
                        }}
                        onClick={async () => {
                            setEditor(editorRef.current.getContent());
                            await handleSubmitNews();
                        }}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <IonSpinner name="circles"/>{" "}
                                {t("news.create.sendButtonLabelLoading")}
                            </>
                        ) : (
                            t("news.create.sendButtonLabel")
                        )}
                    </IonButton>
                </div>
            </SidebarLayout>
        </IonPage>
    );
};
export default CreateNewsPage;
