import React, { useContext } from "react";
import { DetailsPageUserData } from "../../../types/requestTypes";
import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonIcon,
    IonLabel,
} from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import UserContext from "../../../context/UserContext";
import DetailContractCard from "./DetailContractCard";
import moment from "moment";
import AuthContext from "../../../context/AuthContext";
import { useTranslation } from "react-i18next";

import styles from "./DetailPage.module.scss";

type DetailPageUserInfoProps = {
    data: DetailsPageUserData;
};

const DetailPageUserInfo: React.FC<DetailPageUserInfoProps> = ({ data }) => {
    const { t } = useTranslation();
    const userData = useContext(AuthContext);
    const { layoutCtx } = useContext(UserContext);

    const companyId =
        userData.userCompanies && userData.userCompanies.length
            ? userData.userCompanies[0]
            : null;

    return (
        <>
            <div
                className={
                    layoutCtx
                        ? styles.cardsWrapperMenuExtended
                        : styles.cardsWrapperMenuRestraint
                }
            >
                <div
                    className={
                        layoutCtx
                            ? styles.userInfoWrapperExtended
                            : styles.userInfoWrapperRestrain
                    }
                >
                    <IonCard
                        className={
                            layoutCtx
                                ? styles.cardExtended
                                : styles.cardRestrain
                        }
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <h1
                                className={styles.title}
                                style={{
                                    paddingBottom: "0",
                                }}
                            >
                                {t("users.detail.profileTitle")}
                            </h1>

                            <div
                                className={
                                    layoutCtx
                                        ? styles.profileWrapperExtended
                                        : styles.profileWrapperRestrain
                                }
                            >
                                <div
                                    className={
                                        layoutCtx
                                            ? styles.profileMainWrapperExtended
                                            : styles.profileMainWrapperRestrain
                                    }
                                >
                                    <img
                                        className={styles.img}
                                        alt={t("users.detail.altAvatar")}
                                        src={data.userAvatarUrl}
                                    />

                                    <div>
                                        <IonCardTitle
                                            className={styles.cardTitle}
                                            style={{
                                                paddingTop: "20px",
                                                paddingBottom: "10px",
                                            }}
                                        >
                                            {data.name}
                                        </IonCardTitle>

                                        <IonCardContent
                                            className={styles.emailWrapper}
                                        >
                                            <div className={styles.cardContent}>
                                                <IonIcon
                                                    className={styles.icon}
                                                    icon={mailOutline}
                                                />
                                                &nbsp;
                                                {data.email}
                                            </div>
                                        </IonCardContent>
                                    </div>
                                </div>

                                <div className={styles.cardFrequency}>
                                    <div className={`${styles.cardSubtitle}`}>
                                        {t("users.detail.loginFrequency")}
                                    </div>

                                    <div
                                        className={styles.loginFrequencyWrapper}
                                    >
                                        <div className={styles.cardContent}>
                                            <IonLabel
                                                className={
                                                    styles.descriptionText
                                                }
                                            >
                                                {t("users.detail.startDate")}{" "}
                                                &nbsp;
                                            </IonLabel>
                                            <IonLabel
                                                className={styles.infoText}
                                            >
                                                {moment(data.createDate).format(
                                                    "L"
                                                )}
                                            </IonLabel>
                                        </div>

                                        <div className={styles.cardContent}>
                                            <IonLabel
                                                className={
                                                    styles.descriptionText
                                                }
                                            >
                                                {t("users.detail.lastLogin")}{" "}
                                                &nbsp;
                                            </IonLabel>
                                            <IonLabel
                                                className={styles.infoText}
                                            >
                                                {data.lastLoginDate !== null
                                                    ? moment(
                                                          data.lastLoginDate
                                                      ).format("L")
                                                    : "-"}
                                            </IonLabel>
                                        </div>

                                        <div className={styles.cardContent}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                }}
                                            >
                                                <IonLabel
                                                    className={
                                                        styles.descriptionText
                                                    }
                                                >
                                                    {t("users.detail.2FA")}
                                                </IonLabel>

                                                {data.has2FaEnabled ? (
                                                    <div
                                                        className={
                                                            styles.infoText
                                                        }
                                                        style={{
                                                            color: "green",
                                                        }}
                                                        data-testid="checkmark-icon"
                                                    >
                                                        {t(
                                                            "users.detail.2FA.activ"
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles.infoText
                                                        }
                                                        style={{
                                                            color: "red",
                                                        }}
                                                        data-testid="checkmark-icon"
                                                    >
                                                        {t(
                                                            "users.detail.2FA.inactive"
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonCard>
                </div>

                <DetailContractCard
                    data={data}
                    companyIdList={[companyId]}
                    layoutCtx={layoutCtx}
                />
            </div>
        </>
    );
};

export default DetailPageUserInfo;
