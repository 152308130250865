import React from "react";
import {Editor} from "@tinymce/tinymce-react";

type EditorApiTinyProps = {
    editorRef: React.MutableRefObject<any>;
    initialValue: string;
    className?: string;
    setEditor?: (editor: any) => void;
    setInputErrorClass?: (prev: any) => void;
    isDetailPage: boolean;
};

const EdtiorApiTiny: React.FC<EditorApiTinyProps> = ({
                                                         editorRef,
                                                         initialValue,
                                                         className,
                                                         setEditor,
                                                         setInputErrorClass,
                                                         isDetailPage
                                                     }) => {
    return (
        <div className={className}>
            <Editor
                apiKey="chxzb880st7z0fb9i990jd9cm3n087xmggvt1aa4sne07btt"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={initialValue}
                onChange={(evt, editor) => {
                    editorRef.current = editor;
                    if (!isDetailPage) {
                        setEditor(editor.getContent());
                    }

                }}
                onKeyDown={(evt, editor) => {
                    setInputErrorClass((prev) => {
                        return {
                            ...prev,
                            editor: "",
                        }
                    })
                }
                }
                init={{
                    height: 500,
                    mobile: {
                        toolbar_mode: 'floating'
                    },
                    menubar: false,
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "image",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent " +
                        "image",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
        </div>
    );
};

export default EdtiorApiTiny;
