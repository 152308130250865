import React from "react";
import { useHistory } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { caretDownOutline, caretUpOutline } from "ionicons/icons";

import styles from "./SortingIcons.module.scss";

interface SortingIconsProps {
    columnLabel: string;
    sortIndex: number;
    urlStringPart: string;
    query?: URLSearchParams;
    deafultSort: boolean;
    width?: string;
}

const SortingIcons: React.FC<SortingIconsProps> = ({
    columnLabel,
    width,
    query,
    urlStringPart,
    sortIndex,
    deafultSort,
}) => {
    const history = useHistory<History>();

    const handleSort = () => {
        if (
            query.get("sortDirection") === null ||
            query.get("sortDirection") === "desc"
        ) {
            if (query.get("searchTerm") !== null) {
                if (query.get("withContractOnly") !== null) {
                    history.push(
                        `/${urlStringPart}?page=1&searchTerm=${query.get(
                            "searchTerm"
                        )}&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${sortIndex}&sortDirection=asc`
                    );
                } else {
                    if (query.get("isMandatory") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${query.get(
                                "searchTerm"
                            )}&isMandatory=${query.get(
                                "isMandatory"
                            )}&isOptional=${query.get(
                                "isOptional"
                            )}&isRectified=${query.get(
                                "isRectified"
                            )}&sortIndex=${sortIndex}&sortDirection=asc`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${query.get(
                                "searchTerm"
                            )}&sortIndex=${sortIndex}&sortDirection=asc`
                        );
                    }
                }
            } else {
                if (query.get("withContractOnly") !== null) {
                    history.push(
                        `/${urlStringPart}?page=1&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${sortIndex}&sortDirection=asc`
                    );
                } else {
                    if (query.get("isMandatory") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&isMandatory=${query.get(
                                "isMandatory"
                            )}&isOptional=${query.get(
                                "isOptional"
                            )}&isRectified=${query.get(
                                "isRectified"
                            )}&sortIndex=${sortIndex}&sortDirection=asc`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&sortIndex=${sortIndex}&sortDirection=asc`
                        );
                    }
                }
            }
        } else if (query.get("sortDirection") === "asc") {
            if (query.get("searchTerm") !== null) {
                if (query.get("withContractOnly") !== null) {
                    history.push(
                        `/${urlStringPart}?page=1&searchTerm=${query.get(
                            "searchTerm"
                        )}&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${sortIndex}&sortDirection=desc`
                    );
                } else {
                    if (query.get("isMandatory") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${query.get(
                                "searchTerm"
                            )}&isMandatory=${query.get(
                                "isMandatory"
                            )}&isOptional=${query.get(
                                "isOptional"
                            )}&isRectified=${query.get(
                                "isRectified"
                            )}&sortIndex=${sortIndex}&sortDirection=desc`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&searchTerm=${query.get(
                                "searchTerm"
                            )}&&sortIndex=${sortIndex}&sortDirection=desc`
                        );
                    }
                }
            } else {
                if (query.get("withContractOnly") !== null) {
                    history.push(
                        `/${urlStringPart}?page=1&withContractOnly=${query.get(
                            "withContractOnly"
                        )}&sortIndex=${sortIndex}&sortDirection=desc`
                    );
                } else {
                    if (query.get("isMandatory") !== null) {
                        history.push(
                            `/${urlStringPart}?page=1&isMandatory=${query.get(
                                "isMandatory"
                            )}&isOptional=${query.get(
                                "isOptional"
                            )}&isRectified=${query.get(
                                "isRectified"
                            )}&sortIndex=${sortIndex}&sortDirection=desc`
                        );
                    } else {
                        history.push(
                            `/${urlStringPart}?page=1&sortIndex=${sortIndex}&sortDirection=desc`
                        );
                    }
                }
            }
        }
    };

    return (
        <th style={{ width: width }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "2px",
                }}
            >
                <span
                    style={
                        sortIndex !== null
                            ? {
                                  fontSize: "14px",
                                  cursor: "pointer",
                              }
                            : {
                                  fontSize: "14px",
                              }
                    }
                    onClick={query !== undefined ? handleSort : () => {}}
                >
                    {columnLabel}
                </span>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                    }}
                >
                    <SortIcon
                        query={query}
                        sortIndex={sortIndex}
                        defaultSort={deafultSort}
                    />
                </div>
            </div>
        </th>
    );
};

export default SortingIcons;

interface SortIconProps {
    query: URLSearchParams;
    sortIndex: number;
    defaultSort: boolean;
}
const SortIcon: React.FC<SortIconProps> = ({
    query,
    sortIndex,
    defaultSort,
}) => {
    if (defaultSort === true && query.get("sortIndex") === null) {
        return (
            <>
                <IonIcon
                    className={
                        defaultSort === true
                            ? styles.sortIconActive
                            : styles.sortIcon
                    }
                    icon={caretUpOutline}
                />
                <IonIcon
                    className={
                        query.get("sortDirection") === "asc"
                            ? styles.sortIconActive
                            : styles.sortIcon
                    }
                    icon={caretDownOutline}
                />
            </>
        );
    } else if (query !== undefined) {
        return (
            <>
                <IonIcon
                    className={
                        query.get("sortDirection") === "desc" &&
                        query.get("sortIndex") === sortIndex.toString()
                            ? styles.sortIconActive
                            : styles.sortIcon
                    }
                    icon={caretUpOutline}
                />
                <IonIcon
                    className={
                        query.get("sortDirection") === "asc" &&
                        query.get("sortIndex") === sortIndex.toString()
                            ? styles.sortIconActive
                            : styles.sortIcon
                    }
                    icon={caretDownOutline}
                />
            </>
        );
    } else {
        return null;
    }
};
