import React, { createContext, useMemo, useState } from "react";
import { Company, Contract, DashboardMenu } from "../types/responseTypes";

interface UserContextValue {
    menuCtx: DashboardMenu;
    companiesCtx: Company[];
    contractsCtx: Contract[];
    updateMenuCtx: (responseData: DashboardMenu) => void;
    updateCompaniesCtx: (responseData: Company[]) => void;
    updateContractsCtx: (responseData: Contract[]) => void;
    layoutCtx: boolean;
    setLayoutCtx: (value: boolean) => void;
}

const initialMenuValue: DashboardMenu = {
    totalUnpaidInvoices: 0,
    hasWaterContracts: false,
    totalReadingsToSend: 0,
    totalUnreadNotifications: 0,
};

const UserContext = createContext<UserContextValue>({
    menuCtx: initialMenuValue,
    companiesCtx: [],
    contractsCtx: [],
    updateMenuCtx: () => {},
    updateCompaniesCtx: () => {},
    updateContractsCtx: () => {},
    layoutCtx: true,
    setLayoutCtx: () => {},
});

export const UserProvider: React.FC = ({ children }) => {
    const [menuCtx, setMenuCtx] = useState<DashboardMenu>(initialMenuValue);
    const [companiesCtx, setCompaniesCtx] = useState<Company[]>([]);
    const [contractsCtx, setContractsCtx] = useState<Contract[]>([]);
    const [layoutCtx, setLayoutCtx] = useState<boolean>(true);

    const updateMenuCtx = (responseData: DashboardMenu): void => {
        setMenuCtx(responseData);
    };

    const updateCompaniesCtx = (responseData: Company[]): void => {
        setCompaniesCtx(responseData);
    };

    const updateContractsCtx = (responseData: Contract[]): void => {
        setContractsCtx(responseData);
    };

    const contextValue: UserContextValue = useMemo(
        () => ({
            menuCtx,
            companiesCtx,
            contractsCtx,
            updateMenuCtx,
            updateCompaniesCtx,
            updateContractsCtx,
            layoutCtx,
            setLayoutCtx,
        }),
        [
            menuCtx,
            companiesCtx,
            contractsCtx,
            updateMenuCtx,
            updateCompaniesCtx,
            updateContractsCtx,
            layoutCtx,
            setLayoutCtx,
        ]
    );

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
