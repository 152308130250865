import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import styles from "../EmailReturn.module.scss";
import SearchBar from "../../../components/SearchBar/SearchBar";

interface EmailReturnTableHeaderProps {
    query: URLSearchParams;
    totalElements: number;
}

const EmailReturnTableHeader: React.FC<EmailReturnTableHeaderProps> = ({
    query,
    totalElements,
}) => {
    const { t } = useTranslation();
    const history = useHistory<History>();

    const handleIsMandatoryCheckbox = () => {
        if (
            query.get("isMandatory") === "false" ||
            query.get("isMandatory") === null
        ) {
            query.set("isMandatory", "true");

            history.push(
                `/bounce?page=${query.get(
                    "page"
                )}&isMandatory=true&isOptional=${query.get(
                    "isOptional"
                )}&isRectified=${query.get("isRectified")}`
            );
        } else {
            query.set("isMandatory", "false");
            history.push(
                `/bounce?page=${query.get(
                    "page"
                )}&isMandatory=false&isOptional=${query.get(
                    "isOptional"
                )}&isRectified=${query.get("isRectified")}`
            );
        }
    };

    const handleIsOptionalCheckbox = () => {
        if (
            query.get("isOptional") === "false" ||
            query.get("isOptional") === null
        ) {
            query.set("isOptional", "true");
            history.push(
                `/bounce?page=${query.get("page")}&isMandatory=${query.get(
                    "isMandatory"
                )}&isOptional=true&isRectified=${query.get("isRectified")}`
            );
        } else {
            query.set("isOptional", "false");
            history.push(
                `/bounce?page=${query.get("page")}&isMandatory=${query.get(
                    "isMandatory"
                )}&isOptional=false&isRectified=${query.get("isRectified")}`
            );
        }
    };

    const handleIsRectifiedCheckbox = () => {
        if (
            query.get("isRectified") === "false" ||
            query.get("isRectified") === null
        ) {
            query.set("isRectified", "true");
            history.push(
                `/bounce?page=${query.get("page")}&isMandatory=${query.get(
                    "isMandatory"
                )}&isOptional=${query.get("isOptional")}&isRectified=true`
            );
        } else {
            query.set("isRectified", "false");
            history.push(
                `/bounce?page=${query.get("page")}&isMandatory=${query.get(
                    "isMandatory"
                )}&isOptional=${query.get("isOptional")}&isRectified=false`
            );
        }
    };
    return (
        <caption>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        alignSelf: "flex-start",
                    }}
                >
                    <IonLabel className={styles.title}>
                        {t("emailReturn.title")}
                    </IonLabel>
                    <span
                        style={{
                            display: "flex",
                            marginTop: "3px",
                            color: "gray",
                        }}
                    >
                        {t("totalSubtitle.emailRetur", {
                            emailReturCount: totalElements,
                        })}
                    </span>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginBottom: "10px",
                    }}
                >
                    <SearchBar query={query} urlStringPart="bounce" />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <IonItem lines="none">
                            <IonCheckbox
                                slot="start"
                                color="tertiary"
                                checked={query.get("isMandatory") === "true"}
                                onClick={handleIsMandatoryCheckbox}
                            />
                            <IonLabel>
                                {t("emailReturn.checkboxModify")}
                            </IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonCheckbox
                                onClick={handleIsOptionalCheckbox}
                                checked={query.get("isOptional") === "true"}
                                slot="start"
                                color="tertiary"
                            />
                            <IonLabel>
                                {t("emailReturn.checkboxTemporary")}
                            </IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonCheckbox
                                onClick={handleIsRectifiedCheckbox}
                                checked={query.get("isRectified") === "true"}
                                slot="start"
                                color="tertiary"
                            />
                            <IonLabel>
                                {" "}
                                {t("emailReturn.checkboxDone")}
                            </IonLabel>
                        </IonItem>
                    </div>
                </div>
            </div>
        </caption>
    );
};

export default EmailReturnTableHeader;
