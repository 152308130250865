import { useContext } from "react";
import axiosBasic from "../api/customAxios";
import AuthContext from "../context/AuthContext";
import { LogoutData } from "../types/requestTypes";

const useLogout = () => {
  const { logoutCtx } = useContext(AuthContext);

  return async (data: LogoutData): Promise<void> => {
    await axiosBasic.post("/auth/logout", data, { withCredentials: true });

    logoutCtx();
    // folosite la login cu social media
    localStorage.removeItem("isMobile");
    localStorage.removeItem("provider");
  };
};

export default useLogout;
