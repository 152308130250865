import React, { useContext } from "react";
import { type ContractDetail } from "../../../types/requestTypes";
import { IonCard } from "@ionic/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ContractDetails from "./ContractDetails";
import UserContext from "../../../context/UserContext";

import styles from "./DetailPage.module.scss";

type ContractDetailsProps = {
    data: ContractDetail;
};

const DetailPageContractInfo: React.FC<ContractDetailsProps> = ({ data }) => {
    const { t } = useTranslation();
    const { layoutCtx } = useContext(UserContext);

    const emails: string[] = Object.keys(data.groupedContractEmails);
    const rowSpans: { [key: string]: number } = {};
    emails.forEach((email: string) => {
        rowSpans[email] = data.groupedContractEmails[email].length;
    });

    return (
        <div
            className={
                layoutCtx
                    ? styles.cardsWrapperMenuExtended
                    : styles.cardsWrapperMenuRestraint
            }
        >
            <ContractDetails data={data} layoutCtx={false} />
            <div
                className={
                    layoutCtx
                        ? styles.contractCardProfileExtended
                        : styles.contractCardProfileRestrain
                }
            >
                <IonCard className={styles.card}>
                    <h1
                        className={styles.title}
                        style={{
                            paddingBottom: "0",
                        }}
                    >
                        Istoric factura electronică
                    </h1>

                    {emails.length > 0 ? (
                        <div
                            style={{
                                paddingLeft: "14px",
                                paddingRight: "14px",
                                paddingTop: "40px",
                            }}
                        >
                            <table className={styles.table}>
                                <thead className={styles.headerWrapper}>
                                    <tr>
                                        <th>
                                            {t(
                                                "contractDetail.table.headerEmail"
                                            )}
                                        </th>
                                        <th className={styles.active}>
                                            {t(
                                                "contractDetail.table.headerActiv"
                                            )}
                                        </th>
                                        <th>
                                            {t(
                                                "contractDetail.table.headerDate"
                                            )}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className={styles.bodyWrapper}>
                                    {emails.map((email) => {
                                        const contracts =
                                            data.groupedContractEmails[email];

                                        return (
                                            <React.Fragment key={email}>
                                                {contracts.map(
                                                    (
                                                        contract: {
                                                            id: React.Key;
                                                            active: string;
                                                            timestamp: moment.MomentInput;
                                                        },
                                                        contractIndex: number
                                                    ) => {
                                                        const emailCell =
                                                            contractIndex ===
                                                            0 ? (
                                                                <td
                                                                    rowSpan={
                                                                        rowSpans[
                                                                            email
                                                                        ]
                                                                    }
                                                                    className={
                                                                        styles.emailCell
                                                                    }
                                                                >
                                                                    {email}
                                                                </td>
                                                            ) : null;

                                                        return (
                                                            <tr
                                                                key={
                                                                    contract.id
                                                                }
                                                                className={
                                                                    styles.tableRow
                                                                }
                                                            >
                                                                {emailCell}

                                                                <td>
                                                                    {contract.active
                                                                        ? `${t(
                                                                              "contractDetail.table.active"
                                                                          )}`
                                                                        : `${t(
                                                                              "contractDetail.table.inactive"
                                                                          )}`}
                                                                </td>
                                                                <td>
                                                                    {moment(
                                                                        contract.timestamp
                                                                    ).format(
                                                                        "L"
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {/*<TablePagination*/}
                            {/*    totalElements={1}*/}
                            {/*    totalPages={1}*/}
                            {/*    setPageNumber={() => {}}*/}
                            {/*    pageNumber={1}*/}
                            {/*    query={query}*/}
                            {/*    urlStringPart={"noUrlStringPart"}*/}
                            {/*/>*/}
                        </div>
                    ) : (
                        <div className={styles.electronicInvoiceWrapper}>
                            <p
                                className={styles.electronicInvoiceDescription}
                                style={{
                                    paddingLeft: "14px",
                                    paddingRight: "14px",
                                    paddingTop: "40px",
                                    fontSize: "15px",
                                    fontWeight: 500,
                                    color: "#000000",
                                }}
                            >
                                Nu există istoric pentru factura electronică.
                            </p>
                        </div>
                    )}
                </IonCard>
            </div>
        </div>
    );
};

export default DetailPageContractInfo;
