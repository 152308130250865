import React from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import DashboardGraphs from "./DashboardGraphs";
import useFetchCaching from "../../hooks/useFetchCaching";
import { GraphType } from "../../types/requestTypes";

type DashboardGraphsType = {
    users: GraphType[];
    readings: GraphType[];
    payments: GraphType[];
    electronicInvoices: GraphType[];
};

const DashboardPage: React.FC = () => {
    const { data } = useFetchCaching<DashboardGraphsType>(
        "/admin/dashboard/graphs"
    );

    return (
        <IonPage>
            <SidebarLayout
                className="others-page"
                layoutType={"submenu"}
                menuSelected={"item1"}
            >
                <SkeletonList loading={!data} type={"simple"} />

                {data && (
                    <DashboardGraphs
                        dataUtilizatori={data.users}
                        dataFacturiElectronice={data.electronicInvoices}
                        dataPlatiOnline={data.payments}
                        dataAutocitiri={data.readings}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default DashboardPage;
