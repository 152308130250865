import {
    ChangePassword,
    EditProfile,
    RegisterData,
} from "../types/requestTypes";
import {
    EmailAvailability,
    LoginResponse,
    Profile,
    Settings,
} from "../types/responseTypes";
import axiosBasic, { axiosPrivate } from "./customAxios";

class UserActions {
    async checkEmailAvailability(email: string): Promise<EmailAvailability> {
        const response = await axiosPrivate.get(
            `/users/available?email=${email}`
        );
        return response.data;
    }

    async registerAccount(data: RegisterData): Promise<void> {
        const response = await axiosPrivate.post("/users", data);
        return response.data;
    }

    async confirmAccount(confirmationToken: string): Promise<LoginResponse> {
        let response: LoginResponse;

        const webResponse = await axiosBasic.get(
            `/users/confirmation?token=${confirmationToken}`,
            {
                withCredentials: true,
            }
        );
        response = webResponse?.data;
        return response;
    }

    async resendEmailConfirmation(url: string): Promise<boolean> {
        const response = await axiosPrivate.get(url);
        return response.status === 200;
    }

    async confirmNewPassword(
        confirmationToken: string
    ): Promise<LoginResponse> {
        let response: LoginResponse;

        const webResponse = await axiosBasic.get(
            `/users/reset-password/confirmation?token=${confirmationToken}`,
            {
                withCredentials: true,
            }
        );
        response = webResponse?.data;

        return response;
    }

    async getUserProfile(): Promise<Profile> {
        const response = await axiosPrivate.get("/users/profile");
        return response.data;
    }

    async changeUserName(data: EditProfile): Promise<void> {
        await axiosPrivate.patch("/users/profile", data);
    }

    async changeUserPass(data: ChangePassword): Promise<void> {
        await axiosPrivate.post("/users/reset-password", data);
    }

    async changeUserSettings(data: Settings): Promise<void> {
        await axiosPrivate.patch("/users/settings", data);
    }

    async deleteAccount(userId: number): Promise<boolean> {
        const response = await axiosPrivate.delete(`/users/${userId}`);
        return response.status === 200;
    }
}

export const userActions = new UserActions();
