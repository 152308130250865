import { IonLabel, IonPage } from "@ionic/react";
import React from "react";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import CookiesContentRO from "./CookiesContentRO";

export const CookiesPage: React.FC = () => {
    return (
        <IonPage>
            <SidebarLayout layoutType={"back"}>
                <IonLabel className="ag-h2 black">Politică de Cookies</IonLabel>
                <CookiesContentRO />
            </SidebarLayout>
        </IonPage>
    );
};
