const CookiesContentRO: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <h5>Cookies și identificare unică</h5>

      <p className="help-last-update">
        Ultima modificare: 5 aprilie 2018
      </p>

      <p className="indentation">
        Sunteți de acord că Aqmeter are dreptul să folosească orice comentarii, informații sau idei conținute în orice
        fel
        de comunicare pe care dumneavoastră o trimiteți către Set Mobile sau partenerii acestuia fără nicio compensație,
        recunoaștere sau plată către dumeavoastră, în orice scop, inclusiv, dar fără a se limita la elaborarea,
        producția,
        produsele și serviciile de marketing și crearea sau îmbunătățirea aplicației Aqmeter sau altor produse și
        servicii. Pentru a vă oferi o experiență de navigare cât mai bună pe site-ul aqmeter.com, folosim o facilitate a
        browserului pentru internet numită "cookie". Cookies sunt fișiere de dimensiuni reduse pe care browserul le
        încarcă pe
        hard-disk-ul utilizatorului. Aceste cookies sunt folosite pentru a memora numele utilizatorilor, parolele și
        preferințele, pentru a monitoriza navigarea pe site, și pentru a personaliza paginile în funcție de vizitator.
        Aveți posibilitatea de a seta browserul astfel încât să respingă cookies. Daca alegeți această opțiune, este
        posibil să apară o influență negativă privind utilizarea platformei Aqmeter.
      </p>
      <p className="indentation">
        Pentru Platforma noastră utilizăm cookies, care vă oferă o experiență de navigare mai rapidă, în scopuri
        statistice și de îmbunătățire a Platformei. Utilizarea unui cookie nu este în niciun fel legată de informaţiile
        de
        identificare personală în timpul unei vizite pe Platformă.
      </p>
      <p className="indentation">
        De asemenea, folosim Google Analytics pentru a analiza informațiile colectate de la vizitatorii Platformei.
        Aceste
        informații permit Aqmeter să înțeleagă mai bine utilizatorii Platformei și modul în care este utilizată. În
        acest
        tip de raportare nu sunt incluse informații personale de identificare.
      </p>
      <p className="indentation">
        Noi nu folosim cookies pentru a colecta Date cu caracter personal și nicio tehnologie care face legătura între
        informațiile colectate prin cookies și Datele utilizatorilor Platformei.
      </p>
    </div>
  );
};

export default CookiesContentRO;
