import React, { useState } from "react";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import useFetchCaching from "../../hooks/useFetchCaching";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import useQuery from "../../hooks/useQuery";
import TableReadings from "./TableReadings";
import { ReadingsAdmin } from "../../types/responseTypes";
const ReadingsPage: React.FC = () => {
    const query = useQuery();
    const [pageNumber, setPageNumber] = useState<number>(
        parseInt(query.get("page"))
    );

    const urlWithoutSearchTerm: string = `/admin/readings?page=${query.get(
        "page"
    )}`;

    const urlWithSearchTerm: string = `/admin/readings?page=${query.get(
        "page"
    )}&searchTerm=${query.get("searchTerm")}`;

    const urlWithoutSearchTermSorted: string = `/admin/readings?page=${query.get(
        "page"
    )}&sortIndex=${query.get("sortIndex")}&sortDirection=${query.get(
        "sortDirection"
    )}`;

    const urlWithSearchTermSorted: string = `/admin/readings?page=${query.get(
        "page"
    )}&searchTerm=${query.get("searchTerm")}&sortIndex=${query.get(
        "sortIndex"
    )}&sortDirection=${query.get("sortDirection")}`;

    const { data: readingsData } = useFetchCaching<ReadingsAdmin>(
        query.get("sortIndex") === null
            ? query.get("searchTerm") === null
                ? urlWithoutSearchTerm
                : urlWithSearchTerm
            : query.get("searchTerm") === null
            ? urlWithoutSearchTermSorted
            : urlWithSearchTermSorted
    );

    return (
        <IonPage>
            <SidebarLayout className="others-page" layoutType={"submenu"}>
                <SkeletonList
                    loading={!readingsData}
                    type={"simple"}
                    skeletonWidth={"1050px"}
                />

                {readingsData && (
                    <TableReadings
                        key={query.get("page")}
                        readingsData={readingsData}
                        setPageNumber={setPageNumber}
                        pageNumber={pageNumber}
                        query={query}
                    />
                )}
            </SidebarLayout>
        </IonPage>
    );
};

export default ReadingsPage;
