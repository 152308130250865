import React, {
    MutableRefObject,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {
    IonButton,
    IonCard,
    IonChip,
    IonIcon,
    IonInput,
    IonLabel,
    IonToggle,
} from "@ionic/react";
import { ContractDetail } from "../../../types/requestTypes";
import { closeCircleOutline } from "ionicons/icons";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import { axiosPrivate } from "../../../api/customAxios";
import { useParams } from "react-router-dom";
import Card from "../../../components/UI/Card";
import AuthContext from "../../../context/AuthContext";

import styles from "./DetailPage.module.scss";

type DetailContractCardProps = {
    data: ContractDetail;
    layoutCtx: boolean;
};

const ContractDetails: React.FC<DetailContractCardProps> = ({
    data,
}: DetailContractCardProps) => {
    const { t } = useTranslation();
    const { userCompanies } = useContext(AuthContext);

    const { contractId } = useParams<{ contractId: string }>();
    const toggleRef: MutableRefObject<HTMLIonToggleElement> =
        useRef<HTMLIonToggleElement>(null);

    const [electronicInvoice, setElectronicInvoice] = useState<boolean>(
        data.hasElectronicInvoice
    );
    const [phoneNumber, setPhoneNumber] = useState<string>(
        data.contractPhoneField
    );

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.detail.value;
        const numericInput = inputValue.replace(/\D/g, ""); // Remove non-numeric characters

        // Update the input field with the numeric input
        setPhoneNumber(numericInput);
    };

    const handlePhoneNumberSubmit = () => {
        if (phoneNumber === "")
            return toastAdd(`${t("toast.editError")}`, "error");

        axiosPrivate
            .patch(`/admin/contracts/${data.id}`, {
                electronicInvoiceEmails: [...emailAddresses, newEmailAddress],
                phone: phoneNumber,
                email: data.contractEmailField,
            })
            .then(() => {
                toastAdd(`${t("toast.editSuccess")}`, "success");
            })
            .catch(() => {
                toastAdd(`${t("toast.editError")}`, "error");
            });
    };

    type ElectronicInvoiceEmails = {
        email: string;
        confirmedEmail: boolean;
        contractId: string;
    };

    const emails: string[] = Object.keys(data.groupedContractEmails);
    const rowSpans: { [key: string]: number } = {};
    emails.forEach((email: string) => {
        rowSpans[email] = data.groupedContractEmails[email].length;
    });

    const [emailAddresses, setEmailAddresses] = useState<
        ElectronicInvoiceEmails[]
    >([]);
    const [newEmailAddress, setNewEmailAddress] =
        useState<ElectronicInvoiceEmails>({
            email: "",
            confirmedEmail: false,
            contractId: "",
        });

    useEffect(() => {
        const emails: string[] = Object.keys(data.groupedContractEmails);
        const emailsLength: number = emails.length;
        const listOfEmails: ElectronicInvoiceEmails[] = [];

        if (data.groupedContractEmails !== null) {
            for (let i = 0; i < emailsLength; i++) {
                const singleEmail: string = emails[i];
                const emailData: any = data.groupedContractEmails[singleEmail];

                if (emailData[0].active === true) {
                    listOfEmails.push({
                        email: singleEmail,
                        confirmedEmail: false,
                        contractId: emailData[0].contractId,
                    });
                }
            }
            setEmailAddresses(listOfEmails);
        }
    }, [data]);

    const handleNewEmailAddressChange = (event) => {
        if (event.target.value !== "")
            setNewEmailAddress({
                email: event.target.value,
                confirmedEmail: false,
                contractId: contractId,
            });
    };

    const handleSaveButtonClick = (e) => {
        e.preventDefault();

        if (newEmailAddress.email === "") return;
        const updatedObject = {
            electronicInvoiceEmails: [...emailAddresses, newEmailAddress],
            email: data.contractEmailField,
            phone: phoneNumber,
        };

        axiosPrivate
            .patch(`/contracts/${contractId}`, updatedObject)
            .then(() => {
                setEmailAddresses(updatedObject.electronicInvoiceEmails);
                setNewEmailAddress({
                    email: "",
                    confirmedEmail: false,
                    contractId: "",
                });
                toastAdd(`${t("toast.editSuccess")}`, "success");
            })
            .catch(() => {
                toastAdd(`${t("toast.editError")}`, "error");
            });
    };

    const handeleFormSubmit = (e) => {
        e.preventDefault();

        const updatedObject = {
            electronicInvoiceEmails: [...emailAddresses, newEmailAddress],
            email: data.contractEmailField,
            phone: data.contractPhoneField,
        };

        axiosPrivate
            .patch(`/contracts/${contractId}`, updatedObject)
            .then(() => {
                setEmailAddresses(updatedObject.electronicInvoiceEmails);
                setNewEmailAddress({
                    email: "",
                    confirmedEmail: false,
                    contractId: "",
                });

                toastAdd(`${t("toast.editSuccess")}`, "success");
            })
            .catch(() => {
                toastAdd(`${t("toast.editError")}`, "error");
            });
    };

    const handleRemoveEmail = (email: string) => {
        const updatedObject = {
            electronicInvoiceEmails: emailAddresses.filter(
                (item) => item.email !== email
            ),
            email: data.contractEmailField,
            phone: data.contractPhoneField,
        };

        axiosPrivate
            .patch(`/contracts/${contractId}`, updatedObject)
            .then(() => {
                setEmailAddresses(updatedObject.electronicInvoiceEmails);
                setNewEmailAddress({
                    email: "",
                    confirmedEmail: false,
                    contractId: "",
                });
                toastAdd(`${t("toast.editSuccess")}`, "success");
            })
            .catch(() => {
                toastAdd(`${t("toast.editError")}`, "error");
            });
    };

    const handleToggleChange = () => {
        setElectronicInvoice(!electronicInvoice);

        if (electronicInvoice === true) {
            const updatedObject = {
                electronicInvoiceEmails: [],
                email: data.contractEmailField,
                phone: data.contractPhoneField,
            };

            axiosPrivate
                .patch(`/contracts/${contractId}`, updatedObject)
                .then(() => {
                    setEmailAddresses(updatedObject.electronicInvoiceEmails);
                    toastAdd(`${t("toast.editSuccess")}`, "success");
                });
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <IonCard className={styles.contractCard}>
                <h1 className={styles.title}>{t("contracts.details.title")}</h1>

                <div className={styles.contractDetailWrapper}>
                    <>
                        <Card className={styles.antet}>
                            <div className={styles.mainWrapperAntet}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        gap: "2px",
                                    }}
                                >
                                    <div
                                        className={
                                            styles.electronicInvoiceWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                styles.electronicInvoiceDescription
                                            }
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {t("contracts.details.name")}
                                        </p>
                                        <p
                                            className={styles.invoiceNumber}
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {data.name}
                                        </p>
                                    </div>

                                    <div
                                        className={
                                            styles.electronicInvoiceWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                styles.electronicInvoiceDescription
                                            }
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {t("contracts.details.telephone")}
                                        </p>
                                        <p
                                            className={styles.invoiceNumber}
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {userCompanies[0] !== 6 ? (
                                                data.contractPhoneField ??
                                                t("noData")
                                            ) : (
                                                <IonInput
                                                    className={styles.input}
                                                    type="tel"
                                                    inputmode="tel"
                                                    pattern="/\D/g"
                                                    value={phoneNumber}
                                                    maxlength={10}
                                                    onIonChange={
                                                        handlePhoneNumberChange
                                                    }
                                                    required
                                                />
                                            )}
                                        </p>
                                    </div>

                                    <div
                                        className={
                                            styles.electronicInvoiceWrapper
                                        }
                                        style={{
                                            alignItems: "flex-start",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <p
                                            className={
                                                styles.electronicInvoiceDescription
                                            }
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {t("contracts.details.email")}
                                        </p>
                                        <p
                                            className={styles.invoiceNumber}
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                maxWidth: "70%",
                                            }}
                                        >
                                            {data.contractEmailField ??
                                                t("noData")}
                                        </p>
                                    </div>

                                    <div
                                        className={
                                            styles.electronicInvoiceWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                styles.electronicInvoiceDescription
                                            }
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {t("contracts.details.number")}
                                        </p>
                                        <p
                                            className={styles.invoiceNumber}
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {data.number}
                                        </p>
                                    </div>

                                    <div
                                        className={
                                            styles.electronicInvoiceWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                styles.electronicInvoiceDescription
                                            }
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {t("contracts.details.data")}
                                        </p>
                                        <p
                                            className={styles.starDate}
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            {moment(data.startDate).format("L")}
                                        </p>
                                    </div>

                                    <div
                                        className={
                                            styles.electronicInvoiceWrapper
                                        }
                                    >
                                        <p
                                            className={
                                                styles.electronicInvoiceDescription
                                            }
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {t("contracts.details.address")}
                                        </p>
                                        <p
                                            className={styles.invoiceNumber}
                                            style={{
                                                marginTop: "4px",
                                                marginBottom: "4px",
                                            }}
                                        >
                                            {data.address.county},{" "}
                                            {data.address.city}
                                            {t(
                                                "users.detail.contracts.address.street"
                                            ) + data.address.street}
                                            {data.address.number
                                                ? t(
                                                      "users.detail.contracts.address.number"
                                                  ) + data.address.number
                                                : null}
                                            {data.address.building
                                                ? t(
                                                      "users.detail.contracts.address.block"
                                                  ) + data.address.number
                                                : null}
                                            {data.address.entrance
                                                ? t(
                                                      "users.detail.contracts.address.sc"
                                                  ) + data.address.entrance
                                                : null}
                                            {data.address.floor
                                                ? t(
                                                      "users.detail.contracts.address.et"
                                                  ) + data.address.floor
                                                : null}
                                            {data.address.ap
                                                ? t(
                                                      "users.detail.contracts.address.apartment"
                                                  ) + data.address.ap
                                                : null}
                                        </p>
                                    </div>
                                    {userCompanies[0] === 6 && (
                                        <IonButton
                                            onClick={handlePhoneNumberSubmit}
                                        >
                                            {t("contractDetail.saveButton")}
                                        </IonButton>
                                    )}
                                </div>
                            </div>
                        </Card>

                        <div
                            className={styles.contractInvoiceWrapper}
                            style={{ marginBottom: 20 }}
                        >
                            <div>
                                <div className={styles.invoiceTitle}>
                                    {t("contracts.details.invoice.last")}
                                </div>
                                <div className={styles.invoiceCardWrapper}>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.number"
                                            )}
                                        </div>
                                        <div className={styles.invoiceNumber}>
                                            {data.lastAvailableInvoice
                                                ? data.lastAvailableInvoice
                                                      .serial +
                                                  "/" +
                                                  data.lastAvailableInvoice
                                                      .number
                                                : t("noData")}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.date"
                                            )}
                                        </div>
                                        <div className={styles.invoiceValues}>
                                            {data.lastAvailableInvoice
                                                ? moment(
                                                      data.lastAvailableInvoice
                                                          .invoiceDate
                                                  ).format("L")
                                                : t("noData")}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                        style={{
                                            paddingBottom: "15px",
                                        }}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.value"
                                            )}
                                        </div>
                                        <div className={styles.invoiceValues}>
                                            {data.lastAvailableInvoice
                                                ?.totalAmount
                                                ? `${parseFloat(
                                                      data.lastAvailableInvoice
                                                          .totalAmount
                                                  ).toLocaleString("ro-RO", {
                                                      minimumFractionDigits: 2,
                                                  })} ${t(
                                                      "contracts.details.invoice.currency"
                                                  )}`
                                                : t("noData")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={styles.invoiceTitle}>
                                    {t("contracts.details.invoice.lastPayed")}
                                </div>
                                <div
                                    className={styles.invoiceCardWrapper}
                                    style={{
                                        height: "188px",
                                    }}
                                >
                                    <div
                                        className={styles.invoiceContentWrapper}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.number"
                                            )}
                                        </div>
                                        <div className={styles.invoiceNumber}>
                                            {data.lastPayedInvoice
                                                ? data.lastPayedInvoice.serial +
                                                  "/" +
                                                  data.lastPayedInvoice.number
                                                : t("noData")}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.lastPayed.date"
                                            )}
                                        </div>
                                        <div className={styles.invoiceValues}>
                                            {data.lastPayedInvoice
                                                ? moment(
                                                      data.lastPayedInvoice
                                                          .invoiceDate
                                                  ).format("L")
                                                : t("noData")}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.value"
                                            )}
                                        </div>
                                        <div className={styles.invoiceValues}>
                                            {data.lastPayedInvoice?.totalAmount
                                                ? `${parseFloat(
                                                      data.lastPayedInvoice
                                                          .totalAmount
                                                  ).toLocaleString("ro-RO", {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  })} ${t(
                                                      "contracts.details.invoice.currency"
                                                  )}`
                                                : t("noData")}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.lastPayed.payentDate"
                                            )}
                                        </div>
                                        <div className={styles.invoiceValues}>
                                            {data.lastPayedInvoice?.paymentDate
                                                ? moment(
                                                      data.lastPayedInvoice
                                                          .paymentDate
                                                  ).format("L")
                                                : t("noData")}
                                        </div>
                                    </div>
                                    <div
                                        className={styles.invoiceContentWrapper}
                                        style={{
                                            paddingBottom: "15px",
                                        }}
                                    >
                                        <div
                                            className={
                                                styles.invoiceDescription
                                            }
                                        >
                                            {t(
                                                "contracts.details.invoice.lastPayed.payentDocument"
                                            )}
                                        </div>
                                        <div className={styles.invoiceValues}>
                                            {data.lastPayedInvoice
                                                ?.paymentDocument
                                                ? data.lastPayedInvoice
                                                      .paymentDocument
                                                : t("noData")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </IonCard>

            <IonCard className={styles.contractSelectCard}>
                <div className={styles.userInfoWrapper}>
                    <h1
                        className={styles.title}
                        style={{
                            paddingBottom: "0",
                        }}
                    >
                        {t("contracts.details.elInvoice")}
                    </h1>

                    <div className={styles.profileMainWrapper}></div>

                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "20px",
                                paddingRight: "22px",
                            }}
                        >
                            <div
                                className={`${styles.cardSubtitle}`}
                                style={{
                                    paddingLeft: "22px",
                                    paddingBottom: "0",
                                }}
                            >
                                {t("contracts.details.elInvoice")}
                            </div>

                            <IonToggle
                                ref={toggleRef}
                                onClick={handleToggleChange}
                                checked={electronicInvoice}
                                color={electronicInvoice ? "success" : "danger"}
                            />
                        </div>
                        <form onSubmit={handeleFormSubmit}>
                            {electronicInvoice ? (
                                <>
                                    <div className={styles.inputContent}>
                                        <IonLabel
                                            className={styles.descriptionText}
                                            style={{
                                                display: "flex",
                                                paddingTop: "6px",
                                                paddingLeft: "22px",
                                                paddingRight: "22px",
                                            }}
                                        >
                                            {t(
                                                "contractDetail.electronicInvoice.email"
                                            )}{" "}
                                        </IonLabel>

                                        <div
                                            style={{
                                                display: "flex",
                                                paddingTop: "6px",
                                                paddingLeft: "22px",
                                                paddingRight: "22px",
                                                width: "95%",
                                                height: "45px",
                                            }}
                                        >
                                            <IonInput
                                                className={styles.input}
                                                placeholder={t(
                                                    "contractDetail.electronicInvoice.placeholder"
                                                )}
                                                type="email"
                                                onIonChange={
                                                    handleNewEmailAddressChange
                                                }
                                                value={newEmailAddress.email}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.inputContent}>
                                        <div
                                            style={{
                                                paddingTop: "4px",
                                                paddingLeft: "16px",
                                                paddingRight: "24px",
                                            }}
                                        >
                                            {data.groupedContractEmails !==
                                                null &&
                                                emailAddresses.map((email) => (
                                                    <IonChip
                                                        key={email.email}
                                                        color="primary"
                                                    >
                                                        <div
                                                            className={
                                                                styles.chip
                                                            }
                                                        >
                                                            <IonLabel>
                                                                {email.email}
                                                            </IonLabel>
                                                            <div
                                                                onClick={() =>
                                                                    handleRemoveEmail(
                                                                        email.email
                                                                    )
                                                                }
                                                                style={{
                                                                    paddingTop:
                                                                        "4px",
                                                                }}
                                                            >
                                                                <IonIcon
                                                                    icon={
                                                                        closeCircleOutline
                                                                    }
                                                                    color="danger"
                                                                />
                                                            </div>
                                                        </div>
                                                    </IonChip>
                                                ))}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            <div
                                className={styles.inputContent}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "12px",
                                    paddingLeft: "22px",
                                    paddingRight: "22px",
                                }}
                            >
                                <IonButton
                                    disabled={!toggleRef.current?.checked}
                                    onClick={handleSaveButtonClick}
                                >
                                    {t("contractDetail.saveButton")}
                                </IonButton>
                            </div>
                        </form>
                    </div>
                </div>
            </IonCard>
        </div>
    );
};

export default ContractDetails;
