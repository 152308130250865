import {
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList, IonRow,
    IonSkeletonText,
    IonThumbnail,
} from "@ionic/react";
import {sadOutline} from "ionicons/icons";
import React from "react";
import Card from "../UI/Card";
import styles from "./SkeletonList.module.scss";

interface SkeletonListProps {
    loading: boolean;
    error?: any;
    type?: "simple" | "list" | "circle";
    skeletonWidth?: string;
}

const SkeletonList: React.FC<SkeletonListProps> = ({
                                                       loading,
                                                       error,
                                                       type,
                                                       skeletonWidth
                                                   }) => {
    return (
        <>
            {error ? (
                <Card data-testid="skeleton-list">
                    <div className={styles.error}>
                        <IonIcon icon={sadOutline}/>
                        <p>{"suspense.error"}</p>
                    </div>
                </Card>
            ) : loading ? (
                type === "simple" ? (
                    <IonList style={{display: 'flex', width: skeletonWidth}}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <IonLabel>
                                        <IonSkeletonText animated={true} style={{width: "270px", height: "45px"}}/>
                                        <IonSkeletonText animated={true} style={{width: "100px", height: "25px"}}/>
                                    </IonLabel>
                                </IonCol>
                                <IonCol size={"3"}>
                                    <IonLabel>
                                        <IonSkeletonText animated={true} style={{width: "300px", height: "60px"}}/>
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "35px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonSkeletonText animated={true} style={{height: "50px"}}/>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonList>
                ) : type === "circle" ? (
                    <IonItem lines="none" className={styles.circle}>
                        <IonLabel>
                            <IonSkeletonText animated={true}/>
                            <IonSkeletonText animated={true}/>
                            <IonSkeletonText animated={true}/>
                            <IonSkeletonText animated={true}/>
                        </IonLabel>
                    </IonItem>
                ) : (
                    <IonList className={styles.list}>
                        <IonItem>
                            <IonThumbnail slot="start">
                                <IonSkeletonText animated={true}/>
                            </IonThumbnail>
                            <IonLabel>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                            </IonLabel>
                        </IonItem>
                        <IonItem>
                            <IonThumbnail slot="start">
                                <IonSkeletonText animated={true}/>
                            </IonThumbnail>
                            <IonLabel>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                            </IonLabel>
                        </IonItem>
                        <IonItem lines="none">
                            <IonThumbnail slot="start">
                                <IonSkeletonText animated={true}/>
                            </IonThumbnail>
                            <IonLabel>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                                <IonSkeletonText animated={true}/>
                            </IonLabel>
                        </IonItem>
                    </IonList>
                )
            ) : (
                ""
            )}
        </>
    );
};

export default SkeletonList;
