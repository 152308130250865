import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import addressService from "../services/AddressService";
import { CanceledError } from "axios";
import createHttpService from "../services/HttpService";

const useAddresses = () => {
    const { accessTokenCtx } = useContext(AuthContext);

    const [selectedCounties, setSelectedCounties] = useState<string[]>([]);

    const [allCities, setAllCities] = useState<string[]>([]);
    const [selectedCities, setSelectedCities] = useState<string[]>([]);

    const [allStreets, setAllStreets] = useState<string[]>([]);
    const [selectedStreets, setSelectedStreets] = useState<string[]>([]);

    const [allNumbers, setAllNumbers] = useState<string[]>([]);
    const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);

    const [sendChannels, setSendChannels] = useState({
        isEmailSelected: false,
        isSmsSelected: false,
        isPushSelected: false,
    });

    const [contractObject, setContractObject] = useState<{
        usersWithAqmeterContracts: number;
        totalContracts: number;
        emailDestinations: number;
        smsDestinations: number;
        pushDestinations: number;
    }>();

    function countContracts() {
        const { request: ContractsObjectPromise, cancelHandler: canceler } =
            createHttpService.postCount(
                selectedCounties.length != 0 ? selectedCounties : null,
                selectedCities.length != 0 ? selectedCities : null,
                selectedStreets.length != 0 ? selectedStreets : null,
                selectedNumbers.length != 0 ? selectedNumbers : null,
                sendChannels.isEmailSelected,
                sendChannels.isSmsSelected,
                sendChannels.isPushSelected,
                accessTokenCtx
            );
        ContractsObjectPromise.then(({ data }) =>
            setContractObject(data)
        ).catch((err) => {
            if (err instanceof CanceledError) return;
        });
        return canceler;
    }

    useEffect(() => {
        if (
            (selectedCounties &&
                selectedCounties.length &&
                selectedCounties[0] === undefined) ||
            !selectedCounties
        )
            return;

        const allCountiesSelected = selectedCounties.length === 0;
        if (allCountiesSelected) {
            setAllCities([]);
            setSelectedCities([]);
            setAllStreets([]);
            setSelectedStreets([]);
            setAllNumbers([]);
            setSelectedNumbers([]);

            return;
        }

        const { request: citiesPromise, cancelHandler } =
            addressService.getCities(selectedCounties, accessTokenCtx);

        citiesPromise
            .then(({ data: cities }) => {
                setAllCities(cities);
            })
            .catch((err) => {
                if (err instanceof CanceledError) return;
            });

        setSelectedCities([]);
        setAllStreets([]);
        setSelectedStreets([]);
        setAllNumbers([]);
        setSelectedNumbers([]);

        return () => {
            cancelHandler();
        };
    }, [selectedCounties]);

    useEffect(() => {
        if (
            (selectedCities &&
                selectedCities.length &&
                selectedCities[0] === undefined) ||
            !selectedCities
        )
            return;

        const allCitiesSelected = selectedCities.length === 0;
        if (allCitiesSelected) {
            setAllStreets([]);
            setSelectedStreets([]);
            setAllNumbers([]);
            setSelectedNumbers([]);

            return;
        }

        const { request: streetsPromise, cancelHandler } =
            addressService.getStreets(
                selectedCounties,
                selectedCities,
                accessTokenCtx
            );

        streetsPromise
            .then(({ data: streets }) => {
                setAllStreets(streets);
            })
            .catch((err) => {
                if (err instanceof CanceledError) return;
            });

        setSelectedStreets([]);
        setAllNumbers([]);
        setSelectedNumbers([]);

        return () => {
            cancelHandler();
        };
    }, [selectedCities]);

    useEffect(() => {
        if (
            (selectedStreets &&
                selectedStreets.length &&
                selectedStreets[0] === undefined) ||
            !selectedStreets
        )
            return;

        const allStreetsSelected = selectedStreets.length === 0;
        if (allStreetsSelected) {
            setAllNumbers([]);
            setSelectedNumbers([]);
            return;
        }

        const { request: numbersPromise, cancelHandler } =
            addressService.getNumbers(
                selectedCounties,
                selectedCities,
                selectedStreets,
                accessTokenCtx
            );

        numbersPromise
            .then(({ data: numbers }) => {
                setAllNumbers(numbers);
            })
            .catch((err) => {
                if (err instanceof CanceledError) return;
            });

        setSelectedNumbers([]);

        return () => {
            cancelHandler();
        };
    }, [selectedStreets]);

    useEffect(() => {
        const cancelCountContracts = countContracts();
        return () => {
            cancelCountContracts();
        };

        //TODO: se anulează request-ul de count la randări multiple, de evitat folosire useEffect.
    }, [
        sendChannels.isSmsSelected,
        sendChannels.isPushSelected,
        sendChannels.isEmailSelected,
        selectedCounties,
        selectedCities,
        selectedStreets,
        selectedNumbers,
    ]);

    return {
        selectedCounties,
        setSelectedCounties,
        allCities,
        setAllCities,
        selectedCities,
        setSelectedCities,
        allStreets,
        setAllStreets,
        setSelectedStreets,
        selectedStreets,
        allNumbers,
        setAllNumbers,
        setSelectedNumbers,
        selectedNumbers,
        contractObject,
        sendChannels,
        setSendChannels,
    };
};

export default useAddresses;
