import {PasswordValidationType} from '../types/otherTypes';

export const regex = {
  specialChar: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/,
  lowerChar: /[a-z]/,
  upperChar: /[A-Z]/,
  digit: /[\d]/
};

export const passMinLength = 8;

export const initialPasswordValidationState: PasswordValidationType = {
  minLength: false,
  specialChar: false,
  lowerChar: false,
  upperChar: false,
  digit: false,
}