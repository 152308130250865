import React, { useEffect, useMemo, useState } from "react";
import {
    ContractDemand,
    ContractDemandPageRequest,
    ContractDemandStatus,
} from "../../../types/requestTypes";
import { aqmeterActions } from "../../../api/AqmeterActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import EditStatus from "../EditContractDemands/EditStatus";
import { useTranslation } from "react-i18next";
import TablePagination from "../../../components/Pagination/TablePagination";
import TableHeader from "../../../components/UI/TableHeader";

import styles from "../ContractDemands.module.scss";

interface TabelProps {
    contractDemands: ContractDemandPageRequest;
    setPageNumber: (pageNumber: number) => void;
    pageNumber: number;
    query: URLSearchParams;
}

const TabelContractDemands: React.FC<TabelProps> = ({
    contractDemands,
    setPageNumber,
    pageNumber,
    query,
}) => {
    const { t } = useTranslation();

    const [status, setStatus] = useState<ContractDemandStatus>(null);
    const [requestStatus, setRequestStatus] = useState<number>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const totalPages = useMemo<number>(
        () => contractDemands.totalPages,
        [contractDemands]
    );
    const totalElements = useMemo<number>(
        () => contractDemands.totalElements,
        [contractDemands]
    );

    const [dataList, setDataList] = React.useState<ContractDemand[]>(
        contractDemands.content
    );

    const handleChangeStatus = (ev: Event, dataId) => {
        try {
            const target = ev.target as HTMLIonSelectElement;

            const newDataList = dataList.map((item) => {
                if (item.id === dataId) {
                    return {
                        ...item,
                        status: target.value,
                    };
                }
                return item;
            });

            setDataList(newDataList);

            setStatus({
                id: dataId,
                status: target.value,
            });
            toastAdd(t("contractDemands.toast.success"), "success");
        } catch (error) {
            toastAdd(t("contractDemands.toast.error"), "error");
        }
    };

    useEffect(() => {
        aqmeterActions.updateStatusOfContractRequest(status).then((res) => {
            setRequestStatus(res);
        });

        if (requestStatus === 200 || requestStatus === undefined) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [status]);

    return (
        <>
            <table className={styles.table}>
                <TableHeader
                    query={query}
                    totalElements={totalElements}
                    titleHeader={t("contractDemands.title")}
                    urlStringPart={"contract-demands"}
                    subtitleHeaderWithNumberOfElements={t(
                        "totalSubtitle.requests",
                        { requestCount: totalElements }
                    )}
                />

                <thead className={styles.headerWrapper}>
                    <tr>
                        <th>{t("contractDemands.table.header.name")}</th>
                        <th>{t("contractDemands.table.header.address")}</th>
                        <th>{t("contractDemands.table.header.typeClient")}</th>
                        <th>{t("contractDemands.table.header.typeRequest")}</th>
                        <th>
                            {t("contractDemands.table.header.typeContract")}
                        </th>
                        <th>{t("contractDemands.table.header.status")}</th>
                        <th>{t("contractDemands.table.header.document")}</th>
                    </tr>
                </thead>
                <tbody className={styles.bodyWrapper}>
                    {dataList.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    <p>
                                        {item.firstName} {item.lastName}
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        {item.county} {item.city} {item.street}
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        {item.clientType
                                            ? item.clientType
                                            : t("noData")}
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        {item.target
                                            ? item.target
                                            : t("noData")}
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        {item.propertyOwnership
                                            ? item.propertyOwnership
                                            : t("noData")}
                                    </p>
                                </td>
                                <td>
                                    <EditStatus
                                        item={item}
                                        handleChangeStatus={handleChangeStatus}
                                        isLoading={isLoading}
                                    />
                                </td>
                                <td>
                                    {!item.files.length === false
                                        ? item.files.map((item) => (
                                              <div key={item.id}>
                                                  <a
                                                      key={item.id}
                                                      className={
                                                          styles.download
                                                      }
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      href={item.url}
                                                  >
                                                      {item.label}
                                                  </a>
                                              </div>
                                          ))
                                        : t("contractDemands.noDocuments")}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <TablePagination
                totalElements={totalElements}
                totalPages={totalPages}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                query={query}
                urlStringPart={"contract-demands"}
            />
        </>
    );
};

export default TabelContractDemands;
