import React from "react";
import { BiCookie } from "react-icons/bi";
import { BsQuestionCircle, BsShieldLock } from "react-icons/bs";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.scss";

interface Props {
    setSelected: (selected: string) => void;
}

const Footer: React.FC<Props> = ({ setSelected }) => {
    const { t } = useTranslation();
    return (
        <footer className={styles.footer}>
            <Link
                onClick={() => setSelected("")}
                className="icon-link"
                to="/terms"
            >
                <IoDocumentTextOutline
                    title={t("help.title.term")}
                    className={styles.icon}
                />
            </Link>
            <Link
                onClick={() => setSelected("")}
                className="icon-link"
                to="/privacy"
            >
                <BsShieldLock
                    title={t("help.title.privacy")}
                    className={styles.icon}
                />
            </Link>
            <Link
                onClick={() => setSelected("")}
                className="icon-link"
                to="/cookies"
            >
                <BiCookie
                    title={t("help.title.cookies")}
                    className={styles.icon}
                />
            </Link>
            <Link
                onClick={() => setSelected("")}
                className="icon-link"
                to="/faq"
            >
                <BsQuestionCircle
                    title={t("help.title.faq")}
                    className={styles.icon}
                />
            </Link>
        </footer>
    );
};

export default Footer;
