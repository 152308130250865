import {
    IonApp,
    IonItem,
    IonLabel,
    IonSpinner,
    setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useContext, useEffect, useMemo, useState } from "react";
import AuthContext from "./context/AuthContext";
import useRefreshToken from "./hooks/useRefreshToken";
import AuthenticatedPages from "./pages/0.Authentication/AuthorizedPages";
import UnauthenticatedPages from "./pages/0.Authentication/UnauthorizedPages";
import UserContext from "./context/UserContext";
import { AppStatus } from "./types/responseTypes";
import { aqmeterActions } from "./api/AqmeterActions";
import { useSettings } from "./context/SettingsContext";
import moment from "moment";
import "moment/locale/ro";
/* Theme variables */
import "./App.scss";

setupIonicReact({
        mode: 'md',
    });

const App: React.FC = () => {
    const { settings } = useSettings();
    const { updateVersionCtx } = useContext(AuthContext);

    const refresh = useRefreshToken();
    const { isLoggedIn, accessTokenCtx, logoutCtx } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>();
    const [checkingAuth, setCheckingAuth] = useState<boolean>(true);
    const { updateCompaniesCtx } = useContext(UserContext);
    const [status, setStatus] = useState<AppStatus>({
        health: null,
        version: null,
    });
    const [fcmToken] = useState<string>("");
    moment.locale("ro");

    const appIsDown = useMemo(() => status?.health === "DOWN", [status]);

    useEffect(() => {
        aqmeterActions
            .getAppStatus()
            .then((status) => {
                setStatus(status);
                updateVersionCtx(status.version);
            })
            .catch((err) => {
                // consider app is DOWN when backend returns error or is unavailable
                if (err.message === "Request aborted") return;
                setStatus({ health: "DOWN", version: settings.version });
            });

        aqmeterActions
            .getCompanies()
            .then((companies) => updateCompaniesCtx(companies));
    }, []);

    useEffect(() => {
        if (!accessTokenCtx && !appIsDown) {
            setIsLoading(true);
            // if the app is UP and UP TO DATE, check if there is a valid refresh token,
            // then perform a refresh request to get a new access token and keep the user logged in
            refresh({ fcmToken })
                .then()
                .catch((err: any) => {
                    // there is no valid refresh token, so the user needs to be logged out
                    logoutCtx();
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        setCheckingAuth(false);
    }, []);

    const [selected, setSelected] = useState<string>("");

    return (
        <div>
            {isLoading || checkingAuth ? (
                <div className="main-spinner">
                    <IonItem lines="none">
                        <IonSpinner slot="start" color="primary" />
                        <IonLabel color="medium">"Loading..."</IonLabel>
                    </IonItem>
                </div>
            ) : (
                <IonApp className={isLoggedIn ? "aqmeter-ionApp" : ""}>
                    <IonReactRouter>
                        {isLoggedIn ? (
                            <AuthenticatedPages
                                selected={selected}
                                setSelected={setSelected}
                            />
                        ) : (
                            <UnauthenticatedPages />
                        )}
                    </IonReactRouter>
                </IonApp>
            )}
        </div>
    );
};

export default App;
