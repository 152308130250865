import React, { useState } from "react";
import {
    Area,
    AreaChart,
    CartesianGrid,
    LabelList,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { GraphType } from "../../types/requestTypes";
import { useTranslation } from "react-i18next";
import moment from "moment";

import styles from "./Dashboard.module.scss";

interface DashboardGraphProps {
    data: GraphType[];
    title: string;
    barColor: string;
    tooltipText: string;
}

const AreaCharts: React.FC<DashboardGraphProps> = ({
    data: initialData,
    title,
    barColor,
    tooltipText,
}) => {
    const { t } = useTranslation();

    initialData.sort((a, b) => {
        return moment(a.date).diff(moment(b.date));
    });

    const [startDate, setStartDate] = useState<string>(initialData[0].date);
    const [endDate, setEndDate] = useState<string>(
        initialData[initialData.length - 1].date
    );

    const handleStartDateChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        const newStartDate: string = event.target.value;
        if (newStartDate <= endDate) {
            setStartDate(newStartDate);
        } else {
            setStartDate(endDate);
        }

        const newEndDateOptions: GraphType[] = initialData.filter(
            (item: GraphType) => item.date >= newStartDate
        );
        setEndDate(newEndDateOptions[newEndDateOptions.length - 1].date);
    };

    const filtredData: GraphType[] = initialData.filter(
        (item: GraphType) => item.date >= startDate && item.date <= endDate
    );

    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <p className={styles.title}>{title}</p>

                <div className={styles.filterWrapper}>
                    <div className={styles.filterItem}>
                        <label id="startDate">
                            {t("dashboard.graph.startDate")}
                        </label>
                        <select
                            aria-labelledby="startDate"
                            value={startDate}
                            onChange={handleStartDateChange}
                        >
                            {initialData.map((item: GraphType) => (
                                <option key={item.date} value={item.date}>
                                    {capitalizeFirstLetter(
                                        moment(item.date).format("MMM YYYY")
                                    )}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={styles.filterItem}>
                        <label id="endDate">
                            {t("dashboard.graph.endDate")}
                        </label>
                        <select
                            aria-labelledby="endDate"
                            value={endDate}
                            onChange={(event) => setEndDate(event.target.value)}
                        >
                            {initialData
                                .filter(
                                    (item: GraphType) => item.date >= startDate
                                )
                                .map((item: GraphType) => (
                                    <option key={item.date} value={item.date}>
                                        {capitalizeFirstLetter(
                                            moment(item.date).format("MMM YYYY")
                                        )}
                                    </option>
                                ))}
                        </select>
                    </div>
                </div>
            </div>

            <ResponsiveContainer height="95%" width={600} aspect={2.1}>
                <AreaChart data={filtredData}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="date"
                        tickFormatter={(date: string) =>
                            capitalizeFirstLetter(
                                moment(date).format("MMM YYYY")
                            )
                        }
                    />
                    <YAxis
                    // domain={([dataMin, dataMax]) => {
                    //     let roundedMin: number;
                    //
                    //     if (dataMin > 100) {
                    //         if (dataMin > 1000) {
                    //             roundedMin =
                    //                 Math.floor(dataMin / 1000) * 1000;
                    //         } else if (roundedMin % 100 === 0) {
                    //             roundedMin = Math.floor(dataMin * 0.75);
                    //         } else {
                    //             roundedMin =
                    //                 Math.floor(dataMin / 100) * 100;
                    //         }
                    //     } else {
                    //         roundedMin = Math.floor(dataMin / 10) * 10;
                    //     }
                    //
                    //     let roundedMax: number;
                    //     if (dataMax > 100) {
                    //         roundedMax = Math.ceil(dataMax / 100) * 100;
                    //     } else {
                    //         roundedMax = Math.ceil(dataMax / 10) * 10;
                    //     }
                    //
                    //     return [roundedMin, roundedMax] as [number, number];
                    // }}
                    />
                    <Tooltip
                        contentStyle={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: 5,
                        }}
                        formatter={(value, _name) => [value, tooltipText]}
                        labelFormatter={(label) =>
                            capitalizeFirstLetter(
                                moment(label).format("MMM YYYY")
                            )
                        }
                        cursor={false}
                    />
                    <Area
                        dataKey="value"
                        fill={barColor}
                        role="bar"
                        dot={{ stroke: barColor, strokeWidth: 6 }}
                        activeDot={{
                            stroke: barColor,
                            strokeWidth: 1,
                            r: 6,
                        }}
                    >
                        <LabelList
                            dataKey="value"
                            position="insideTop"
                            fill="#fff"
                        />
                    </Area>
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AreaCharts;
