import React, { useEffect, useState } from "react";
import type { CheckboxCustomEvent } from "@ionic/react";
import {
    IonButton,
    IonCheckbox,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
} from "@ionic/react";
import { checkmarkOutline, removeOutline, searchOutline } from "ionicons/icons";
import { t } from "i18next";
import styles from "./Typehead.module.scss";

interface TypeaheadProps {
    items: string[];
    selectedItems: string[];
    title?: string;
    onSelectionCancel?: () => void;
    onSelectionChange?: (selection: string[]) => void;
    disabled?: boolean;
}

function AppTypeahead(props: TypeaheadProps) {
    const [filteredItems, setFilteredItems] = useState<string[]>([
        ...props.items,
    ]);
    const [workingSelectedValues, setWorkingSelectedValues] = useState<
        string[]
    >([...props.selectedItems]);

    // let workingSelectedValues2 = [...props.selectedItems];

    const isChecked = (value: string) => {
        return (
            workingSelectedValues.find((item) => item === value) !== undefined
        );
    };
    const [coloreuse, setColoreuse] = useState<string>(
        workingSelectedValues.length > 0 ? "danger" : "tertiary"
    );

    const cancelChanges = () => {
        const { onSelectionCancel } = props;
        if (onSelectionCancel !== undefined) {
            onSelectionCancel();
        }
    };

    useEffect(() => {
        setColoreuse(workingSelectedValues.length > 0 ? "danger" : "tertiary");
    }, [workingSelectedValues.length]);

    const confirmChanges = () => {
        const { onSelectionChange } = props;
        if (onSelectionChange !== undefined) {
            onSelectionChange(workingSelectedValues);
        }
    };

    const searchbarInput = (ev: any) => {
        filterList(ev.target.value);
    };

    const filterList = (searchQuery: string | null | undefined) => {
        if (searchQuery === undefined || searchQuery === null) {
            setFilteredItems([...props.items]);
        } else {
            const normalizedQuery = searchQuery.toLowerCase();
            setFilteredItems(
                props.items.filter((item) => {
                    return item.toLowerCase().includes(normalizedQuery);
                })
            );
        }
    };

    const checkboxChange = (ev: CheckboxCustomEvent) => {
        const { checked, value } = ev.detail;

        if (checked) {
            setWorkingSelectedValues((workingSelectedValues) => [
                ...workingSelectedValues,
                value,
            ]);
        } else {
            setWorkingSelectedValues((workingSelectedValues) =>
                workingSelectedValues.filter((item) => item !== value)
            );
        }
    };

    function resetSelection() {
        let theArray = workingSelectedValues.length > 0 ? [] : [...props.items];
        setWorkingSelectedValues(theArray);
        setColoreuse(workingSelectedValues.length > 0 ? "danger" : "tertiary");
    }

    return (
        <>
            <IonHeader>
                <div
                    style={{
                        display: "flex",
                        padding: "15.5px",
                        marginTop: "15px",
                    }}
                >
                    <IonTitle
                        style={{
                            fontSize: "24px",
                            alignSelf: "start",
                            marginTop: "-5px",
                            paddingLeft: "0",
                        }}
                    >
                        {props.title}
                    </IonTitle>
                    <div className={styles.search}>
                        <input
                            type="text"
                            // ref={inputRef}
                            // onKeyUp={(ev) => handleKeyPress(ev)}
                            onChange={(ev) => searchbarInput(ev)}
                            className={styles.searchBar}
                            placeholder={t("search.placeholder")}
                        />
                        <IonIcon icon={searchOutline} />
                        {/*{t("search.button")}*/}
                    </div>
                </div>
            </IonHeader>

            <IonContent class="ion-padding" style={{ position: "relative" }}>
                <IonFab slot="fixed" vertical="bottom" horizontal="start">
                    <IonFabButton color={coloreuse} onClick={resetSelection}>
                        <IonIcon
                            className={styles.iconButton}
                            size={"large"}
                            icon={
                                coloreuse === "danger"
                                    ? removeOutline
                                    : checkmarkOutline
                            }
                        />
                    </IonFabButton>
                </IonFab>
                <IonList id="modal-list">
                    {filteredItems.map((item, i) => (
                        <IonItem key={`${item}${i}`} lines={"full"}>
                            <IonLabel>{item}</IonLabel>
                            <IonCheckbox
                                value={item}
                                checked={isChecked(item)}
                                onIonChange={(ev) => {
                                    checkboxChange(ev);
                                }}
                                color={"tertiary"}
                            />
                        </IonItem>
                    ))}
                </IonList>
                <div
                    style={{
                        position: "fixed",
                        bottom: "0px",
                        right: "17px",
                        backgroundColor: "white",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "10px",
                        }}
                    >
                        <IonButton
                            fill={"clear"}
                            color={"medium"}
                            onClick={cancelChanges}
                        >
                            Anulează
                        </IonButton>
                        <IonButton
                            fill={"clear"}
                            color={"tertiary"}
                            onClick={confirmChanges}
                        >
                            Confirmă
                        </IonButton>
                    </div>
                </div>
            </IonContent>
        </>
    );
}

export default AppTypeahead;
