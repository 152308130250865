import useFetchCaching from "../../../hooks/useFetchCaching";
import { useParams } from "react-router-dom";
import { IonPage } from "@ionic/react";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import React from "react";
import { ReadingDetailAdmin } from "../../../types/responseTypes";
import TableReadingDetailPage from "./TableReadingDetailPage";

const DetailsInvoicePage: React.FC = () => {
    const { readingId } = useParams<{ readingId: string }>();
    const { data: readingDetails } = useFetchCaching<ReadingDetailAdmin>(
        `admin/readings/${readingId}`
    );

    return (
        <>
            <IonPage>
                <SidebarLayout className="others-page" layoutType={"submenu"}>
                    <SkeletonList loading={!readingDetails} type={"simple"} />

                    {readingDetails && (
                        <TableReadingDetailPage
                            readingDetails={readingDetails}
                        />
                    )}
                </SidebarLayout>
            </IonPage>
        </>
    );
};

export default DetailsInvoicePage;
