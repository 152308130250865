import React, { useContext, useEffect, useState } from "react";
import {
    IonButton,
    IonItem,
    IonPage,
    IonSelect,
    IonSelectOption,
    SelectChangeEventDetail,
    SelectCustomEvent,
} from "@ionic/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import { axiosPrivate } from "../../api/customAxios";
import AuthContext from "../../context/AuthContext";
import useFetchCaching from "../../hooks/useFetchCaching";

import styles from "./Export.module.scss";

const ExportReadingsPage: React.FC = () => {
    const { t } = useTranslation();
    const { accessTokenCtx } = useContext(AuthContext);

    const getISODateToExport = (monthsInThePast: number) => {
        if (monthsInThePast === 0) return moment().format("YYYY-MM-01");
        const date = moment()
            .subtract(monthsInThePast, "month")
            .format("YYYY-MM-01");

        return date;
    };

    const [selected, setSelected] = useState<string>();
    const [month, setMonth] = useState<string>(moment(selected).format("M"));
    const [year, setYear] = useState<string>(moment(selected).format("YYYY"));
    const [download, setDownload] = useState<boolean>(false);

    useEffect(() => {
        setSelected(moment().format("MMMM - YYYY"));
    }, []);

    useFetchCaching(`/download/signature`); // add the interceptor

    const getDateToExport = (monthsInThePast: number) => {
        if (monthsInThePast === 0) return moment().format("MMMM - YYYY");
        const date = moment()
            .subtract(monthsInThePast, "month")
            .format("MMMM - YYYY");

        return date;
    };

    const handleChange = (
        e: SelectCustomEvent<SelectChangeEventDetail<any>>
    ) => {
        console.log(moment(e.target.value).format("M"));
        setSelected(moment(e.target.value).format("MMMM - YYYY"));
        setMonth(moment(e.target.value).format("M"));
        setYear(moment(e.target.value).format("YYYY"));
    };

    const handleDownload = async () => {
        try {
            const { data: signature } = await axiosPrivate.get(
                `/download/signature`,
                {
                    headers: {
                        Authorization: `Bearer ${accessTokenCtx}`,
                    },
                }
            );

            window.location.href = `${process.env.REACT_APP_API_URL}/admin/readings/xls/${year}/${month}?signature=${signature}`;

            setDownload(true);

            setTimeout(() => {
                setDownload(false);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <IonPage>
            <SidebarLayout
                className="others-page"
                layoutType={"submenu"}
                menuSelected={"item1"}
            >
                <>
                    <h1 className={styles.pageTitle}>{t("export.title")}</h1>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "20px",
                        }}
                    >
                        <IonItem>
                            <IonSelect
                                data-testid="month-select"
                                interface="popover"
                                selectedText={selected}
                                placeholder={t("export.select.placeholder")}
                                onIonChange={handleChange}
                            >
                                <IonSelectOption value={getISODateToExport(0)}>
                                    {getDateToExport(0)}
                                </IonSelectOption>
                                <IonSelectOption value={getISODateToExport(1)}>
                                    {getDateToExport(1)}
                                </IonSelectOption>
                                <IonSelectOption value={getISODateToExport(2)}>
                                    {getDateToExport(2)}
                                </IonSelectOption>
                                <IonSelectOption value={getISODateToExport(3)}>
                                    {getDateToExport(3)}
                                </IonSelectOption>
                            </IonSelect>
                        </IonItem>

                        <IonButton
                            onClick={handleDownload}
                            disabled={download}
                            target="_blank"
                        >
                            {t("export.downloadExcel")}
                        </IonButton>
                    </div>
                </>
            </SidebarLayout>
        </IonPage>
    );
};

export default ExportReadingsPage;
