import React, { useEffect, useState } from 'react';
import { IonProgressBar } from '@ionic/react';

interface ProgressBarProps{
    progress: number;
    setProgress: (value: any) => any;
}

const ProgressBar:React.FC<ProgressBarProps> =({progress, setProgress}) => {

    let interval;
    const totalTime = 120;
    const updateTime = 250;
    const step = 1 / totalTime / (1000 / updateTime);

    useEffect(() => {
        interval = setInterval(() => {
            setProgress((prevProgress) => prevProgress + step);
        }, updateTime);
        return () => clearInterval(interval);
    }, []);

    if (progress > 1) {
        clearInterval(interval);
    }

    return (
        <IonProgressBar value={progress} color={"tertiary"}/>
    );
}
export default ProgressBar;