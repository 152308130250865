import { useContext } from "react";
import axiosBasic from "../api/customAxios";
import AuthContext from "../context/AuthContext";
import { RefreshData } from "../types/requestTypes";
import { LoginResponse } from "../types/responseTypes";

const useRefreshToken = () => {
    const { updateAuthCtx } = useContext(AuthContext);

    return async (data: RefreshData): Promise<string> => {
        try {
            let response: LoginResponse;

            const webRefreshResponse = await axiosBasic.post(
                "/auth/refresh",
                data,
                {
                    withCredentials: true,
                }
            );
            response = webRefreshResponse?.data;

            const accessToken = response?.accessToken;

            updateAuthCtx(response);
            return accessToken;
        } catch (err) {
            throw new Error(err);
        }
    };
};

export default useRefreshToken;
