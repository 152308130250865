import { IonIcon, IonLabel, IonRouterLink } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface IconMenuButtonProps {
    selected: string;
    setSelected: (selected: string) => void;
    extendMenu: boolean;
    routerLink: string;
    icon: string;
    tooltip: string;
    menuContent: string;
}

const IconMenuButton: React.FC<IconMenuButtonProps> = ({
    selected,
    setSelected,
    extendMenu,
    routerLink,
    icon,
    tooltip,
    menuContent,
}) => {
    const { t } = useTranslation();

    return (
        <IonRouterLink
            routerLink={routerLink}
            className="icon-menu"
            slot="start"
            onClick={() => setSelected(routerLink)}
            color={selected === routerLink ? "tertiary" : ""}
            title={extendMenu ? "" : t(tooltip)}
        >
            <IonLabel
                className="menu-element"
                style={{
                    color: selected === routerLink ? "white" : "",
                }}
            >
                <IonIcon
                    slot="icon-only"
                    size="medium"
                    icon={icon}
                    className="icon"
                />
                {extendMenu && (
                    <div className="menu-text">{t(menuContent)}</div>
                )}
            </IonLabel>
        </IonRouterLink>
    );
};

export default IconMenuButton;
