import {
    InputChangeEventDetail,
    IonButton,
    IonIcon,
    IonInput,
    IonLabel,
    IonSpinner,
} from "@ionic/react";
import { eye, eyeOff } from "ionicons/icons";
import React, { useContext, useState } from "react";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import { userActions } from "../../../api/UserActions";
import PasswordValidationTooltip from "../../../components/PasswordComponents/PasswordValidationTooltip";
import AuthContext from "../../../context/AuthContext";
import { PasswordValidationType } from "../../../types/otherTypes";
import { ChangePassword } from "../../../types/requestTypes";
import { ExceptionDto } from "../../../types/schema";
import { passMinLength, regex } from "../../../utils/globalVariables";
import styles from "./UserPages.module.scss";
import { IonInputCustomEvent } from "@ionic/core";

const ChangePasswordPageForm: React.FC = () => {
    const { mailCtx } = useContext(AuthContext);

    const [pass1, setPass1] = useState<string>("");
    const [pass2, setPass2] = useState<string>("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showValidationState, setShowValidationState] =
        useState<PasswordValidationType>({
            minLength: false,
            specialChar: false,
            lowerChar: false,
            upperChar: false,
            digit: false,
        });

    const validatePass1 = (e: IonInputCustomEvent<InputChangeEventDetail>) => {
        setPass1(e.target.value as string);
        const inputValue = e.detail.value as string;
        setShowValidationState({
            minLength: inputValue.length >= passMinLength,
            specialChar: regex.specialChar.test(inputValue),
            lowerChar: regex.lowerChar.test(inputValue),
            upperChar: regex.upperChar.test(inputValue),
            digit: regex.digit.test(inputValue),
        });
    };

    const submitHandler = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (pass1.length < passMinLength) {
                setLoading(false);
                toastAdd(
                    "Câmpul 'Parolă' trebuie să aibă lungime minimă de 8 caractere",
                    "error"
                );
                return;
            }
            if (!regex.upperChar.test(pass1)) {
                setLoading(false);
                toastAdd(
                    "Câmpul 'Parolă' trebuie să conțină minim o literă mare",
                    "error"
                );
                return;
            }
            if (!regex.lowerChar.test(pass1)) {
                setLoading(false);
                toastAdd(
                    "Câmpul 'Parolă' trebuie să conțină minim o literă mică",
                    "error"
                );
                return;
            }
            if (!regex.digit.test(pass1)) {
                setLoading(false);
                toastAdd(
                    "Câmpul 'Parolă' trebuie să conțină minim o cifră",
                    "error"
                );
                return;
            }
            if (!regex.specialChar.test(pass1)) {
                setLoading(false);
                toastAdd(
                    "Câmpul 'Parolă' trebuie să conțină minim un caracter special: !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~",
                    "error"
                );
                return;
            }
            if (pass1 !== pass2) {
                setLoading(false);
                toastAdd("Parolele introduse nu sunt identice", "error");
                return;
            }

            const data: ChangePassword = {
                email: mailCtx as string,
                password: pass1,
            };

            await userActions.changeUserPass(data);
            setLoading(false);
            toastAdd(
                "Contul a fost actualizat cu succes. Înainte de a putea utiliza noua parolă, te rugăm să confirmi schimbarea acesteia accesând link-ul primit prin email.",
                "success"
            );
        } catch (err: any) {
            setLoading(false);
            const ex = err.response.data as ExceptionDto;
            if (ex) {
                toastAdd("Eroare", "error");
            }
        }
    };

    return (
        <form onSubmit={submitHandler} className={styles.form}>
            <div className={styles.passWrapper}>
                <IonLabel>{"Parolă nouă"}</IonLabel>
                <IonInput
                    className={styles.passInput}
                    onIonChange={(e) => validatePass1(e)}
                    onIonBlur={() => setShowTooltip(false)}
                    onIonFocus={() => setShowTooltip(true)}
                    clearOnEdit={false}
                    value={pass1}
                    type={showPassword ? "text" : "password"}
                    inputmode="text"
                    autocomplete="off"
                    placeholder={"Parolă nouă"}
                />
                <IonIcon
                    onClick={() => setShowPassword((prevState) => !prevState)}
                    icon={showPassword ? eyeOff : eye}
                    className={showPassword ? styles.off : styles.on}
                />
            </div>

            <div className={styles.passWrapper}>
                <IonLabel>{"Repetă parolă"}</IonLabel>
                <IonInput
                    className={styles.passInput}
                    onIonChange={(e) => setPass2(e.target.value as string)}
                    value={pass2}
                    type={showPassword2 ? "text" : "password"}
                    clearOnEdit={false}
                    inputmode="text"
                    autocomplete="off"
                    placeholder={"Repetă parolă"}
                />
                <IonIcon
                    onClick={() => setShowPassword2((prevState) => !prevState)}
                    icon={showPassword2 ? eyeOff : eye}
                    className={showPassword2 ? styles.off : styles.on}
                />
            </div>

            <PasswordValidationTooltip
                showTooltip={showTooltip}
                showValidationState={showValidationState}
                position={"bottom"}
            />

            <IonButton
                type="submit"
                expand="block"
                fill="solid"
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <IonSpinner name="circles" /> {"Se salvează"}
                    </>
                ) : (
                    "Salvează"
                )}
            </IonButton>

            <input type="submit" className="submit-enter" />
        </form>
    );
};

export default ChangePasswordPageForm;
