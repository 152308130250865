import {Exception} from "sass";


export class URLBuilderService{
    //serviciu pentru construire url atunci cînd se va refactoriza

    #baseUrl: string;
    #searchTerm: string;
    #page : string;
    #sortIndex: string;
    #sortDirection: string;
    #array: any[] = [];

    constructor(baseUrl:string) {
        this.#baseUrl = baseUrl;
    }

    static build(value:string){
        return new URLBuilderService(value);
    }

    withPage(page:string){
        if (page===null) return this;
        this.#page = page;
        this.#array.push(`page=${page}`);
        return this;
    }

    withSearchTerm(searchTerm:string){
        if (searchTerm===null || searchTerm==="") return this;
        this.#searchTerm = searchTerm;
        this.#array.push(`searchTerm=${searchTerm}`);
        return this;
    }

    withSortIndex(sortIndex:string){
        if (sortIndex===null) return this;
        this.#sortIndex = sortIndex;
        this.#array.push(`sortIndex=${sortIndex}`);
        return this;
    }

    withSortDirection(sortDirection:string){
        if (sortDirection===null) return this;
        // if(sortDirection.toLowerCase()!=="asc" && sortDirection.toLowerCase()!=="desc") throw new Error("only asc/desc supported")
        this.#sortDirection = sortDirection;
        this.#array.push(`sortDirection=${sortDirection}`);
        return this;
    }

    #getParams(){
        return this.#array.join("&");
    }


    generateFullUrl(){
        // return `${this.#baseUrl}?page=${this.#page}&searchTerm=${this.#searchTerm}&sortIndex=${this.#sortIndex}&sortDirection=${this.#sortDesc}`

        const params = this.#getParams();
        if (params.length) return `${this.#baseUrl}?${params}`;
        return this.#baseUrl;
    }

}



// URLBuilderService.build("/admin/news")
//     .withPage(1)
//     .withSearch("geagichi")
//     .generateFullUrl();

//https://test.aqmeter.com/v2/admin/invoices?page=1&searchTerm=milea


//const customUrl  = new URLBuilderService();

//export default customUrl;