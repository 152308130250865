import {IonContent, IonLabel} from '@ionic/react';
import React from 'react';
import styles from './ImageCardLayout.module.scss';

type ImageCardLayoutProps = {
  title?: string;
  imgUrl: string;
  logo: boolean;
};

const ImageCardLayout: React.FC<ImageCardLayoutProps> = ({title, imgUrl, logo, children}) => {

  const wrapperHeight = document.querySelector(`.${styles.wrapper}`)?.clientHeight;
  const contentHeight = document.querySelector(`.${styles.content}`)?.clientHeight;
  const imageHeight = wrapperHeight - contentHeight - 80;

  return (
    <IonContent className={styles.imageCard} fullscreen>
      <main className={styles.wrapper}>
        <section className={styles.inner}>
          <div className={styles.content}>
            {logo ? <img src="/assets/img/aqmeter-small.png" alt="Aqmeter" className={styles.logo} /> : ''}
            {title ? <IonLabel className={styles.title}>{title}</IonLabel> : ''}
            {children}
          </div>
          <img src={imgUrl} alt={title} className={styles.picture}
            style={imageHeight ? {height: imageHeight} : null}
          />
        </section>
      </main>
    </IonContent>
  );
};

export default ImageCardLayout;