import {IonLabel, IonPage} from "@ionic/react";
import React from "react";
import SidebarLayout from '../../../../components/SidebarLayout/SidebarLayout';
import {TermsContentRO} from "./TermsContentRO";

export const TermsPage: React.FC = () => {
    return (
        <IonPage>
            <SidebarLayout layoutType={'back'}>
                <IonLabel className="ag-h2 black">
                    Termeni și Condiții
                </IonLabel>
                <TermsContentRO/>
            </SidebarLayout>
        </IonPage>
    );
};
